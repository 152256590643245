import { Alignment } from '../../model';
import { CreateCategory } from '../../model/plugin';
import { CodePluginID } from './plugin';
import { CodeLanguage } from './lang';
import { jsonBlocksToText, newTextBlock } from '../../../RIDInlineEditor';
import { TextPluginID } from '../text';
export function createCode(id) {
    return {
        id,
        plugin: CodePluginID,
        code: '',
        lang: CodeLanguage.ProtoForce
    };
}
function textToCode(withID, from) {
    const text = jsonBlocksToText(from.inlines);
    return {
        id: withID,
        plugin: CodePluginID,
        code: text,
        lang: CodeLanguage.ProtoForce
    };
}
function codeToParagraph(withID, from) {
    return {
        id: withID,
        plugin: TextPluginID,
        kind: 'paragraph',
        alignment: Alignment.Left,
        inlines: [newTextBlock(from.code)]
    };
}
const newCreators = [
    {
        type: 'new',
        icon: 'code',
        name: 'Code',
        desc: 'Code snippet',
        category: CreateCategory.Media,
        ctor: (withID) => createCode(withID)
    }
];
// : BlockCreateFromData<Code | Paragraph, Code | Text>[]
const fromCreators = [
    {
        type: 'from',
        icon: 'paragraph',
        name: 'Paragraph',
        ctor: (withID, from) => codeToParagraph(withID, from),
        check: (from) => {
            return from.plugin === CodePluginID;
        }
    },
    {
        type: 'from',
        icon: 'code',
        name: 'Code',
        ctor: (withID, from) => textToCode(withID, from),
        check: (from) => {
            return from.plugin === TextPluginID;
        }
    }
];
export const creators = [...newCreators, ...fromCreators];
