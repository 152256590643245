import React, { useContext, useCallback, useState, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { OptionsContext } from '../context';
import { BlockWrapper } from './styles';
import { Toolbar } from '../Toolbar';
import { DragBlockType } from '../dnd';
import { DropArea } from '../DropArea';
import { PluginRefContext } from './PluginRefContext';
import { useVisibilityObserver } from './useVisibilityObserver';
export const BlockRenderer = React.memo((props) => {
    const { style, locked, block, drop } = props;
    const { readonly, plugins, observe } = useContext(OptionsContext);
    const wrapperRef = useRef(null);
    const ref = useRef(null);
    const [over, setOver] = useState(false);
    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: DragBlockType, block: block ? block.id : '' },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        }),
        canDrag: !readonly && !locked && !(block === null || block === void 0 ? void 0 : block.locked)
    });
    const onMouseLeave = useCallback(() => {
        setOver(false);
    }, [setOver]);
    const onMouseEnter = useCallback(() => {
        setOver(true);
    }, [setOver]);
    const plugin = block ? plugins[block.plugin] : undefined;
    const observableCallback = useCallback((visible) => {
        if (!block || !plugin || !plugin.id) {
            return;
        }
        const cb = observe[plugin.id].onVisibilityChange;
        if (cb) {
            cb(block, visible);
        }
    }, [block, plugin === null || plugin === void 0 ? void 0 : plugin.id, observe]);
    useVisibilityObserver(plugin && plugin.id in observe ? wrapperRef.current : undefined, observableCallback);
    if (!block || !plugin || !plugin.renderer) {
        if (!block) {
            return null;
        }
        return React.createElement("span", null,
            "No plugin or renderer for block of plugin: ",
            block.plugin);
    }
    const Component = plugin.forwardRef ? plugin.renderer : plugin.renderer;
    const toolbarPossible = !readonly && plugin.menu && !locked;
    const toolbarVisible = toolbarPossible && over && !isDragging;
    return (React.createElement(BlockWrapper, { "data-block": block.id, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, style: style, ref: wrapperRef },
        toolbarPossible &&
            React.createElement(PluginRefContext.Provider, { value: ref.current },
                React.createElement(Toolbar, { block: block, connectDragSource: drag, shouldBeVisible: toolbarVisible })),
        preview(
        // We need to wrap in div here so preview correctly works
        React.createElement("div", { style: { width: '100%', height: '100%' } },
            React.createElement(Component, { block: block, over: over, isDragging: isDragging, toolbarShouldBeVisible: toolbarVisible, pluginConfig: plugin.config, ref: plugin.forwardRef ? ref : undefined }))),
        !readonly && drop && !isDragging && React.createElement(DropArea, Object.assign({ block: block.id }, drop))));
}, (prev, next) => {
    const dropMatches = (!prev.drop && !next.drop) ||
        (prev.drop &&
            next.drop &&
            prev.drop.left === next.drop.left &&
            prev.drop.right === next.drop.right &&
            prev.drop.top === next.drop.top &&
            prev.drop.bottom === next.drop.bottom &&
            prev.drop.onDrop === next.drop.onDrop &&
            prev.drop.containerBlock === next.drop.containerBlock);
    return prev.block === next.block && prev.style === next.style && prev.locked === next.locked && dropMatches
        ? true
        : false;
});
