import styled from 'styled-components';
// We need padding-bottom so that the pop over doesn't close as mouse
// moves. Padding-right for the same purpose.
export const ToolbarWrapper = styled.div `
  position: absolute;
  transform: translate(-100%);
  padding-right: 5px;
  padding-bottom: 15px;
  z-index: 2;
`;
export const ToolbarButtons = styled.div `
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  background-color: #30404d;
`;
