import { TextRenderer } from './TextRenderer';
import { Alignment, newBlockID } from '../../model';
import { focus, alignmentMenu } from '../common';
import { newTextBlock as newTextInlineBlock, jsonBlocksToText } from '../../../RIDInlineEditor';
import { creators } from './creators';
export const TextPluginID = 'text';
export function textMenuVerticalOffset(block) {
    switch (block.kind) {
        case 'header':
            switch (block.size) {
                case 1: return 28;
                case 2: return 24;
                case 3:
                default:
                    return 20;
            }
        case 'paragraph':
            return -5;
        case 'quote':
            return -1;
    }
}
export const textPlugin = {
    id: TextPluginID,
    renderer: TextRenderer,
    focus,
    creators,
    menu: true,
    menuOffset: (context, block) => ({ y: textMenuVerticalOffset(block) }),
    menuItems: [
        alignmentMenu
    ]
};
export function createTextPlugin(config) {
    return Object.assign(Object.assign({}, textPlugin), { config });
}
export function newTextBlock(text) {
    return {
        id: newBlockID(),
        kind: 'paragraph',
        plugin: TextPluginID,
        alignment: Alignment.Left,
        inlines: text ? [newTextInlineBlock(text)] : []
    };
}
export function newHeaderBlock(text, size = 1) {
    return {
        id: newBlockID(),
        kind: 'header',
        plugin: TextPluginID,
        size,
        alignment: Alignment.Left,
        inlines: text ? [newTextInlineBlock(text)] : []
    };
}
export function textBlockToText(block) {
    return jsonBlocksToText(block.inlines);
}
