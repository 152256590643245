export function compareLocation(left, right) {
    return left && right && left.block === right.block && left.offset === right.offset ? true : false;
}
export function compareSelection(left, right) {
    return left && right && compareLocation(left.start, right.start) && compareLocation(left.end, right.end)
        ? true
        : false;
}
export function isSelectionCollapsed(s) {
    return s && s.start.block === s.end.block && s.start.offset === s.end.offset ? true : false;
}
export function collapseSelection(toStart, s) {
    if (!s) {
        return undefined;
    }
    return {
        start: toStart ? Object.assign({}, s.start) : Object.assign({}, s.end),
        end: toStart ? Object.assign({}, s.start) : Object.assign({}, s.end)
    };
}
export function normalizeSelection(blocks, s) {
    if (!s) {
        // https://github.com/microsoft/TypeScript/issues/22735
        return undefined;
    }
    // https://github.com/microsoft/TypeScript/issues/22735
    return (isSelectionReversed(blocks, s) ? { start: s.end, end: s.start } : s);
}
export function isSelectionReversed(blocks, s) {
    if (!s) {
        return false;
    }
    const startIndex = s.start.block;
    const endIndex = s.end.block;
    if (startIndex > endIndex) {
        return true;
    }
    if (startIndex < endIndex) {
        return false;
    }
    if (s.start.offset === s.end.offset) {
        return false;
    }
    const sl = locationToOffset(blocks, s.start);
    const el = locationToOffset(blocks, s.end);
    return sl > el;
}
export function buildSelection(block, offset) {
    return {
        start: {
            block,
            offset
        },
        end: {
            block,
            offset
        }
    };
}
// Removes empty start / end blocks, if selection isn't good
export function optimizeRange(s, blocks) {
    const normalized = normalizeSelection(blocks, s);
    const { start, end } = normalized;
    let startIndex = start.block;
    let endIndex = end.block;
    let startOffset = start.offset;
    let endOffset = end.offset;
    if (blocks.length > 0) {
        if (start.offset === 'end') {
            startIndex++;
            startOffset = 0;
        }
        else {
            const sb = blocks[start.block];
            switch (sb.type) {
                case 'text':
                    if (sb.text.length === start.offset && startIndex < blocks.length - 1) {
                        startIndex++;
                        startOffset = 0;
                    }
                    break;
                case 'object':
                    if (start.offset > 0) {
                        startIndex++;
                        startOffset = 0;
                    }
                    break;
            }
        }
        if (end.offset === 'end') {
            endIndex++;
            endOffset = 0;
        }
        else if (end.offset === 0 && endIndex > 0) {
            endIndex--;
            endOffset = 'end';
        }
    }
    return {
        startIndex,
        startOffset,
        endIndex,
        endOffset
    };
}
export function locationToOffset(blocks, loc) {
    let res = 0;
    for (let i = 0; i < loc.block; i++) {
        const b = blocks[i];
        res += blockTextLength(b);
    }
    if (loc.offset === 'end') {
        const b = blocks[loc.block];
        res += blockTextLength(b);
    }
    else {
        res += loc.offset;
    }
    return res;
}
export function offsetToLocation(blocks, offset) {
    const res = {
        block: 0,
        offset: 0
    };
    while (offset > 0) {
        const b = blocks[res.block];
        const bl = blockTextLength(b);
        if (offset > bl) {
            res.block++;
        }
        else {
            res.offset = bl === offset && b.type === 'object' ? 'end' : offset;
        }
        offset -= bl;
    }
    return res;
}
export function blockTextLength(b) {
    switch (b.type) {
        case 'object':
            return 1;
        case 'text':
            return b.text.length;
    }
}
export function blocksTextLength(blocks) {
    let res = 0;
    blocks.forEach(b => (res += blockTextLength(b)));
    return res;
}
