import { newBlockID } from '../../model';
export function allHaveColor(blocks, color, back) {
    if (blocks.length === 0) {
        return false;
    }
    for (const b of blocks) {
        if (b.type !== 'text') {
            continue;
        }
        const value = b.colors ? (back ? b.colors.back : b.colors.text) : undefined;
        if (value !== color) {
            return false;
        }
    }
    return true;
}
export function setColor(blocks, color, back) {
    for (const b of blocks) {
        if (b.type !== 'text') {
            continue;
        }
        const field = back ? 'back' : 'text';
        if (typeof color !== 'undefined') {
            if (!b.colors) {
                b.colors = {
                    [field]: color
                };
            }
            else {
                b.colors[field] = color;
            }
        }
        else {
            if (b.colors) {
                delete b.colors[field];
            }
        }
    }
}
export function applyColor(contexts, color, back) {
    const { selection } = contexts.model;
    if (!selection) {
        return;
    }
    const copied = contexts.actions.copy(selection);
    if (copied.length === 0) {
        return;
    }
    // const enable = !allHaveColor(copied, color, back);
    setColor(copied, color, back);
    // Reset ID for the first and last ones, as they will be new blocks most likely
    copied[0].id = newBlockID();
    copied[copied.length - 1].id = newBlockID();
    contexts.actions.replace(copied, selection);
}
