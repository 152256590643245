import React, { useContext, useMemo, useState, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import { Icon, Overlay, Classes } from '@blueprintjs/core';
import { Wrapper, Img, ImageResizableFrame, ImgView } from './styles';
import { MediaPicker, urlPicker, uploadPicker, Rescaler, RescalerMode, useRescalerStyle, unsplashPicker, Dummy, OptionsContext, ActionsContext } from '../../Editor';
import { MediaCaption } from '../common';
import { useResizableBlockFlexAlignment } from '../../model';
import { newTextBlock } from '../../../RIDInlineEditor';
const ImageRendererImpl = (props, forRef) => {
    const { block, pluginConfig } = props;
    const actions = useContext(ActionsContext);
    const { readonly } = useContext(OptionsContext);
    const ref = useRef(null);
    const blockRef = useRef(null);
    const [viewOpen, setViewOpen] = useState(false);
    const [rescaleState, setRescaleState] = useState();
    const [pickerOpen, setPickerOpen] = useState(false);
    const mediaValue = block.media;
    const url = useMemo(() => {
        return block.media.kind === 'none' ? undefined : block.media.url;
    }, [block.media]);
    const plugins = useMemo(() => {
        const pickerPlugins = [urlPicker];
        if (!pluginConfig) {
            return pickerPlugins;
        }
        if (pluginConfig.uploader) {
            pickerPlugins.push(Object.assign(Object.assign({}, uploadPicker), { config: {
                    upload: pluginConfig.uploader
                } }));
        }
        if (pluginConfig.unsplash) {
            pickerPlugins.push(Object.assign(Object.assign({}, unsplashPicker), { config: {
                    search: pluginConfig.unsplash.search,
                    random: pluginConfig.unsplash.random
                } }));
        }
        return pickerPlugins;
    }, [pluginConfig === null || pluginConfig === void 0 ? void 0 : pluginConfig.uploader, pluginConfig === null || pluginConfig === void 0 ? void 0 : pluginConfig.unsplash]);
    const onCaptionUpdated = useCallback((caption) => {
        const cloned = actions.clone(block).cloned;
        cloned.caption = caption;
        actions.update(cloned);
    }, [actions, block]);
    const onOpenMedia = useCallback(() => {
        if (readonly) {
            return;
        }
        setPickerOpen(true);
    }, [readonly, setPickerOpen]);
    const onSelectMedia = useCallback((value, plugin, caption) => {
        const cloned = actions.clone(block).cloned;
        cloned.media = value;
        if (caption) {
            cloned.caption = [newTextBlock(caption)];
        }
        actions.update(cloned);
        setPickerOpen(false);
    }, [actions, block, setPickerOpen]);
    const onCancelMedia = useCallback(() => {
        setPickerOpen(false);
    }, [setPickerOpen]);
    const onRescaleStart = useCallback((update) => {
        if (!update) {
            return;
        }
        setRescaleState(update);
    }, [setRescaleState]);
    const onRescale = useCallback((update) => {
        if (!update) {
            return;
        }
        setRescaleState(update);
    }, [setRescaleState]);
    const onRescaleEnd = useCallback((update) => {
        if (!update || (!update.width && !update.height)) {
            return;
        }
        const cloned = actions.clone(block).cloned;
        if (update.width) {
            cloned.width = update.width.value;
        }
        if (update.height) {
            cloned.height = update.height.value;
        }
        actions.update(cloned);
        setRescaleState(undefined);
    }, [actions, block, setRescaleState]);
    const onViewImage = useCallback(() => {
        setViewOpen(true);
    }, [setViewOpen]);
    const onViewClose = useCallback(() => {
        setViewOpen(false);
    }, [setViewOpen]);
    useImperativeHandle(forRef, () => {
        return {
            openPicker: onOpenMedia
        };
    });
    const containerStyle = useRescalerStyle(block, rescaleState);
    const altText = useMemo(() => {
        return block.caption ? block.caption.map(c => c.type === 'text' ? c.text : '').join(' ') : '';
    }, [block.caption]);
    const alignmentStyle = useResizableBlockFlexAlignment(block);
    return (React.createElement(Wrapper, { ref: blockRef, style: alignmentStyle },
        React.createElement(ImageResizableFrame, { ref: ref, style: containerStyle },
            url ? (React.createElement(Img, { draggable: false, src: url, alt: altText, onClick: readonly ? onViewImage : undefined, onDoubleClick: readonly ? undefined : onOpenMedia })) : (React.createElement(Dummy, { interactive: !readonly, onClick: onOpenMedia },
                React.createElement(Icon, { icon: 'media' }),
                "\u00A0Click to set or upload an image.")),
            React.createElement(Rescaler, { readonly: readonly, mode: RescalerMode.WidthHeight, target: {
                    alignment: block.alignment,
                    container: blockRef,
                    element: ref
                }, onRescaleStart: onRescaleStart, onRescale: onRescale, onRescaleEnd: onRescaleEnd })),
        React.createElement(MediaPicker, { blockID: block.id, open: pickerOpen, value: mediaValue, plugins: plugins, onSave: onSelectMedia, onCancel: onCancelMedia }),
        React.createElement(MediaCaption, { readonly: readonly, caption: block.caption, onUpdated: onCaptionUpdated }),
        mediaValue.kind !== 'none' && (React.createElement(Overlay, { isOpen: viewOpen, onClose: onViewClose, canEscapeKeyClose: true, canOutsideClickClose: true, className: Classes.OVERLAY_CONTAINER },
            React.createElement("div", { onClick: onViewClose, className: 'doc-image-transition' },
                React.createElement(ImgView, { draggable: false, alt: altText, src: mediaValue.url }))))));
};
export const ImageRenderer = React.memo(forwardRef(ImageRendererImpl));
