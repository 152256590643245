export var TextStyle;
(function (TextStyle) {
    TextStyle["Bold"] = "bold";
    TextStyle["Italic"] = "italic";
    TextStyle["Strike"] = "strike";
    TextStyle["Sup"] = "sup";
    TextStyle["Sub"] = "sub";
    TextStyle["Underline"] = "underline";
    TextStyle["Code"] = "code";
})(TextStyle || (TextStyle = {}));
