import styled, { css } from 'styled-components';
export const ResizerWrapper = styled.div `
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  opacity: 0;
  cursor: col-resize;
  &:hover {
    opacity: 0.2;
  }
  ${props => props.vertical
    ? css `
          justify-content: center;
        `
    : css `
          align-items: center;
        `}
  ${props => props.vertical
    ? css `
          width: 32px;
        `
    : css `
          height: 32px;
        `}
`;
export const ResizerIndicator = styled.div `
  background: #000;
  user-select: none;
  position: absolute;
  transition: opacity 200ms ease-out 0s;
  ${props => props.vertical
    ? css `
          top: 0;
          bottom: 0;
          width: 4px;
          height: 100%;
        `
    : css `
          left: 0;
          right: 0;
          height: 4px;
          width: 100%;
        `}
`;
