import { optimizeRange, offsetToLocation, locationToOffset, blocksTextLength, normalizeSelection } from './location';
import { cloneBlock } from './utils';
export function packBlocks(plugins, blocks, ...locations) {
    const packed = [];
    const discarded = [];
    const positions = locations.map(l => (Object.assign({}, l)));
    let merges = 0;
    for (let i = 0; i < blocks.length; i++) {
        let current = blocks[i];
        const plugin = plugins[current.plugin];
        let mergeHappened = false;
        if (current.type === 'text') {
            if (current.text.length === 0 || !plugin.merge) {
                continue;
            }
            for (let j = i + 1, ic = i; j < blocks.length; j++) {
                const next = blocks[j];
                const merged = plugin.merge(current, next);
                if (!merged) {
                    break;
                }
                discarded.push(next.id);
                // Adjust positions accordingly
                for (const p of positions) {
                    if (!p || p.block <= ic - merges) {
                        continue;
                    }
                    p.block -= 1;
                    if (p.block === ic - merges && typeof p.offset !== 'string') {
                        p.offset += current.text.length;
                        p.block = ic - merges;
                    }
                }
                current = merged;
                i = j;
                mergeHappened = true;
            }
        }
        packed.push(current);
        if (mergeHappened) {
            merges++;
        }
    }
    return {
        packed,
        discarded,
        locations: positions
    };
}
export function copyFragment(plugins, s, blocks) {
    if (!s) {
        return [];
    }
    const { startIndex, startOffset, endIndex, endOffset } = optimizeRange(s, blocks);
    if (endIndex < startIndex) {
        return [];
    }
    const startEndMatch = startIndex === endIndex;
    const startCloned = cloneBlock(plugins, blocks[startIndex]);
    if (startCloned.type === 'text') {
        const st = startOffset === 'end' ? startCloned.text.length : startOffset;
        const en = endOffset === 'end' || !startEndMatch ? startCloned.text.length : endOffset;
        startCloned.text = startCloned.text.substring(st, en);
    }
    const res = [startCloned];
    if (startEndMatch) {
        return res;
    }
    for (let i = startIndex + 1; i < endIndex; i++) {
        res.push(cloneBlock(plugins, blocks[i]));
    }
    const endCloned = cloneBlock(plugins, blocks[endIndex]);
    if (endCloned.type === 'text') {
        const st = 0;
        const en = endOffset === 'end' ? endCloned.text.length : endOffset;
        endCloned.text = endCloned.text.substring(st, en);
    }
    res.push(endCloned);
    return res;
}
export function replaceFragment(plugins, replaceWith, s, blocks, ...locations) {
    const ns = normalizeSelection(blocks, s);
    const { startIndex, startOffset, endIndex, endOffset } = optimizeRange(ns, blocks);
    if (endIndex < startIndex) {
        return undefined;
    }
    let locs = locations.map(l => (Object.assign({}, l)));
    const res = [];
    for (let i = 0; i < startIndex; i++) {
        const b = blocks[i];
        res.push(b);
    }
    // We need to insert a piece of start block if needed
    const sb = blocks[startIndex];
    if (sb) {
        switch (sb.type) {
            case 'text':
                const sbc = cloneBlock(plugins, sb, true);
                sbc.text = sbc.text.substr(0, startOffset === 'end' ? sbc.text.length : startOffset);
                // Ignore empty text blocks
                if (sbc.text.length > 0) {
                    res.push(sbc);
                }
                break;
            case 'object':
                if (startOffset === 'end' || startOffset > 0) {
                    // We can place the whole object as is
                    res.push(sb);
                }
                break;
        }
    }
    // Add a block of replacement now
    for (const b of replaceWith) {
        res.push(b);
    }
    // We need to add a piece of end block if needed
    const eb = blocks[endIndex];
    if (eb) {
        switch (eb.type) {
            case 'text':
                const ebc = cloneBlock(plugins, eb, true);
                ebc.text = ebc.text.substr(endOffset === 'end' ? ebc.text.length : endOffset);
                if (ebc.text.length > 0) {
                    res.push(ebc);
                }
                break;
            case 'object':
                if (endOffset === 0) {
                    res.push(eb);
                }
                break;
        }
    }
    for (let i = endIndex + 1; i < blocks.length; i++) {
        const b = blocks[i];
        res.push(b);
    }
    if (locs.length > 0) {
        // Before we proceed we need to adjust current positions
        // with regards to the insert blocks, so that we can then
        // clearly adjust during packing
        const offsetTillStart = locationToOffset(blocks, ns.start);
        const offsetTillEnd = locationToOffset(blocks, ns.end);
        const insertionLength = blocksTextLength(replaceWith);
        const droppedRange = offsetTillEnd - offsetTillStart;
        locs = locs.map(l => {
            const o = locationToOffset(blocks, l);
            if (o <= offsetTillStart) {
                return l;
            }
            if (o > offsetTillStart && o < offsetTillEnd) {
                // Everything within the range of replacement,
                // gets automatically moved to the beginning, as
                // its position is no longer relevant
                // TODO? Maybe we can have a better logic, for example
                // to scroll to either side or stay where it is after insertion
                return ns.start;
            }
            return offsetToLocation(res, o - droppedRange + insertionLength);
        });
    }
    const { packed, discarded, locations: updated } = packBlocks(plugins, res, ...locs);
    return {
        blocks: packed,
        locations: updated,
        discarded
    };
}
