import styled from 'styled-components';
const color = `rgba(64,128,189, 0.4)`;
export const WrapperSimple = styled.div `
  display: flex;
  width: 100%;
  max-width: 100%;
  border-color: ${color};
  margin: 5px;
  border-right: 1px solid ${color};
  border-bottom: 1px solid ${color};
`;
export const WrapperDashed = styled.div `
  display: flex;
  width: 100%;
  max-width: 100%;
  border-color: ${color};
  margin: 5px;
  border-right: 1px dashed ${color};
  border-bottom: 1px dashed ${color};
`;
