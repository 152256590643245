import React from 'react';
import ToolbarMenuDivider from './ToolbarMenuDivider';
import ToolbarMenuLabel from './ToolbarMenuLabel';
import ToolbarMenuGroup from './ToolbarMenuGroup';
import ToolbarMenuCommand from './ToolbarMenuCommand';
export function ToolbarMenuItem(props) {
    const { item, block } = props;
    switch (item.type) {
        case 'divider':
            return React.createElement(ToolbarMenuDivider, { divider: item, block: block });
        case 'label':
            return React.createElement(ToolbarMenuLabel, { label: item, block: block });
        case 'group':
            return React.createElement(ToolbarMenuGroup, { block: block, group: item });
        case 'command':
            return React.createElement(ToolbarMenuCommand, { block: block.id, command: item });
    }
}
