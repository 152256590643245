var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled, { css } from 'styled-components';
import React from 'react';
import { textColors, backColors } from '../../model';
function propsToStyle(props) {
    return css `
        ${props.bold
        ? css `
                font-weight: 600;
              `
        : undefined}
        ${props.italic
        ? css `
                font-style: italic;
              `
        : undefined}
        ${props.strike
        ? css `
                text-decoration: line-through;
              `
        : undefined}
        ${props.sup
        ? css `
                position: relative;
                top: -0.5em;
                font-size: 75%;
                line-height: 0;
                vertical-align: baseline;
              `
        : undefined}
        ${props.sub
        ? css `
                position: relative;
                bottom: -0.25em;
                font-size: 75%;
                line-height: 0;
                vertical-align: baseline;
              `
        : undefined}
        ${props.underline
        ? css `
                text-decoration: underline;
              `
        : undefined}
    `;
}
function propsToColor(props) {
    const textColor = props.colors && props.colors.text;
    const backColor = props.colors && props.colors.back;
    // TODO Add support for arbitrary colors here, for now https://github.com/ProtoForce/protoforce-portal-webui/issues/38
    // always picks from the maps
    const text = textColor ? textColors[textColor] : undefined;
    const back = backColor ? backColors[backColor] : undefined;
    return css `
    ${text
        // @ts-ignore
        ? css `
          color: ${text};
        `
        : undefined}
    ${back
        ? css `
          background-color: ${back};
        `
        : undefined}
  `;
}
export const StyledSpan = styled.span `
  ${props => propsToStyle(props)}
  ${props => propsToColor(props)}
`;
export const StyledCode = styled.code `
  ${props => propsToStyle(props)}
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  background: rgba(16, 22, 26, 0.3);
  color: #bfccd6;
`;
const styledText = props => {
    const { text, tag = 'span' } = props, rest = __rest(props, ["text", "tag"]);
    switch (tag) {
        case 'span':
            return React.createElement(StyledSpan, Object.assign({}, rest), text);
        case 'code':
            return React.createElement(StyledCode, Object.assign({}, rest), text);
    }
};
export const StyledText = React.memo(styledText);
