import styled from 'styled-components';
import { CalloutIntent } from './models';
const Colors = {
    [CalloutIntent.Default]: {
        wrapper: 'rgba(138,155,168,.2)',
        header: '#f5f8fa'
    },
    [CalloutIntent.Primary]: {
        wrapper: 'rgba(19,124,189,.25)',
        header: '#48aff0'
    },
    [CalloutIntent.Success]: {
        wrapper: 'rgba(15,153,96,.25)',
        header: '#3dcc91'
    },
    [CalloutIntent.Warning]: {
        wrapper: 'rgba(217,130,43,.25)',
        header: '#ffb366'
    },
    [CalloutIntent.Danger]: {
        wrapper: 'rgba(219,55,55,.25)',
        header: '#ff7373'
    }
};
export const Heading = styled.h4 `
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  display: block;
  margin-top: 0;
  margin-bottom: 5px;
  color: ${props => Colors[props.intent].header};
`;
export const Wrapper = styled.div `
  line-height: 1.5;
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  width: 100%;
  padding: 10px 12px 9px;
  margin-bottom: 10px;
  background-color: ${props => Colors[props.intent].wrapper};
`;
