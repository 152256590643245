export var FocusPosition;
(function (FocusPosition) {
    FocusPosition[FocusPosition["Start"] = 0] = "Start";
    FocusPosition[FocusPosition["End"] = 1] = "End";
})(FocusPosition || (FocusPosition = {}));
export var CreateCategory;
(function (CreateCategory) {
    CreateCategory[CreateCategory["Basic"] = 0] = "Basic";
    CreateCategory[CreateCategory["Containers"] = 1] = "Containers";
    CreateCategory[CreateCategory["Inline"] = 2] = "Inline";
    CreateCategory[CreateCategory["Media"] = 3] = "Media";
    CreateCategory[CreateCategory["Integrations"] = 4] = "Integrations";
})(CreateCategory || (CreateCategory = {}));
