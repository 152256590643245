import styled, { css } from 'styled-components';
export const Wrapper = styled.div ``;
export const ImageGrid = styled.div `
  max-height: calc(100vh - 300px);
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
`;
export const ImageContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  ${props => props.selected
    ? css `
          border: dotted #68d4f8 1px;
          border-radius: 5px;
        `
    : undefined}
`;
export const ImagePreview = styled.img `
  max-width: 150px;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
`;
