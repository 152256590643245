import { createContext, useContext, useMemo } from 'react';
// @ts-ignore
export const ModelContext = createContext({});
// @ts-ignore
export const ActionsContext = createContext({});
// @ts-ignore
export const OptionsContext = createContext({});
export function useModelState() {
    const model = useContext(ModelContext);
    const actions = useContext(ActionsContext);
    const options = useContext(OptionsContext);
    return useMemo(() => ({ model, actions, options }), [model, actions, options]);
}
