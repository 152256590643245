import React, { useContext } from 'react';
import { Button, Tooltip } from '@blueprintjs/core';
import { allHaveStyle } from '../format/utils';
import { ActionsContext, ModelContext } from '../../Editor';
export const FormatButton = (props) => {
    const { icon, command, title } = props;
    const model = useContext(ModelContext);
    const actions = useContext(ActionsContext);
    const active = allHaveStyle(model.selected, command.style);
    function toggle(event) {
        command.func({
            actions,
            model
        }, event);
    }
    return (React.createElement(Tooltip, { content: title },
        React.createElement(Button, { icon: icon, onClick: toggle, intent: active ? 'primary' : undefined })));
};
