import { LinkRenderer } from './LinkRenderer';
import { newBlockID } from '../../model';
export const LinkPluginID = 'link';
export const linkPlugin = {
    id: LinkPluginID,
    renderer: LinkRenderer,
    merge
};
function merge(left, right) {
    if (left.type !== 'text' || left.plugin !== LinkPluginID) {
        return undefined;
    }
    if (right.type !== 'text') {
        return undefined;
    }
    if (right.text.length === 0) {
        return Object.assign({}, left);
    }
    if (right.plugin !== LinkPluginID) {
        return undefined;
    }
    const leftLink = left;
    const rightLink = left;
    if (leftLink.data !== rightLink.data) {
        return undefined;
    }
    // TODO Performance? Better without JSON? https://github.com/ProtoForce/protoforce-portal-webui/issues/38
    const formatLeft = left.styles && left.styles.length > 0 ? JSON.stringify(left.styles.sort()) : undefined;
    const formatRight = right.styles && right.styles.length > 0 ? JSON.stringify(right.styles.sort()) : undefined;
    if (formatLeft !== formatRight) {
        return undefined;
    }
    const colorLeft = left.colors ? `${left.colors.text}.${left.colors.back}` : undefined;
    const colorRight = right.colors ? `${right.colors.text}.${right.colors.back}` : undefined;
    if (colorLeft !== colorRight) {
        return undefined;
    }
    return Object.assign(Object.assign({}, left), { text: left.text + right.text });
}
export function newLinkBlock(link, name) {
    return {
        id: newBlockID(),
        type: 'text',
        plugin: LinkPluginID,
        text: name || link,
        data: link
    };
}
