import styled from 'styled-components';
// We need a bit of padding for the sheet, so that we can have
// drop area there if it's a part of a bigger sheet
export const VerticalWrapper = styled.div `
  width: 100%;
  max-width: 100%;
  padding-bottom: 5px;
`;
export const HorizontalWrapper = styled.div `
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100%;
  padding-bottom: 5px;
`;
