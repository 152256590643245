import React, { useContext, useCallback } from 'react';
import { PrismCode } from './PrismCode';
import { FocusTarget, FocusPosition } from '../../model';
import { ActionsContext, OptionsContext } from '../../Editor';
function CodeRendererImpl(props) {
    const { block, pluginConfig } = props;
    const options = useContext(OptionsContext);
    const actions = useContext(ActionsContext);
    // TODO Figure out styles and selectors for the code editor https://github.com/ProtoForce/protoforce-portal-webui/issues/28
    // to make sure we can render without blueprint
    const onUpdate = useCallback((code, lang) => {
        // We do shallow cloning here
        const cloned = Object.assign(Object.assign({}, block), { code: code || block.code, lang: lang || block.lang });
        actions.update(cloned);
    }, [block, actions]);
    const onPrevParagraph = useCallback(() => {
        actions.focus(block.id, FocusTarget.Previous, FocusPosition.End, true);
        return true;
    }, [actions, block.id]);
    const onNextParagraph = useCallback(() => {
        actions.focus(block.id, FocusTarget.Next, FocusPosition.Start, true);
        return true;
    }, [actions, block.id]);
    return (React.createElement(PrismCode, { defaultCode: block.code, defaultLanguage: block.lang, disabled: options.readonly, onUpdate: onUpdate, onPrevParagraph: onPrevParagraph, onNextParagraph: onNextParagraph, onCopy: pluginConfig === null || pluginConfig === void 0 ? void 0 : pluginConfig.onCopy }));
}
export const CodeRenderer = React.memo(CodeRendererImpl);
