import { CreateCategory } from '../../model/plugin';
import { FilePluginID } from './models';
// New creators
function createFile(id) {
    return {
        id,
        plugin: FilePluginID,
        media: {
            kind: 'none'
        }
    };
}
const newCreators = [
    {
        type: 'new',
        icon: 'paperclip',
        name: 'File',
        desc: 'Attach or link a file',
        category: CreateCategory.Media,
        ctor: (withID) => createFile(withID)
    }
];
export const creators = [...newCreators];
