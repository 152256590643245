import { isSelectionCollapsed, isSelectionReversed } from './model';
export const DATA_INLINE_BLOCK_ATTR = 'data-inline-block';
// These go up stream till reaches the first satisfying one
export function findParentBlockID(el, attr = DATA_INLINE_BLOCK_ATTR) {
    if (!el) {
        return undefined;
    }
    switch (el.nodeType) {
        case Node.TEXT_NODE:
            return findParentBlockID(el.parentNode, attr);
        case Node.ELEMENT_NODE: {
            const element = el;
            const id = element.getAttribute(attr);
            if (id) {
                return {
                    element,
                    id
                };
            }
            const parent = element.parentElement;
            if (!parent) {
                return undefined;
            }
            return findParentBlockID(parent, attr);
        }
    }
    console.warn('Unsupported node type in findParentBlockID: ' + el.nodeType + ' ' + el.textContent);
    return undefined;
}
export function findChildBlockByID(el, id, attr = DATA_INLINE_BLOCK_ATTR) {
    if (!el) {
        return undefined;
    }
    switch (el.nodeType) {
        case Node.DOCUMENT_NODE:
        case Node.ELEMENT_NODE: {
            const element = el;
            const res = element.querySelector(`[${attr}="${id}"]`);
            return res ? res : undefined;
        }
    }
    console.warn('Unsupported node type in findChildBlockByID: ' + el.nodeType + ' ' + el.textContent);
    return undefined;
}
export function getDOMSelection(blocks) {
    const getSelection = () => {
        const selection = document.getSelection();
        if (!selection || (!selection.anchorNode && !selection.focusNode)) {
            return undefined;
        }
        const res = {
            start: {
                block: '',
                offset: 0
            },
            end: {
                block: '',
                offset: 0
            }
        };
        // When a mouse is used to set caret position, selection can be set
        // at a div level, not a span level. Therefore, to get the accurate
        // positioning, we need to drill down until we hit the bottom and then
        // use that as a starting point to set position
        let anchorNode = selection.anchorNode;
        let anchorOffset = selection.anchorOffset;
        if (anchorNode && anchorNode.nodeType === Node.ELEMENT_NODE && anchorNode.nodeName === 'DIV') {
            if (anchorNode.childNodes.length === 0) {
                return undefined;
            }
            if (anchorNode.childNodes.length === anchorOffset) {
                // We are at the end of a non-empty div, so we take the last
                // node and assign offset its length
                anchorNode = anchorNode.childNodes[anchorNode.childNodes.length - 1];
                anchorOffset = (anchorNode.textContent || '').length;
            }
            else {
                anchorNode = anchorNode.childNodes[anchorOffset];
                anchorOffset = 0;
            }
        }
        if (!anchorNode) {
            return undefined;
        }
        const startBlock = findParentBlockID(anchorNode);
        if (startBlock) {
            res.start = {
                block: startBlock.id,
                offset: anchorOffset
            };
        }
        else {
            return undefined;
        }
        let focusNode = selection.focusNode;
        let focusOffset = selection.focusOffset;
        if (focusNode && focusNode.nodeType === Node.ELEMENT_NODE && focusNode.nodeName === 'DIV') {
            focusNode = focusNode.childNodes[focusOffset];
            focusOffset = 0;
        }
        const endBlock = findParentBlockID(focusNode);
        if (endBlock) {
            res.end = {
                block: endBlock.id,
                offset: focusOffset
            };
        }
        else {
            // Collapse to start if there is no end node
            res.end = Object.assign({}, res.start);
        }
        return res;
    };
    const sel = getSelection();
    if (!sel) {
        return undefined;
    }
    return fromDOMSelection(sel, blocks);
}
// This is a safe method to set selection on the node and offset within it,
// it drills down until the number of children or length equals that of the offset
function findRangeOffsetTarget(node, offset) {
    // Skip all children nodes, which are not the target range offset
    // tslint:disable-next-line
    for (let i = 0; i < node.childNodes.length; i++) {
        const child = node.childNodes[i];
        const tc = (child.textContent || '').length;
        if (offset > tc) {
            offset -= tc;
            continue;
        }
        // We are currently at a child which has enough length for the range to be set
        if (child.nodeType === Node.TEXT_NODE) {
            // Be safe here, if we were provided offset longer than the content
            // of the text node, just set to the last element
            const max = offset > tc ? tc : offset;
            return { child, offset: max };
        }
        return findRangeOffsetTarget(child, offset);
    }
    return undefined;
}
// function setRangeStart(range: Range, node: Node, offset: number) {
//   const target = findRangeOffsetTarget(node, offset);
//   if (!target) {
//     console.warn(`Can't apply selection in setRangeStart`);
//     return;
//   }
//   range.setStart(target.child, target.offset);
// }
// function setRangeEnd(range: Range, node: Node, offset: number) {
//   const target = findRangeOffsetTarget(node, offset);
//   if (!target) {
//     console.warn(`Can't apply selection in setRangeStart`);
//     return;
//   }
//   range.setEnd(target.child, target.offset);
// }
export function setDOMSelection(blocks, selection) {
    const setSelection = (s) => {
        const docSelection = document.getSelection();
        if (!docSelection) {
            console.warn('Set selection failed to get document selection.');
            return;
        }
        if (!s) {
            docSelection.removeAllRanges();
            return;
        }
        const node = document.getRootNode();
        const reversed = isSelectionReversed(blocks, selection);
        const start = reversed ? s.end : s.start;
        const end = reversed ? s.start : s.end;
        const startEl = findChildBlockByID(node, start.block);
        if (!startEl || !startEl.firstChild) {
            // We always work with the first text node, so need to check
            console.warn('Set selection failed to find a block node with ID: ' + start.block);
            return;
        }
        const range = document.createRange();
        if (isSelectionCollapsed(s)) {
            switch (start.offset) {
                case 'start':
                    range.selectNode(startEl);
                    range.collapse(true);
                    break;
                case 'end':
                    range.selectNode(startEl);
                    range.collapse(false);
                    break;
                default:
                    const startTarget = findRangeOffsetTarget(startEl, start.offset);
                    if (startTarget) {
                        range.setStart(startTarget.child, startTarget.offset);
                        range.setEnd(startTarget.child, startTarget.offset);
                    }
                    break;
            }
            docSelection.removeAllRanges();
            docSelection.addRange(range);
            return;
        }
        const endEl = findChildBlockByID(node, end.block);
        if (!endEl) {
            // We always work with the first text node, so need to check
            console.warn('Set selection failed to find a block node with ID: ' + end.block);
            return;
        }
        switch (start.offset) {
            case 'start':
                range.setStartBefore(startEl);
                break;
            case 'end':
                range.setStartAfter(startEl);
                break;
            default:
                const startTarget = findRangeOffsetTarget(startEl, start.offset);
                if (startTarget) {
                    range.setStart(startTarget.child, startTarget.offset);
                }
                break;
        }
        switch (end.offset) {
            case 'start':
                range.setEndBefore(endEl);
                break;
            case 'end':
                range.setEndAfter(endEl);
                break;
            default:
                const endTarget = findRangeOffsetTarget(endEl, end.offset);
                if (endTarget) {
                    range.setEnd(endTarget.child, endTarget.offset);
                }
                break;
        }
        docSelection.removeAllRanges();
        docSelection.addRange(range);
        // // Extend is not supported in IE
        // if (reversed && docSelection.extend) {
        //   // Reversed selections in ranges are not supported, we need to scroll
        //   // the focus to the end of the selection so we can fix the position
        //   docSelection.extend(range.startContainer, range.startOffset);
        // }
    };
    if (!selection) {
        return;
    }
    setSelection(toDOMSelection(selection, blocks));
}
function toDOMLocation(l, blocks) {
    const block = blocks[l.block];
    if (!block) {
        return undefined;
    }
    return {
        block: block.id,
        offset: l.offset
    };
}
function toDOMSelection(l, blocks) {
    const start = toDOMLocation(l.start, blocks);
    const end = toDOMLocation(l.end, blocks);
    if (!start || !end) {
        return undefined;
    }
    return {
        start,
        end
    };
}
function fromDOMLocation(l, blocks) {
    const block = blocks.findIndex(b => b.id === l.block);
    if (block < 0) {
        return undefined;
    }
    return {
        block,
        offset: l.offset
    };
}
function fromDOMSelection(l, blocks) {
    const start = fromDOMLocation(l.start, blocks);
    const end = fromDOMLocation(l.end, blocks);
    if (!start || !end) {
        return undefined;
    }
    return {
        start,
        end
    };
}
export function isAtDOMStart(el, collapsedOnly = false) {
    const selection = document.getSelection();
    if (!selection ||
        !selection.focusNode ||
        !selection.isCollapsed ||
        !el ||
        (collapsedOnly && !selection.isCollapsed)) {
        return false;
    }
    const range = selection.getRangeAt(0);
    const preRange = document.createRange();
    preRange.selectNodeContents(el);
    preRange.setEnd(range.startContainer, range.startOffset);
    const text = preRange.cloneContents();
    return text && (!text.textContent || text.textContent.length === 0) ? true : false;
}
export function isAtDOMEnd(el, collapsedOnly = false, ignoreEndWhitespace = false) {
    const selection = document.getSelection();
    if (!selection ||
        !selection.focusNode ||
        !selection.isCollapsed ||
        !el ||
        (collapsedOnly && !selection.isCollapsed)) {
        return false;
    }
    const range = selection.getRangeAt(0);
    const postRange = document.createRange();
    postRange.selectNodeContents(el);
    postRange.setStart(range.endContainer, range.endOffset);
    const text = postRange.cloneContents();
    const textContent = text && text.textContent;
    if (text) {
        if (!text.textContent) {
            return true;
        }
        const t = textContent || '';
        return ignoreEndWhitespace ? t.trimRight().length === 0 : t.length === 0;
    }
    return false;
}
// See one version with fixes for Chrome here:
// https://github.com/facebook/draft-js/blob/fadf9ace40e5f1e869e50f75fa3a97769bf95aa2/
//  src/component/selection/getRangeClientRects.js
const getRangeClientRects = (range) => {
    return Array.from(range.getClientRects());
};
function getRangeBoundingClientRect(range) {
    // "Return a DOMRect object describing the smallest rectangle that includes
    // the first rectangle in list and all of the remaining rectangles of which
    // the height or width is not zero."
    // http://www.w3.org/TR/cssom-view/#dom-range-getboundingclientrect
    const rects = getRangeClientRects(range);
    let top = 0;
    let right = 0;
    let bottom = 0;
    let left = 0;
    if (rects.length) {
        ({ top, right, bottom, left } = rects[0]);
        for (let ii = 1; ii < rects.length; ii++) {
            const rect = rects[ii];
            if (rect.height !== 0 || rect.width !== 0) {
                top = Math.min(top, rect.top);
                right = Math.max(right, rect.right);
                bottom = Math.max(bottom, rect.bottom);
                left = Math.min(left, rect.left);
            }
        }
    }
    return {
        top,
        right,
        bottom,
        left,
        width: right - left,
        height: bottom - top
    };
}
export function getVisibleSelectionRect() {
    const s = window.getSelection();
    if (!s || !s.rangeCount) {
        return undefined;
    }
    const range = s.getRangeAt(0);
    const boundingRect = getRangeBoundingClientRect(range);
    const { top, right, bottom, left } = boundingRect;
    // When a re-render leads to a node being removed, the DOM selection will
    // temporarily be placed on an ancestor node, which leads to an invalid
    // bounding rect. Discard this state.
    if (top === 0 && right === 0 && bottom === 0 && left === 0) {
        return undefined;
    }
    return Object.assign(Object.assign({}, boundingRect), { top: top + window.scrollY, bottom: bottom + window.scrollY, left: left + window.scrollX, right: right + window.scrollX });
}
