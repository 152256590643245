import React, { useContext } from 'react';
import { isSelectionReversed } from '../model';
import { ModelContext } from './context';
export const Debug = () => {
    const { blocks, history, selection, selected } = useContext(ModelContext);
    return (React.createElement("small", null,
        React.createElement("pre", null,
            "Debug: reverse = ",
            isSelectionReversed(blocks, selection).toString(),
            JSON.stringify(selection, undefined, 2),
            React.createElement("br", null),
            "History:",
            `${history.location} in ${history.changes.length}`,
            React.createElement("br", null),
            "Blocks:",
            JSON.stringify(blocks, undefined, 2),
            React.createElement("br", null),
            "Selected:",
            JSON.stringify(selected.map(s => s.id), undefined, 2))));
};
