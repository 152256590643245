import { useMemo } from 'react';
import { TextStyle } from '../model';
export function useTextStyles(styles) {
    return useMemo(() => {
        const res = {};
        if (!styles) {
            return res;
        }
        styles.forEach(s => {
            switch (s) {
                case TextStyle.Bold:
                    res.bold = true;
                    break;
                case TextStyle.Italic:
                    res.italic = true;
                    break;
                case TextStyle.Strike:
                    res.strike = true;
                    break;
                case TextStyle.Sup:
                    res.sup = true;
                    break;
                case TextStyle.Sub:
                    res.sub = true;
                    break;
                case TextStyle.Underline:
                    res.underline = true;
                    break;
                case TextStyle.Code:
                    res.code = true;
                    break;
            }
        });
        return res;
    }, [styles]);
}
