import { Alignment } from '../../model';
import { CreateCategory } from '../../model/plugin';
import { EmbedPluginID } from './models';
// New creators
function createEmbed(id) {
    return {
        id,
        plugin: EmbedPluginID,
        alignment: Alignment.Left,
        media: {
            kind: 'none'
        }
    };
}
const newCreators = [
    {
        type: 'new',
        icon: 'widget',
        name: 'Embed',
        desc: 'Embed an external widget',
        category: CreateCategory.Integrations,
        ctor: (withID) => createEmbed(withID)
    }
];
export const creators = [...newCreators];
