import React, { useContext, useCallback, useRef, useState, useEffect } from 'react';
import { Portal, MenuItem } from '@blueprintjs/core';
import { getVisibleSelectionRect } from '../../dom';
import { searchEmoji, defaultEmojis, preloadEmojis } from './EmojiSelect';
import { EmojisComponent } from './styles';
import { scrollIntoView } from './utils';
import { ModelContext, ActionsContext, EditorContext, OptionsContext } from '../../Editor';
const offsetHeight = 10;
const searchLimit = 50;
const activeEmoji = {
    backgroundColor: 'rgba(167, 182, 194, 0.3)'
};
export const emojiPickerClass = 'emojies-picker';
export function isEmojiPickerOpen() {
    return document.querySelector(`.${emojiPickerClass}`) != null; // :not(:hidden) ?
}
export function Emoji() {
    const options = useContext(OptionsContext);
    const editor = useContext(EditorContext);
    const model = useContext(ModelContext);
    const actions = useContext(ActionsContext);
    const contexts = useRef({
        model,
        actions
    });
    contexts.current = {
        model,
        actions
    };
    const { focused, addEventListener, removeEventListener } = editor;
    const menuRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [filter, setFilter] = useState('');
    const [activePos, setActivePos] = useState(0);
    const [emojies, setEmojis] = useState([]);
    const onMouseEvent = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
    }, []);
    const handleEmojiSelect = useCallback((e) => {
        return () => {
            onEmojiSelect(e);
        };
    }, []);
    const onEmojiSelect = useCallback((e) => {
        if (!visible) {
            return;
        }
        setVisible(false);
        const selection = contexts.current.model.selection;
        const sel = selection ? selection.end : undefined;
        const curBlock = sel ? contexts.current.model.blocks[sel.block] : undefined;
        if (!curBlock || curBlock.type !== 'text' || !sel || !selection ||
            // +1 to account for : which is not part of the filter
            (sel.offset !== 'end' && sel.offset >= curBlock.text.length + filter.length + 1) ||
            (sel.offset === 'end' && curBlock.text.length < filter.length)) {
            // Should never happen though
            return;
        }
        const shift = sel.offset === 'end' ? curBlock.text.length : sel.offset;
        const frontPart = curBlock.text.substr(0, shift - filter.length - 1) + e.native;
        const endPart = curBlock.text.substr(shift);
        contexts.current.actions.updateText(curBlock.id, frontPart + endPart, true, {
            block: sel.block,
            offset: frontPart.length
        });
    }, [visible, filter]);
    useEffect(() => {
        if (!focused && visible) {
            setVisible(false);
        }
    }, [focused, visible]);
    useEffect(() => {
        if (options.readonly) {
            return;
        }
        preloadEmojis();
        function onKeyDown(event) {
            const key = event.which || event.keyCode;
            if (visible) {
                switch (key) {
                    case 27: // Escape
                    case 32: // Space
                        setVisible(false);
                        break;
                    case 38: // Arrow Up
                        if (activePos > 0) {
                            setActivePos(activePos - 1);
                            scrollIntoView(menuRef.current, activePos - 1);
                        }
                        event.preventDefault();
                        event.stopPropagation();
                        break;
                    case 40: // Arrow Down
                        if (activePos < emojies.length - 1) {
                            setActivePos(activePos + 1);
                            scrollIntoView(menuRef.current, activePos + 1);
                        }
                        event.preventDefault();
                        event.stopPropagation();
                        break;
                    case 13: // Enter
                        if (emojies.length > 0 && activePos >= 0 && activePos < emojies.length) {
                            onEmojiSelect(emojies[activePos]);
                        }
                        else {
                            setVisible(false);
                        }
                        event.preventDefault();
                        event.stopPropagation();
                        break;
                    case 8: // Backspace
                        // TODO This has a bug if you use shift when moving the cursor
                        // TODO Bug here and below in default if activePos gets out of the range
                        if (filter.length >= 1) {
                            const f = filter.substr(0, filter.length - 1);
                            setFilter(f);
                            setEmojis(searchEmoji(f, searchLimit));
                        }
                        else {
                            setVisible(false);
                        }
                        break;
                    default:
                        if (event.key !== '' && event.key.length === 1 && /^[a-z0-9]+$/i.test(event.key)) {
                            const f = filter + event.key;
                            setFilter(f);
                            setEmojis(searchEmoji(f, searchLimit));
                        }
                        else {
                            setVisible(false);
                        }
                }
            }
            else {
                switch (key) {
                    case 59: // Mozilla / Opera for :
                    case 186: // Chrome / IE for :
                        if (event.shiftKey) {
                            setVisible(true);
                            setFilter('');
                            setEmojis(defaultEmojis);
                            setActivePos(0);
                            break;
                        }
                }
            }
        }
        addEventListener('onKeyDown', onKeyDown);
        return () => {
            removeEventListener('onKeyDown', onKeyDown);
        };
    }, [options.readonly, activePos, visible, emojies, filter, searchLimit, addEventListener, removeEventListener]);
    if (!visible || options.readonly) {
        return null;
    }
    const rect = getVisibleSelectionRect();
    const { left, bottom } = rect || { left: 0, top: 0, width: 0, bottom: 0 };
    return (React.createElement(Portal, { className: 'bp4-dark' },
        React.createElement(EmojisComponent, { className: emojiPickerClass, visible: visible, style: {
                // backgroundColor: props.focused ? 'red' : 'green',
                left,
                top: bottom + offsetHeight
            }, onMouseDown: onMouseEvent, onMouseUp: onMouseEvent }, visible && (React.createElement("ul", { className: 'bp4-menu bp4-elevation-1', style: {
                overflow: 'auto', maxHeight: 250
            }, ref: menuRef }, emojies.length === 0 ? (React.createElement(MenuItem, { disabled: true, text: 'Nothing \uD83D\uDE1E' })) : (emojies.map((e, ei) => (React.createElement("li", { key: e.name, style: Object.assign({}, (ei === activePos ? activeEmoji : {})) },
            React.createElement("button", { type: 'button', className: 'bp4-menu-item', onClick: handleEmojiSelect(e) },
                React.createElement("span", null, e.native),
                "\u00A0",
                e.text))))))))));
}
