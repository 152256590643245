import { FocusPosition } from './plugin';
import { newBlockID, createBlankDoc } from './utils';
import { FocusTarget } from './state';
function cloneImpl(plugins, blocks, bid, newID, cloneChildren) {
    const b = typeof bid === 'string' ? blocks[bid] : bid;
    const plugin = plugins[b.plugin];
    if (!plugin) {
        throw new Error(`Plugin is not found ${b.plugin} for object ${b.id}`);
    }
    const cloned = plugin.codec ? plugin.codec.fromJSON(plugin.codec.toJSON(b)) : JSON.parse(JSON.stringify(b));
    if (newID) {
        cloned.id = newBlockID();
    }
    const res = {
        cloned: cloned,
        children: []
    };
    if (cloneChildren && plugin.container) {
        const childrenIDs = plugin.container.children(cloned);
        for (const cid of childrenIDs) {
            const childCloned = cloneImpl(plugins, blocks, cid, newID, cloneChildren);
            if (newID) {
                plugin.container.replace(cloned, cid, childCloned.cloned.id);
            }
            res.children.push(childCloned.cloned);
            if (childCloned.children) {
                res.children.push(...childCloned.children);
            }
        }
    }
    if (!res.children || res.children.length === 0) {
        // @ts-ignore
        delete res.children;
    }
    return res;
}
function cloneDocImpl(plugins, d, newIDs) {
    const blocks = {};
    const cloned = cloneImpl(plugins, d.blocks, d.root, newIDs, true);
    blocks[cloned.cloned.id] = cloned.cloned;
    if (cloned.children) {
        for (const ch of cloned.children) {
            blocks[ch.id] = ch;
        }
    }
    return {
        root: cloned.cloned.id,
        blocks
    };
}
export function buildOptions(options) {
    const pluginsMap = {};
    options.plugins.forEach(p => pluginsMap[p.id] = p);
    return {
        plugins: pluginsMap,
        debug: options.debug,
        readonly: options.readonly,
        observe: options.observe || {}
    };
}
export function buildModel(options, doc) {
    const { readonly, plugins } = options;
    function buildParents(d) {
        const res = {};
        const assignParent = (b) => {
            if (!b) {
                return;
            }
            const bp = plugins[b.plugin];
            if (!bp || !bp.container) {
                return;
            }
            bp.container.children(b).forEach(c => {
                res[c] = b.id;
                assignParent(d.blocks[c]);
            });
        };
        assignParent(d.blocks[d.root]);
        if (Object.keys(d.blocks).length - 1 !== Object.keys(res).length) {
            throw new Error('Inconsistent document or plugin resulted in wrong number of parent relations.');
        }
        return res;
    }
    const dc = doc || createBlankDoc();
    const pa = buildParents(dc);
    if (readonly) {
        return {
            doc: dc,
            parents: pa
        };
    }
    return {
        // Make a clone here
        doc: cloneDocImpl(plugins, dc),
        parents: pa
    };
}
export function docCollectChildren(plugins, d, id, includeSelf) {
    const bid = typeof id === 'string' ? id : id.id;
    const res = includeSelf ? [bid] : [];
    const collect = (b) => {
        if (!b) {
            return;
        }
        const bp = plugins[b.plugin];
        if (!bp || !bp.container) {
            return;
        }
        bp.container.children(b).forEach(c => {
            res.push(c);
            collect(d.blocks[c]);
        });
    };
    collect(d.blocks[bid]);
    return res;
}
export function buildActions(options, model, onUpdated, onRedraw) {
    function collectParents(d, id, includeSelf) {
        const bid = typeof id === 'string' ? id : id.id;
        const res = [];
        let b = includeSelf ? bid : d[bid];
        while (b) {
            res.push(b);
            b = d[b];
        }
        return res;
    }
    function clone(bid, newID, cloneChildren) {
        const { plugins } = options;
        const { blocks } = model.doc;
        return cloneImpl(plugins, blocks, bid, newID, cloneChildren);
    }
    function children(id, includeSelf) {
        const { plugins } = options;
        const { doc } = model;
        return docCollectChildren(plugins, doc, id, includeSelf);
    }
    function findParent(id, within) {
        const { plugins } = options;
        const withinArray = Array.isArray(within) ? within : within ? [within] : [];
        let parent;
        for (const ub of withinArray) {
            const ubp = plugins[ub.plugin];
            if (!ubp.container) {
                continue;
            }
            if (ubp.container.children(ub).indexOf(id) >= 0) {
                parent = ub.id;
            }
        }
        return parent;
    }
    function update(updated, added, deleted) {
        const { plugins } = options;
        const { doc, parents } = model;
        const updates = Array.isArray(updated) ? updated : updated ? [updated] : [];
        const additions = Array.isArray(added) ? added : added ? [added] : [];
        const deletions = Array.isArray(deleted) ? deleted : deleted ? [deleted] : [];
        const redraws = [];
        updates.forEach(u => {
            doc.blocks[u.id] = u;
            redraws.push(...collectParents(parents, u.id, true));
            // Updates might move elements around, let's for containers update
            // parents of their children
            const up = plugins[u.plugin];
            if (up && up.container) {
                for (const uc of up.container.children(u)) {
                    parents[uc] = u.id;
                }
            }
        });
        additions.forEach(a => {
            doc.blocks[a.id] = a;
            // To be optimal, we need to locate a parent for this
            // block. It is required that if a parent changes - the update
            // is issued in the list of updates. Impossible to have something
            // added without attaching it to something.
            const parent = findParent(a.id, updates) || findParent(a.id, additions);
            if (!parent) {
                throw new Error(`Trying to insert block ${a.id}, without updating its parent.`);
            }
            parents[a.id] = parent;
            // If we added a container, we need to update all its children parents
            const ap = plugins[a.plugin];
            if (!ap) {
                throw new Error(`Trying to insert block ${a.id}, with unknown parent ${a.plugin}.`);
            }
            if (ap.container) {
                ap.container.children(a).forEach(ac => (parents[ac] = a.id));
            }
            redraws.push(...collectParents(parents, a.id, true));
        });
        deletions.forEach(d => {
            const did = typeof d === 'string' ? d : d.id;
            // TODO When we delete, we need to make sure we have updated https://github.com/ProtoForce/protoforce-portal-webui/issues/26
            // the parent as well. Should be only in debug.
            const dch = docCollectChildren(plugins, doc, did, true);
            redraws.push(...collectParents(parents, did, true));
            delete doc.blocks[did];
            delete parents[did];
            dch.forEach(ch => delete parents[ch]);
        });
        if (redraws.length > 0) {
            redraws.forEach(r => {
                const rb = doc.blocks[r];
                if (!rb) {
                    // parent might have been deleted, therefore
                    // we can't really redraw it, ignore
                    return;
                }
                doc.blocks[r] = Object.assign({}, rb);
            });
            onRedraw();
        }
        onUpdated();
    }
    function prev(id, ofType) {
        let block = findPrevBlock(id);
        if (!ofType) {
            return block;
        }
        while (block && block.plugin !== ofType) {
            block = findPrevBlock(block.id);
        }
        return block;
    }
    function findPrevBlock(id) {
        const { plugins } = options;
        const { parents, doc } = model;
        const parentID = parents[id];
        if (!parentID) {
            return undefined;
        }
        const pb = doc.blocks[parentID];
        if (!pb) {
            return undefined;
        }
        const pp = plugins[pb.plugin];
        if (!pp || !pp.container) {
            return undefined;
        }
        const prevBlock = pp.container.prev(pb, id);
        if (prevBlock && prevBlock !== parentID) {
            // When going back, if we get a new element we need to
            // make sure drill down to the last element of that container
            const drillDown = (did) => {
                const db = doc.blocks[did];
                if (!db) {
                    throw new Error('Inconsistent document state');
                }
                const dp = plugins[db.plugin];
                if (!dp) {
                    throw new Error('Inconsistent document plugins state');
                }
                if (!dp.container) {
                    return did;
                }
                const dch = dp.container.children(db);
                if (dch.length === 0) {
                    return did;
                }
                return drillDown(dch[dch.length - 1]);
            };
            const last = drillDown(prevBlock);
            return doc.blocks[last];
        }
        return (prevBlock ? doc.blocks[prevBlock] : undefined);
    }
    function next(id, ofType) {
        let block = findNextBlock(id);
        if (!ofType) {
            return block;
        }
        while (block && block.plugin !== ofType) {
            block = findNextBlock(block.id);
        }
        return block;
    }
    function findNextBlock(id, stepOver) {
        const { doc, parents } = model;
        const { plugins } = options;
        const idb = doc.blocks[id];
        if (!idb) {
            return undefined;
        }
        const idp = plugins[idb.plugin];
        if (!stepOver && idp && idp.container) {
            const idpn = idp.container.next(idb, id);
            return (idpn ? doc.blocks[idpn] : findNextBlock(idb.id, true));
        }
        const parentID = parents[id];
        if (!parentID) {
            return undefined;
        }
        const pb = doc.blocks[parentID];
        if (!pb) {
            return undefined;
        }
        const pp = plugins[pb.plugin];
        if (!pp || !pp.container) {
            return undefined;
        }
        const nextBlock = pp.container.next(pb, id);
        return (nextBlock ? doc.blocks[nextBlock] : findNextBlock(pb.id, true));
    }
    function getParent(id) {
        const { doc, parents } = model;
        const { plugins } = options;
        const par = parents[id];
        if (!par) {
            console.warn(`Didn't find parent for block ${id}`);
            return undefined;
        }
        const parentBlock = doc.blocks[par];
        if (!parentBlock) {
            console.warn(`Inconsistent model, parent reference ${par} is broken for block ${id}`);
            return undefined;
        }
        const parentPlugin = plugins[parentBlock.plugin];
        if (!parentPlugin || !parentPlugin.container) {
            console.warn(`Inconsistent model, parent ${par} plugin ${parentBlock.plugin} is not found or not a container`);
            return;
        }
        return {
            parent: parentBlock,
            plugin: parentPlugin
        };
    }
    function focus(id, behavior, pos, immediate) {
        const { doc } = model;
        const { plugins } = options;
        const focusBlock = (b, p) => {
            if (!b) {
                return false;
            }
            const bp = plugins[b.plugin];
            if (!bp || !bp.focus) {
                return false;
            }
            if (immediate) {
                bp.focus(b, p || FocusPosition.Start);
            }
            else {
                setTimeout(() => {
                    if (!bp.focus) {
                        return;
                    }
                    bp.focus(b, p || FocusPosition.Start);
                }, 1);
            }
            return true;
        };
        switch (behavior) {
            case FocusTarget.Previous:
                // For previous, we want to find the next focusable item, so
                // we just roll through until we achieve something that is focusable
                let pb = prev(id);
                while (pb && !focusBlock(pb, pos)) {
                    pb = prev(pb.id);
                }
                break;
            case FocusTarget.Next:
                let nb = next(id);
                while (nb && !focusBlock(nb, pos)) {
                    nb = next(nb.id);
                }
                break;
            case FocusTarget.Current:
            default:
                return focusBlock(doc.blocks[id], pos);
        }
        return false;
    }
    function insert(block, after, focusAfter, add, bubbleOnFail) {
        let parentInfo;
        let parentCloned;
        let current = after;
        while (!parentInfo) {
            parentInfo = getParent(current);
            if (!parentInfo) {
                break;
            }
            const { parent, plugin: parentPlugin } = parentInfo;
            parentCloned = clone(parent, false);
            const insertResult = parentPlugin.container.insert(parentCloned.cloned, (Array.isArray(block) ? block : [block]).map(b => b.id), current);
            if (!insertResult) {
                if (bubbleOnFail) {
                    current = parent.id;
                    parentInfo = undefined;
                }
                else {
                    break;
                }
            }
        }
        if (!parentInfo || !parentCloned) {
            return;
        }
        const blocks = Array.isArray(block) ? block : [block];
        if (add) {
            blocks.push(...add);
        }
        update(parentCloned.cloned, blocks);
        if (typeof focusAfter !== 'undefined') {
            const focusOn = typeof focusAfter === 'boolean' ? (Array.isArray(block) ? block[block.length - 1] : block).id : focusAfter;
            focus(focusOn);
        }
    }
    function remove(block) {
        const { plugins } = options;
        const blockChildren = children(block.id, false);
        const parent = model.parents[block.id];
        const parentBlock = model.doc.blocks[parent];
        const parentPlugin = plugins[parentBlock.plugin];
        if (!parentPlugin.container) {
            console.warn(`Trying to delete block ${block.id} which has not parent container.`);
            return;
        }
        const cloned = clone(parentBlock);
        parentPlugin.container.remove(cloned.cloned, block.id);
        let focusBlock = prev(block.id);
        let focusEnd = true;
        if (!focusBlock) {
            focusBlock = next(block.id);
            focusEnd = false;
        }
        update(cloned.cloned, undefined, blockChildren ? [block.id, ...blockChildren] : block.id);
        if (focusBlock) {
            focus(focusBlock.id, undefined, focusEnd ? FocusPosition.End : FocusPosition.Start, true);
        }
    }
    return {
        clone,
        prev,
        next,
        focus,
        children,
        parent: getParent,
        update,
        insert,
        remove
    };
}
