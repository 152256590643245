import styled from 'styled-components';
export const Wrapper = styled.div `
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
`;
export const VideoIFrame = styled.iframe `
  display: block;
  width: 100%;
  height: 100%;
  cursor: default;
  border-width: 0;
`;
export const VideoResizableFrame = styled.div `
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 5px;
  overflow: hidden;
`;
