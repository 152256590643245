import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react';
import { Icon } from '@blueprintjs/core';
import { Wrapper, DropInput, DropCTA, DropImage } from './styles';
import { AcceptImages } from './plugin';
const AcceptAll = '*/*';
const noFile = {
    kind: 'none'
};
function checkType(types, item) {
    if (typeof item !== 'string' && item.kind !== 'file') {
        return false;
    }
    return types.indexOf(typeof item === 'string' ? item : item.type) >= 0;
}
export const Upload = React.memo((props) => {
    const { config, disabled, blockID, onChanged } = props;
    const multiple = config && config.multiple ? true : false;
    const maxFileSize = config && config.maxFileSize;
    const acceptArray = useMemo(() => {
        return config && config.accept ? (Array.isArray(config.accept) ? config.accept : [config.accept]) : [AcceptAll];
    }, [config]);
    const accept = useMemo(() => {
        return acceptArray.join(', ');
    }, [acceptArray]);
    const fileRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [over, setOver] = useState(false);
    const [preview, setPreview] = useState();
    const validateFiles = useCallback((items, checkSize) => {
        const res = [];
        if (disabled || items.length === 0) {
            return res;
        }
        if (!multiple && items.length > 1) {
            return res;
        }
        // tslint:disable-next-line
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (accept !== AcceptAll && !checkType(acceptArray, item)) {
                continue;
            }
            if (checkSize && maxFileSize) {
                const itemFile = item.getAsFile();
                if (!itemFile) {
                    continue;
                }
                if (itemFile.size > maxFileSize) {
                    continue;
                }
            }
            res.push(item);
        }
        return res;
    }, [disabled, accept, multiple, maxFileSize, acceptArray]);
    const processFiles = useCallback((fl) => {
        if (!fl || fl.length === 0 || (!multiple && fl.length > 1)) {
            return;
        }
        const dtFiles = [];
        // tslint:disable-next-line
        for (let i = 0; i < fl.length; i++) {
            const f = fl[i];
            if (accept !== AcceptAll && !checkType(acceptArray, f.type)) {
                continue;
            }
            if (maxFileSize && f.size > maxFileSize) {
                continue;
            }
            dtFiles.push(fl[i]);
        }
        if (multiple) {
            throw new Error('Multiple is not supported yet.');
        }
        if (!config) {
            return;
        }
        setFiles(dtFiles);
    }, [multiple, setFiles, acceptArray, accept, config, maxFileSize]);
    const onClick = useCallback(() => {
        if (!fileRef.current) {
            return;
        }
        fileRef.current.click();
    }, []);
    const onFilePicked = useCallback((event) => {
        if (disabled) {
            return;
        }
        processFiles(event.currentTarget.files);
    }, [disabled, processFiles]);
    const onDragEnter = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        const filtered = validateFiles(event.dataTransfer.items, false);
        if (filtered.length === 0) {
            return;
        }
        setOver(true);
    }, [validateFiles, setOver]);
    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);
    const onDragLeave = useCallback((event) => {
        setOver(false);
        event.preventDefault();
        event.stopPropagation();
    }, [setOver]);
    const onDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!over || disabled) {
            return;
        }
        const filtered = validateFiles(event.dataTransfer.items, false);
        if (filtered.length === 0) {
            return;
        }
        processFiles(event.dataTransfer.files);
        event.dataTransfer.clearData();
        setOver(false);
    }, [over, disabled, validateFiles, processFiles, setOver]);
    const upload = useCallback((cfg, fs) => {
        if (fs.length === 0) {
            throw new Error('Tried to upload with no files provided.');
        }
        return new Promise((resolve, reject) => {
            const uploader = cfg.upload;
            const file = fs[0];
            uploader(blockID, file)
                .then((data) => {
                const mediaFile = Object.assign({ kind: 'upload', size: file.size }, data);
                resolve(mediaFile);
            }, err => {
                console.error(err);
                reject('' + err);
            })
                .catch(err => {
                console.error(err);
                reject('' + err);
            });
        });
    }, []);
    useEffect(() => {
        if (!files.length) {
            setPreview(undefined);
            return;
        }
        // TODO Support for multiple images preview https://github.com/ProtoForce/protoforce-portal-webui/issues/23
        const file = files[0];
        if (AcceptImages.indexOf(file.type) < 0) {
            setPreview(undefined);
            return;
        }
        const previewObject = URL.createObjectURL(file);
        setPreview(previewObject);
        return () => {
            if (previewObject) {
                URL.revokeObjectURL(previewObject);
            }
        };
    }, [files]);
    useEffect(() => {
        if (!files.length || !config) {
            onChanged(noFile);
            return;
        }
        const f = () => upload(config, files);
        onChanged(f, files[0].name);
    }, [files, upload]);
    if (!config) {
        return React.createElement("span", null, "Upload plugin requires an upload function to be provided in the config.");
    }
    return (React.createElement(Wrapper, { onClick: onClick, onDragEnter: onDragEnter, onDragOver: onDragOver, onDragLeave: onDragLeave, onDrop: onDrop, over: over, readonly: disabled },
        React.createElement(DropInput, { ref: fileRef, disabled: disabled, type: 'file', accept: accept, onInput: onFilePicked }),
        preview && React.createElement(DropImage, { src: preview }),
        !preview && (React.createElement(DropCTA, null,
            React.createElement(Icon, { icon: 'media', iconSize: 48 }),
            React.createElement("span", { style: { marginTop: 20 } }, files && files.length > 0
                ? `Files: ${files.map(f => f.name).join(', ')}`
                : 'Click to pick or drop a file here.')))));
});
