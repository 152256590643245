import React from 'react';
export const BlockRenderer = React.memo((props) => {
    const { block, plugin } = props;
    if (!plugin || !plugin.renderer) {
        return React.createElement("span", null,
            "No plugin or renderer for block of plugin: ",
            block.plugin);
    }
    const Component = plugin.renderer;
    return React.createElement(Component, { block: block });
});
