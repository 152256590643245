import styled from 'styled-components';
export const Wrapper = styled.div `
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
`;
export const Img = styled.img `
  display: block;
  border-radius: 0;
  max-width: 100%;
  height: auto;
  cursor: default;
  opacity: 1;
  object-position: center 0;
`;
export const ImageResizableFrame = styled.div `
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: 5px;
  overflow: hidden;
`;
export const ImgView = styled.img `
  pointer-events: none;
  max-width: calc(100% - 50px);
  max-height: calc(100% - 50px) l;
`;
