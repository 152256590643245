export var CodeLanguage;
(function (CodeLanguage) {
    CodeLanguage["Arduino"] = "Arduino";
    CodeLanguage["Bash"] = "Bash";
    CodeLanguage["Basic"] = "Basic";
    CodeLanguage["C"] = "C";
    CodeLanguage["Clojure"] = "Clojure";
    CodeLanguage["CoffeeScript"] = "CoffeeScript";
    CodeLanguage["CPP"] = "CPP";
    CodeLanguage["CSharp"] = "CSharp";
    CodeLanguage["CSS"] = "CSS";
    CodeLanguage["Dart"] = "Dart";
    CodeLanguage["Docker"] = "Docker";
    CodeLanguage["Elixir"] = "Elixir";
    CodeLanguage["Elm"] = "Elm";
    CodeLanguage["Erlang"] = "Erlang";
    CodeLanguage["Flow"] = "Flow";
    CodeLanguage["Fortran"] = "Fortran";
    CodeLanguage["FSharp"] = "FSharp";
    CodeLanguage["Gherkin"] = "Gherkin";
    CodeLanguage["GLSL"] = "GLSL";
    CodeLanguage["Go"] = "Go";
    CodeLanguage["GraphQL"] = "GraphQL";
    CodeLanguage["Groovy"] = "Groovy";
    CodeLanguage["Haskell"] = "Haskell";
    CodeLanguage["HOCON"] = "HOCON";
    CodeLanguage["HTML"] = "HTML";
    CodeLanguage["Java"] = "Java";
    CodeLanguage["JavaScript"] = "JavaScript";
    CodeLanguage["JSON"] = "JSON";
    CodeLanguage["Kotlin"] = "Kotlin";
    CodeLanguage["LaTeX"] = "LaTeX";
    CodeLanguage["Less"] = "Less";
    CodeLanguage["Lisp"] = "Lisp";
    CodeLanguage["LiveScript"] = "LiveScript";
    CodeLanguage["Lua"] = "Lua";
    CodeLanguage["Makefile"] = "Makefile";
    CodeLanguage["Markdown"] = "Markdown";
    CodeLanguage["Markup"] = "Markup";
    CodeLanguage["MATLAB"] = "MATLAB";
    CodeLanguage["Nix"] = "Nix";
    CodeLanguage["ObjectiveC"] = "ObjectiveC";
    CodeLanguage["Pascal"] = "Pascal";
    CodeLanguage["Perl"] = "Perl";
    CodeLanguage["ProtoForce"] = "ProtoForce";
    CodeLanguage["Protobuf"] = "Protobuf";
    CodeLanguage["PHP"] = "PHP";
    CodeLanguage["PowerShell"] = "PowerShell";
    CodeLanguage["Prolog"] = "Prolog";
    CodeLanguage["Python"] = "Python";
    CodeLanguage["R"] = "R";
    CodeLanguage["Ruby"] = "Ruby";
    CodeLanguage["Rust"] = "Rust";
    CodeLanguage["SASS"] = "SASS";
    CodeLanguage["Scala"] = "Scala";
    CodeLanguage["SCSS"] = "SCSS";
    CodeLanguage["SQL"] = "SQL";
    CodeLanguage["Swift"] = "Swift";
    CodeLanguage["TypeScript"] = "TypeScript";
    CodeLanguage["VBNet"] = "VBNet";
    CodeLanguage["VisualBasic"] = "VisualBasic";
    CodeLanguage["WebAssembly"] = "WebAssembly";
    CodeLanguage["XML"] = "XML";
    CodeLanguage["YAML"] = "YAML";
    CodeLanguage["PlainText"] = "PlainText";
    CodeLanguage["Shell"] = "Shell";
})(CodeLanguage || (CodeLanguage = {}));
export const allCodeLanguages = [
    CodeLanguage.Arduino,
    CodeLanguage.Bash,
    CodeLanguage.Basic,
    CodeLanguage.C,
    CodeLanguage.Clojure,
    CodeLanguage.CoffeeScript,
    CodeLanguage.CPP,
    CodeLanguage.CSharp,
    CodeLanguage.CSS,
    CodeLanguage.Dart,
    CodeLanguage.Docker,
    CodeLanguage.Elixir,
    CodeLanguage.Elm,
    CodeLanguage.Erlang,
    CodeLanguage.Flow,
    CodeLanguage.Fortran,
    CodeLanguage.FSharp,
    CodeLanguage.Gherkin,
    CodeLanguage.GLSL,
    CodeLanguage.Go,
    CodeLanguage.GraphQL,
    CodeLanguage.Groovy,
    CodeLanguage.Haskell,
    CodeLanguage.HOCON,
    CodeLanguage.HTML,
    CodeLanguage.Java,
    CodeLanguage.JavaScript,
    CodeLanguage.JSON,
    CodeLanguage.Kotlin,
    CodeLanguage.LaTeX,
    CodeLanguage.Less,
    CodeLanguage.Lisp,
    CodeLanguage.LiveScript,
    CodeLanguage.Lua,
    CodeLanguage.Makefile,
    CodeLanguage.Markdown,
    CodeLanguage.Markup,
    CodeLanguage.MATLAB,
    CodeLanguage.Nix,
    CodeLanguage.ObjectiveC,
    CodeLanguage.Pascal,
    CodeLanguage.Perl,
    CodeLanguage.ProtoForce,
    CodeLanguage.Protobuf,
    CodeLanguage.PHP,
    CodeLanguage.PowerShell,
    CodeLanguage.Prolog,
    CodeLanguage.Python,
    CodeLanguage.R,
    CodeLanguage.Ruby,
    CodeLanguage.Rust,
    CodeLanguage.SASS,
    CodeLanguage.Scala,
    CodeLanguage.SCSS,
    CodeLanguage.SQL,
    CodeLanguage.Swift,
    CodeLanguage.TypeScript,
    CodeLanguage.VBNet,
    CodeLanguage.VisualBasic,
    CodeLanguage.WebAssembly,
    CodeLanguage.XML,
    CodeLanguage.YAML,
    CodeLanguage.PlainText,
    CodeLanguage.Shell
];
