import React, { useEffect, useRef, useState, useCallback } from 'react';
import Prism from 'prismjs';
import { Select } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';
import { useLayoutEffect } from '~/core/hooks';
import './prism.scss';
import { prismProtoforce, prismHocon } from './prism';
import { CodeLanguage, allCodeLanguages } from './lang';
import { intentFromKeyboardEvent, isAtDOMStart, isAtDOMEnd, InputIntent } from '../../../RIDInlineEditor';
import { Wrapper, Editable, EditableControls } from './styles';
const CodeLanguageName = {
    [CodeLanguage.CPP]: 'C++',
    [CodeLanguage.CSharp]: 'C#',
    [CodeLanguage.FSharp]: 'F#',
    [CodeLanguage.VBNet]: 'VB.Net',
    [CodeLanguage.ObjectiveC]: 'Objective-C',
    [CodeLanguage.PlainText]: 'Plain Text'
};
function getPrismHighlight(l) {
    switch (l) {
        case CodeLanguage.Bash:
            return { grammar: Prism.languages.bash, lang: 'bash' };
        case CodeLanguage.Basic:
            return { grammar: Prism.languages.basic, lang: 'basic' };
        case CodeLanguage.C:
            return { grammar: Prism.languages.c, lang: 'c' };
        case CodeLanguage.Clojure:
            return { grammar: Prism.languages.clojure, lang: 'clojure' };
        case CodeLanguage.CoffeeScript:
            return { grammar: Prism.languages.coffeescript, lang: 'coffeescript' };
        case CodeLanguage.CPP:
            return { grammar: Prism.languages.cpp, lang: 'cpp' };
        case CodeLanguage.CSharp:
            return { grammar: Prism.languages.csharp, lang: 'csharp' };
        case CodeLanguage.CSS:
            return { grammar: Prism.languages.css, lang: 'css' };
        case CodeLanguage.Dart:
            return { grammar: Prism.languages.dart, lang: 'dart' };
        case CodeLanguage.Docker:
            return { grammar: Prism.languages.docker, lang: 'docker' };
        case CodeLanguage.Elixir:
            return { grammar: Prism.languages.elixir, lang: 'elixir' };
        case CodeLanguage.Elm:
            return { grammar: Prism.languages.elm, lang: 'elm' };
        case CodeLanguage.Erlang:
            return { grammar: Prism.languages.erlang, lang: 'erlang' };
        case CodeLanguage.Flow:
            return { grammar: Prism.languages.flow, lang: 'flow' };
        case CodeLanguage.Fortran:
            return { grammar: Prism.languages.fortran, lang: 'fortran' };
        case CodeLanguage.FSharp:
            return { grammar: Prism.languages.fsharp, lang: 'fsharp' };
        case CodeLanguage.Gherkin:
            return { grammar: Prism.languages.gherkin, lang: 'gherkin' };
        case CodeLanguage.GLSL:
            return { grammar: Prism.languages.glsl, lang: 'glsl' };
        case CodeLanguage.Go:
            return { grammar: Prism.languages.go, lang: 'go' };
        case CodeLanguage.GraphQL:
            return { grammar: Prism.languages.graphql, lang: 'graphql' };
        case CodeLanguage.Groovy:
            return { grammar: Prism.languages.groovy, lang: 'groovy' };
        case CodeLanguage.Haskell:
            return { grammar: Prism.languages.haskell, lang: 'haskell' };
        case CodeLanguage.HOCON:
            return { grammar: prismHocon, lang: 'hocon' };
        case CodeLanguage.HTML:
            return { grammar: Prism.languages.markup, lang: 'html' };
        case CodeLanguage.Java:
            return { grammar: Prism.languages.java, lang: 'java' };
        case CodeLanguage.JavaScript:
            return { grammar: Prism.languages.javascript, lang: 'javascript' };
        case CodeLanguage.JSON:
            return { grammar: Prism.languages.json, lang: 'json' };
        case CodeLanguage.Kotlin:
            return { grammar: Prism.languages.kotlin, lang: 'kotlin' };
        case CodeLanguage.LaTeX:
            return { grammar: Prism.languages.latex, lang: 'latex' };
        case CodeLanguage.Less:
            return { grammar: Prism.languages.less, lang: 'less' };
        case CodeLanguage.Lisp:
            return { grammar: Prism.languages.lisp, lang: 'lisp' };
        case CodeLanguage.LiveScript:
            return { grammar: Prism.languages.livescript, lang: 'livescript' };
        case CodeLanguage.Lua:
            return { grammar: Prism.languages.lua, lang: 'lua' };
        case CodeLanguage.Makefile:
            return { grammar: Prism.languages.makefile, lang: 'makefile' };
        case CodeLanguage.Markdown:
            return { grammar: Prism.languages.markdown, lang: 'markdown' };
        case CodeLanguage.Markup:
            return { grammar: Prism.languages.markup, lang: 'markup' };
        case CodeLanguage.MATLAB:
            return { grammar: Prism.languages.matlab, lang: 'matlab' };
        case CodeLanguage.Nix:
            return { grammar: Prism.languages.nix, lang: 'nix' };
        case CodeLanguage.ObjectiveC:
            return { grammar: Prism.languages.objectivec, lang: 'objectivec' };
        case CodeLanguage.Pascal:
            return { grammar: Prism.languages.pascal, lang: 'pascal' };
        case CodeLanguage.Perl:
            return { grammar: Prism.languages.perl, lang: 'perl' };
        case CodeLanguage.PHP:
            return { grammar: Prism.languages.php, lang: 'php' };
        case CodeLanguage.PowerShell:
            return { grammar: Prism.languages.powershell, lang: 'powershell' };
        case CodeLanguage.Prolog:
            return { grammar: Prism.languages.prolog, lang: 'prolog' };
        case CodeLanguage.Protobuf:
            return { grammar: Prism.languages.protobuf, lang: 'protobuf' };
        case CodeLanguage.ProtoForce:
            return { grammar: prismProtoforce, lang: 'protoforce' };
        case CodeLanguage.Python:
            return { grammar: Prism.languages.python, lang: 'python' };
        case CodeLanguage.R:
            return { grammar: Prism.languages.r, lang: 'r' };
        case CodeLanguage.Ruby:
            return { grammar: Prism.languages.ruby, lang: 'ruby' };
        case CodeLanguage.Rust:
            return { grammar: Prism.languages.rust, lang: 'rust' };
        case CodeLanguage.SASS:
            return { grammar: Prism.languages.sass, lang: 'sass' };
        case CodeLanguage.Scala:
            return { grammar: Prism.languages.scala, lang: 'scala' };
        case CodeLanguage.SCSS:
            return { grammar: Prism.languages.scss, lang: 'scss' };
        case CodeLanguage.SQL:
            return { grammar: Prism.languages.sql, lang: 'sql' };
        case CodeLanguage.Swift:
            return { grammar: Prism.languages.swift, lang: 'swift' };
        case CodeLanguage.TypeScript:
            return { grammar: Prism.languages.typescript, lang: 'typescript' };
        case CodeLanguage.VBNet:
            return { grammar: Prism.languages.vbnet, lang: 'vbnet' };
        case CodeLanguage.VisualBasic:
            return { grammar: Prism.languages.vb, lang: 'visual-basic' };
        case CodeLanguage.WebAssembly:
            return { grammar: Prism.languages.wasm, lang: 'wasm' };
        case CodeLanguage.XML:
            return { grammar: Prism.languages.markup, lang: 'xml' };
        case CodeLanguage.YAML:
            return { grammar: Prism.languages.yaml, lang: 'yaml' };
        case CodeLanguage.Shell:
            return { grammar: Prism.languages.shell, lang: 'shell' };
        case CodeLanguage.PlainText:
        default:
            return undefined;
    }
}
const LangSelect = Select.ofType();
const dummyOnUpdate = (code, language) => {
    // nop
};
const codeForcedTerminator = '\n\r';
export function PrismCode(props) {
    const { code, defaultCode, language, onCopy: onCopyCallback, defaultLanguage, disabled, disabledLanguage, onUpdate = dummyOnUpdate, onPrevParagraph, onNextParagraph } = props;
    const initialCode = code || defaultCode || '';
    const initialLanguage = language || defaultLanguage || CodeLanguage.ProtoForce;
    const ref = useRef(null);
    const [lang, setLang] = useState(initialLanguage);
    const highlight = useCallback((text, l) => {
        const ph = getPrismHighlight(l);
        if (ph) {
            const htmlMarkup = Prism.highlight(text, ph.grammar, ph.lang);
            // We need br in the end so that it shows new lines, or it won't display
            // them.
            return htmlMarkup + '<br>';
        }
        return text;
    }, []);
    const [html, setHtml] = useState(() => highlight(initialCode, initialLanguage));
    const [content, setContent] = useState(initialCode);
    const [nextCaretPos, setNextCaretPos] = useState();
    const getTextNodeOffset = useCallback((node, offset) => {
        if (!ref.current) {
            return undefined;
        }
        const range = document.createRange();
        range.selectNodeContents(ref.current);
        range.setEnd(node, offset);
        const text = range.cloneContents();
        return (text.textContent || '').length;
    }, []);
    const getTextPosition = useCallback(() => {
        const sel = document.getSelection();
        if (!sel || sel.rangeCount === 0 || !ref.current) {
            return undefined;
        }
        const range = sel.getRangeAt(0);
        return getTextNodeOffset(range.endContainer, range.endOffset);
    }, [getTextNodeOffset]);
    const getTextRange = useCallback(() => {
        const sel = document.getSelection();
        if (!sel || sel.rangeCount === 0 || !ref.current) {
            return undefined;
        }
        const range = sel.getRangeAt(0);
        const start = getTextNodeOffset(range.startContainer, range.startOffset);
        const end = getTextNodeOffset(range.endContainer, range.endOffset);
        return typeof start !== 'undefined' && typeof end !== 'undefined' ? [start, end] : undefined;
    }, [getTextNodeOffset]);
    const onContentChanged = useCallback(() => {
        if (!ref.current) {
            return;
        }
        // When someone pastes code for example from Monaco, it will paste HTML text,
        // so we need to replace divs, brs with newlines.
        // ref.current.innerHTML = ref.current.innerHTML.replace(/<br>/g, '\n')
        //   .replace(/<div>/g, '\n').replace(/<\/div>/g, '');
        let value = ref.current.textContent || '';
        if (ref.current.innerHTML.endsWith(codeForcedTerminator) && value.endsWith('\n')) {
            value = value.substr(0, value.length - 1);
        }
        if (!code) {
            setContent(value);
            setHtml(highlight(value, lang));
            // We now need to preserve the caret pos. Since code editors is always a text
            // and nesting might change, we just stick to text content length to find the spot
            const nextPos = getTextPosition();
            if (typeof nextPos !== 'undefined') {
                setNextCaretPos(nextPos);
            }
            else {
                if (value === '') {
                    setNextCaretPos(0);
                }
            }
        }
        onUpdate(value);
    }, [onUpdate, setContent, setHtml, highlight, getTextPosition, setNextCaretPos]);
    useEffect(() => {
        if (!code) {
            return;
        }
        setContent(code);
    }, [code]);
    useEffect(() => {
        if (!language) {
            return;
        }
        setLang(language);
    }, [language]);
    useEffect(() => {
        setHtml(highlight(content, lang));
    }, [content, lang]);
    useLayoutEffect(() => {
        if (typeof nextCaretPos === 'undefined' || disabled) {
            return;
        }
        const sel = document.getSelection();
        if (!sel || !ref.current) {
            return;
        }
        const range = document.createRange();
        const setStart = (el, pos) => {
            if (pos === 0) {
                range.setStart(el, 0);
                return;
            }
            let totalOffset = 0;
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < el.childNodes.length; i++) {
                const cn = el.childNodes[i];
                const cnl = (cn.textContent || '').length;
                if (totalOffset + cnl < pos) {
                    totalOffset += cnl;
                    continue;
                }
                if (cn.nodeType === Node.TEXT_NODE) {
                    range.setStart(cn, pos - totalOffset);
                }
                else {
                    // This might a text node inside the SPAN, but can also be a span with
                    // other spans inside, check first
                    if (cn.childNodes.length === 1) {
                        if (cn.firstChild.nodeType !== Node.TEXT_NODE) {
                            // Must be nested again, let's drill
                            setStart(cn.firstChild, pos - totalOffset);
                        }
                        else {
                            // This should be TEXT NODE inside the SPAN, not really safe...
                            range.setStart(cn.firstChild, pos - totalOffset);
                        }
                    }
                    else {
                        setStart(cn, pos - totalOffset);
                    }
                }
                break;
            }
        };
        setStart(ref.current, nextCaretPos);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        setNextCaretPos(undefined);
    });
    function onBeforeInput(event) {
        if (disabled) {
            return;
        }
        const e = event;
        const chars = e.data;
        if (!chars) {
            return;
        }
        if (chars === '\n' || chars === '\r\n') {
            event.preventDefault();
            const range = getTextRange();
            if (!range) {
                return;
            }
            const [start, end] = range;
            // TODO Expected behavior with enters is that you'd get the next line offset https://github.com/ProtoForce/protoforce-portal-webui/issues/30
            // matching the current line start offset
            const value = content.slice(0, start) + chars + content.slice(end);
            if (!code) {
                setContent(value);
                setHtml(highlight(value, lang));
                setNextCaretPos(start + chars.length);
            }
            onUpdate(value);
        }
    }
    function onKeyDown(event) {
        if (disabled) {
            return;
        }
        const intent = intentFromKeyboardEvent(event);
        switch (intent) {
            case InputIntent.MoveLeft:
            case InputIntent.MoveUp:
                if (isAtDOMStart(ref.current) && onPrevParagraph) {
                    if (onPrevParagraph()) {
                        event.preventDefault();
                        return;
                    }
                }
                break;
            case InputIntent.MoveRight:
            case InputIntent.MoveDown:
                if (onNextParagraph && isAtDOMEnd(ref.current, undefined)) {
                    if (onNextParagraph()) {
                        event.preventDefault();
                        return;
                    }
                }
                break;
        }
        if (event.keyCode === 9) {
            // TAB
            const range = getTextRange();
            if (!range) {
                return;
            }
            const [start, end] = range;
            event.preventDefault();
            if (start !== end) {
                // TODO We need to support range selection by doing new line shift with a tab https://github.com/ProtoForce/protoforce-portal-webui/issues/30
                // or multiple lines at the same time
                return;
            }
            const value = content.slice(0, start) + '\t' + content.slice(end);
            if (!code) {
                setContent(value);
                setHtml(highlight(value, lang));
                setNextCaretPos(start + '\t'.length);
            }
            onUpdate(value);
        }
    }
    function onPaste(event) {
        event.preventDefault();
        const range = getTextRange();
        if (!range) {
            return;
        }
        const [start, end] = range;
        const text = event.clipboardData.getData('text/plain');
        if (!text || text === '') {
            return;
        }
        const value = content.slice(0, start) + text + content.slice(end);
        if (!code) {
            setContent(value);
            setHtml(highlight(value, lang));
            setNextCaretPos(end + text.length);
        }
        onUpdate(value);
    }
    const onSetLang = useCallback((value) => {
        if (!language) {
            setLang(value);
            setHtml(highlight(content, value));
        }
        onUpdate(undefined, value);
    }, [setLang, setHtml, onUpdate, highlight]);
    const filterLang = useCallback((query, item, index, exactMatch) => {
        const name = CodeLanguageName[item] || item;
        if (!name) {
            return false;
        }
        if (exactMatch && name !== query) {
            return false;
        }
        if (name.toLowerCase().indexOf(query) >= 0) {
            return true;
        }
        return false;
    }, []);
    const onCopy = useCallback(() => {
        if (!navigator.clipboard) {
            return;
        }
        navigator.clipboard.writeText(content);
        if (onCopyCallback) {
            onCopyCallback(content);
        }
    }, [content, onCopyCallback]);
    const renderLang = useCallback((item, s) => {
        if (!s.modifiers.matchesPredicate) {
            return null;
        }
        const name = CodeLanguageName[item] || item;
        return (React.createElement(MenuItem, { style: { minWidth: 150 }, active: s.modifiers.active, disabled: s.modifiers.disabled, key: item, onClick: s.handleClick, text: name }));
    }, []);
    // TODO Fix lang select, it currently when opens is displayed in the wrong place https://github.com/ProtoForce/protoforce-portal-webui/issues/30
    return (React.createElement(Wrapper, null,
        React.createElement(Editable, { ref: ref, contentEditable: disabled ? undefined : true, spellCheck: false, autoCorrect: 'off', autoCapitalize: 'off', onKeyDown: onKeyDown, onPaste: onPaste, onBeforeInput: onBeforeInput, onInput: onContentChanged, dangerouslySetInnerHTML: { __html: html } }),
        React.createElement(EditableControls, null,
            React.createElement(LangSelect, { disabled: disabled || disabledLanguage, filterable: true, items: allCodeLanguages, itemRenderer: renderLang, itemPredicate: filterLang, onItemSelect: onSetLang, popoverProps: {
                    minimal: true
                } },
                React.createElement(Button, { disabled: disabled || disabledLanguage, minimal: true, text: React.createElement("span", { className: 'bp4-text-muted' }, CodeLanguageName[lang] || CodeLanguage[lang]), style: {
                        minWidth: 100,
                        height: 30
                    } })),
            React.createElement(Button, { disabled: content.length === 0, minimal: true, icon: 'clipboard', style: {
                    height: 30
                }, onClick: onCopy }))));
}
