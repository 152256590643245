import { CreateCategory } from '../../model/plugin';
import { newDelimBlock, DelimiterPluginID } from './models';
// New creators
function createSimple(id) {
    return newDelimBlock('simple', id);
}
function createDashed(id) {
    return newDelimBlock('dashed', id);
}
const newCreators = [
    {
        type: 'new',
        icon: 'minus',
        name: 'Flat Divider',
        desc: 'Line to separate sections',
        category: CreateCategory.Basic,
        ctor: (withID) => createSimple(withID)
    },
    {
        type: 'new',
        icon: 'more',
        name: 'Dashed Divider',
        desc: 'Line to separate sections',
        category: CreateCategory.Basic,
        ctor: (withID) => createDashed(withID)
    }
];
// From creators
function delimToSimple(withID, from) {
    return {
        id: withID,
        plugin: from.plugin,
        kind: 'simple'
    };
}
function delimToDashed(withID, from) {
    return {
        id: withID,
        plugin: from.plugin,
        kind: 'dashed'
    };
}
const fromCreators = [
    {
        type: 'from',
        icon: 'minus',
        name: 'Simple Divider',
        ctor: (withID, from) => delimToSimple(withID, from),
        check: (from) => {
            return from.plugin === DelimiterPluginID && from.kind !== 'simple';
        }
    },
    {
        type: 'from',
        icon: 'more',
        name: 'Dashed Divider',
        ctor: (withID, from) => delimToDashed(withID, from),
        check: (from) => {
            return from.plugin === DelimiterPluginID && from.kind !== 'dashed';
        }
    }
];
export const creators = [...newCreators, ...fromCreators];
