import styled from 'styled-components';
export const Wrapper = styled.div `
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  margin-bottom: 5px;
`;
export const FileContainer = styled.a `
  display: flex;
  justify-content: space-between;
  padding: 10px 5px 10px 5px;
  mouse: pointer;
  &:hover {
    background-color: rgba(123, 168, 206, 0.2);
  }

  :link {
    color: inherit;
  }

  :visited {
    color: inherit;
  }

  :hover {
    color: inherit;
  }

  :active {
    color: inherit;
  }
`;
export const FileName = styled.span `
  text-decoration: ellipsis;
`;
export const FileSize = styled.span `
  font-size: 80%;
  color: #cccccc;
`;
