import styled from 'styled-components';
export const PluginHolder = styled.div `
  display: flex;
  min-height: 300px;
  padding-top: 20px;
  display: ${props => (props.visible ? 'block' : 'none')};
`;
export const Dummy = styled.div `
  min-height: 64px;
  height: 100%;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-width: 2px;
  border-color: #7ba8ce;
  color: #7ba8ce;
  border-radius: 5px;
  cursor: ${props => (props.interactive ? 'pointer' : undefined)};
`;
