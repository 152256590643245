import { Alignment } from '../../model';
export const alignmentMenu = {
    type: 'group',
    data: {
        icon: 'align-left',
        name: 'Alignment'
    },
    items: [
        {
            type: 'command',
            data: {
                icon: 'align-left',
                name: 'Left'
            },
            func: (context, block) => {
                block.alignment = Alignment.Left;
                context.actions.update(block);
            }
        },
        {
            type: 'command',
            data: {
                icon: 'align-center',
                name: 'Center'
            },
            func: (context, block) => {
                block.alignment = Alignment.Center;
                context.actions.update(block);
            }
        },
        {
            type: 'command',
            data: {
                icon: 'align-right',
                name: 'Right'
            },
            func: (context, block) => {
                block.alignment = Alignment.Right;
                context.actions.update(block);
            }
        }
    ]
};
