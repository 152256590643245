import { CreateCategory } from '../../model/plugin';
import { CalloutPluginID, CalloutIntent } from './models';
import { newTextBlock } from '../text';
// New creators
function createCallout(id, header, content) {
    return {
        id,
        plugin: CalloutPluginID,
        intent: CalloutIntent.Default,
        header,
        content
    };
}
function createCalloutContainer(id) {
    const header = newTextBlock();
    const content = newTextBlock();
    return [createCallout(id, header.id, content.id), header, content];
}
const newCreators = [
    {
        type: 'new',
        icon: 'info-sign',
        name: 'Callout',
        desc: 'Callout block with a header and content',
        category: CreateCategory.Containers,
        ctor: (withID) => createCalloutContainer(withID)
    }
];
export const creators = [...newCreators];
