import Prism from 'prismjs';
// Has to have this order
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-arduino';
// --
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-basic';
import 'prismjs/components/prism-clojure';
import 'prismjs/components/prism-coffeescript';
import 'prismjs/components/prism-csharp';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-dart';
import 'prismjs/components/prism-docker';
import 'prismjs/components/prism-elixir';
import 'prismjs/components/prism-elm';
import 'prismjs/components/prism-erlang';
import 'prismjs/components/prism-flow';
import 'prismjs/components/prism-fortran';
import 'prismjs/components/prism-fsharp';
import 'prismjs/components/prism-gherkin';
import 'prismjs/components/prism-glsl';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-graphql';
import 'prismjs/components/prism-groovy';
import 'prismjs/components/prism-haskell';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-kotlin';
import 'prismjs/components/prism-latex';
import 'prismjs/components/prism-less';
import 'prismjs/components/prism-lisp';
import 'prismjs/components/prism-livescript';
import 'prismjs/components/prism-lua';
import 'prismjs/components/prism-makefile';
import 'prismjs/components/prism-markdown';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-matlab';
import 'prismjs/components/prism-nix';
import 'prismjs/components/prism-objectivec';
import 'prismjs/components/prism-pascal';
import 'prismjs/components/prism-perl';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-php';
import 'prismjs/components/prism-powershell';
import 'prismjs/components/prism-prolog';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-protobuf';
import 'prismjs/components/prism-r';
import 'prismjs/components/prism-ruby';
import 'prismjs/components/prism-rust';
import 'prismjs/components/prism-sass';
import 'prismjs/components/prism-scala';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-sql';
import 'prismjs/components/prism-swift';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-vbnet';
import 'prismjs/components/prism-visual-basic';
import 'prismjs/components/prism-wasm';
import 'prismjs/components/prism-yaml';
// TODO Add line numbers https://github.com/ProtoForce/protoforce-portal-webui/issues/29
// import 'prismjs/plugins/line-numbers/prism-line-numbers';
// TODO Full language definition should go here... this is copied from scala https://github.com/ProtoForce/protoforce-portal-webui/issues/29
// tslint:disable
export const prismProtoforce = Prism.languages.extend('java', {
    keyword: /<-|=>|\b(?:package|domain|import|include|id|enum|adt|const|data|service|mixin|interface|def|alias|template|type|foreign|as|cloned|with)\b/,
    builtin: /\b(?:bit|boolean|bool|string|str|i08|byte|int8|bigint|big|i16|short|int16|i32|int|int32|i64|long|int64|u08|ubyte|uint8|u16|ushort|uint16|u32|uint32|uint|u64|ulong|uint64|f32|flt|float|f64|dbl|double|uid|uuid|blob|blb|bytes|tsl|datetimel|tso|datetimeo|tsu|datetimeu|time|date|error|err|any|lst|list|set|opt|option|map|dict|either|alt)\b/,
    number: /\b0x[\da-f]*\.?[\da-f]+|(?:\b\d+\.?\d*|\B\.\d+)(?:e\d+)?[dfl]?/i,
    symbol: /'[^\d\s\\]\w*/
});
export const prismHocon = Prism.languages.extend('json', {
    keyword: /<-|=>|\b(?:include|url|file|classpath)\b/,
    builtin: /\b(?:true|false|null|on|off|yes|no|day|hour|minute|millisecond|microsecond|nanosecond|second|d|h|ns|ms|us|s)[s]?\b/,
    operator: /=/
});
Prism.languages.webmanifest = Prism.languages.json;
// tslint:enable
import './prism.scss';
