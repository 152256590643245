import styled from 'styled-components';
// const fadein = keyframes`
// 	from {
// 		opacity: 0;
// 	}
// 	to {
// 		opacity: 1;
// 	}
// `;
export const OuterWrapper = styled.div `
  margin: 0px;
  padding: 10px 70px 10px 70px;
  width: 100%;
  max-width: 100%;
`;
// animation: ${fadein} 230ms ease-in;
export const BottomArea = styled.div `
  height: 300px;
  display: flex;
  background-color: transparent;
`;
// rgba(255, 0, 0, 0.1);
