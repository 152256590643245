import { sheetPlugin, textPlugin, createTextPlugin, codePlugin, delimiterPlugin, embedPlugin, calloutPlugin, createImagePlugin, imagePlugin, filePlugin, previewBreakPlugin, createGoogleMapsPlugin, createAudioPlugin, audioPlugin, videoPlugin, listPlugin, createVideoPlugin, createFilePlugin, createCodePlugin } from '../plugins';
// TODO Build this options list using generic types + types from actual plugins https://github.com/ProtoForce/protoforce-portal-webui/issues/19
export function createEditorOptions(plugins, debug, readonly, observe) {
    const list = [
        sheetPlugin,
        plugins.text ? createTextPlugin(plugins.text) : textPlugin
    ];
    if (plugins.code) {
        list.push(typeof plugins.code === 'boolean' ? codePlugin : createCodePlugin(plugins.code));
    }
    if (plugins.delimiter) {
        list.push(delimiterPlugin);
    }
    if (plugins.embed) {
        list.push(embedPlugin);
    }
    if (plugins.callout) {
        list.push(calloutPlugin);
    }
    if (plugins.list) {
        list.push(listPlugin);
    }
    if (plugins.image) {
        list.push(typeof plugins.image === 'boolean' ? imagePlugin : createImagePlugin(plugins.image));
    }
    if (plugins.video) {
        list.push(typeof plugins.video === 'boolean' ? videoPlugin : createVideoPlugin(plugins.video));
    }
    if (plugins.audio) {
        list.push(typeof plugins.audio === 'boolean' ? audioPlugin : createAudioPlugin(plugins.audio));
    }
    if (plugins.file) {
        list.push(typeof plugins.file === 'boolean' ? filePlugin : createFilePlugin(plugins.file));
    }
    if (plugins.previewBreak) {
        list.push(previewBreakPlugin);
    }
    if (plugins.googleMaps) {
        list.push(createGoogleMapsPlugin(plugins.googleMaps));
    }
    if (plugins.other) {
        list.push(...plugins.other);
    }
    return { plugins: list, debug, readonly, observe };
}
export const defaultEditorOptions = createEditorOptions({
    code: true,
    delimiter: true,
    embed: true,
    previewBreak: true,
    callout: true,
    image: true,
    list: true,
    audio: true,
    video: true,
    file: true
});
