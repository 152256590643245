import { URL } from './URL';
export const urlPicker = {
    name: 'URL',
    icon: 'link',
    component: URL
};
const YOUTUBE_WATCH = 'https://www.youtube.com/watch';
const YOUTUBE_EMBED = 'https://www.youtube.com/embed';
const VIMEO_WATCH = 'https://vimeo.com/';
const VIMEO_EMBED = 'https://player.vimeo.com/video/';
export const VIDEO_DIRECT_FILE = RegExp('^https?:\/\/(.*).(mp4|mov)$');
const allowedRegexp = [
    RegExp('^' + YOUTUBE_WATCH),
    RegExp('^' + YOUTUBE_EMBED),
    RegExp('^' + VIMEO_WATCH),
    RegExp('^' + VIMEO_EMBED),
    VIDEO_DIRECT_FILE
];
export function videoURLCheck(value) {
    const vl = value.toLowerCase();
    for (const a of allowedRegexp) {
        if (a.test(vl)) {
            return undefined;
        }
    }
    return 'Must start with https://www.youtube.com, https://vimeo.com/, or be a direct link to a video file.';
}
export function videoURLProcessor(value) {
    const vl = value.toLowerCase();
    if (vl.startsWith(YOUTUBE_WATCH)) {
        // YouTube won't allow an iframe like this, we need to convert to an embed
        // link:
        // https://www.youtube.com/watch?v=VvAWxPNsNEk&list=RD_0mlPCr_s7U&index=13
        // to:
        // https://www.youtube.com/embed/tgbNymZ7vqY
        const query = value.split('?');
        if (query.length === 2) {
            const vq = query[1].split('&').find(qp => qp.startsWith('v='));
            if (vq) {
                const videoID = vq.split('=')[1];
                const embedVideo = `${YOUTUBE_EMBED}/${videoID}`;
                return embedVideo;
            }
        }
    }
    if (vl.startsWith(VIMEO_WATCH)) {
        // Vimeo links have to be converted from
        // https://vimeo.com/259411563#t=1s
        // to
        // https://player.vimeo.com/video/259411563#t=1s"
        const embedVideo = value.replace(VIMEO_WATCH, VIMEO_EMBED);
        return embedVideo;
    }
    return value;
}
