import styled from 'styled-components';
export const Wrapper = styled.div ``;
export const PreviewImage = styled.img `
  display: ${props => (props.ready ? 'block' : 'none')};
  max-width: 100%;
  max-height: 300px;
  object-fit: scale-down;
`;
export const PreviewVideo = styled.iframe `
  width: 100%;
  height: 100%;
  border-width: 0;
`;
export const PreviewVideoDirect = styled.video `
  max-width: 100%;
  border-width: 0;
`;
