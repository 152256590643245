import { FocusPosition, findChildBlockByID } from '../../model';
export function focus(block, pos) {
    const root = document.getRootNode();
    const el = findChildBlockByID(root, typeof block === 'string' ? block : block.id);
    if (!el || el.nodeType !== Node.ELEMENT_NODE) {
        return;
    }
    const docSelection = document.getSelection();
    if (!docSelection) {
        return;
    }
    const e = el.querySelector('[contenteditable="true"]');
    if (!e) {
        return;
    }
    const sel = document.getSelection();
    if (!sel) {
        return;
    }
    const range = document.createRange();
    range.selectNodeContents(e);
    range.collapse(pos === FocusPosition.Start);
    sel.removeAllRanges();
    sel.addRange(range);
    const bounding = e.getBoundingClientRect();
    const inViewport = bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight);
    if (!inViewport) {
        e.scrollIntoView();
    }
}
