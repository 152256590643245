export function scrollIntoView(ref, index) {
    if (!ref) {
        return false;
    }
    if (index >= ref.children.length) {
        return false;
    }
    const childElement = ref.children.item(index);
    if (!childElement) {
        return false;
    }
    function pixelsToNumber(value) {
        return value == null ? 0 : parseInt(value.slice(0, -2), 10);
    }
    function getPadding(el) {
        const { paddingTop: pt, paddingBottom: pb } = getComputedStyle(el);
        return {
            paddingBottom: pixelsToNumber(pb),
            paddingTop: pixelsToNumber(pt)
        };
    }
    const { offsetTop: activeTop, offsetHeight: activeHeight } = childElement;
    const { offsetTop: parentOffsetTop, scrollTop: parentScrollTop, clientHeight: parentHeight } = ref;
    const { paddingTop, paddingBottom } = getPadding(ref);
    const bottomEdge = activeTop + activeHeight + paddingBottom - parentOffsetTop;
    const activeTopEdge = activeTop - paddingTop - parentOffsetTop;
    if (bottomEdge >= parentScrollTop + parentHeight) {
        // align item bottom to the ref bottom
        ref.scrollTop = bottomEdge + activeHeight - parentHeight;
    }
    else if (activeTopEdge <= parentScrollTop) {
        // align item top to the ref top
        ref.scrollTop = activeTopEdge - activeHeight;
    }
    return true;
}
