import React, { useState, useCallback } from 'react';
import { Dialog, Classes, Button, ButtonGroup, Callout } from '@blueprintjs/core';
import { PluginHolder } from './styles';
export function MediaPicker(props) {
    const { open, icon, title, plugins, blockID, value, onSave, onCancel, onError } = props;
    const [activePlugin, setActivePlugin] = useState(plugins.length > 0 ? plugins[0].name : '');
    const [plugin, setPlugin] = useState();
    const [media, setMedia] = useState();
    const [caption, setCaption] = useState();
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState();
    // TODO Make those two below using static functions, because right now they always https://github.com/ProtoForce/protoforce-portal-webui/issues/22
    // generate a new function which then re-render all other plugins.
    const onPluginChange = useCallback((pluginID) => {
        return () => {
            setActivePlugin(pluginID);
        };
    }, [setActivePlugin]);
    const onChanged = useCallback((pid) => {
        return (v, cap) => {
            setPlugin(pid);
            setMedia({ mf: v });
            setCaption(cap);
            setError(undefined);
        };
    }, [setPlugin, setMedia, setCaption, setError]);
    if (plugins.length === 0) {
        return React.createElement("span", null, "Media picker has no plugins provided.");
    }
    function onSelect() {
        if (!media || !plugin) {
            return;
        }
        const handleError = (err) => {
            console.error(err);
            setError(err);
            if (onError) {
                onError('' + err);
            }
        };
        if (typeof media.mf === 'function') {
            setProcessing(true);
            media.mf()
                .then((mf) => {
                onSave(mf, plugin, caption);
            }, handleError)
                .catch(handleError)
                .finally(() => {
                setProcessing(false);
            });
        }
        else {
            onSave(media.mf, plugin, caption);
        }
    }
    const canSelect = media && (typeof media.mf === 'function' || media.mf.kind !== 'none');
    const tabs = plugins.map(p => {
        return (React.createElement(Button, { key: p.name, icon: p.icon, minimal: true, active: activePlugin === p.name, disabled: processing, onClick: onPluginChange(p.name) }, p.name));
    });
    const content = plugins.map(p => {
        const Component = p.component;
        return (React.createElement(PluginHolder, { visible: p.name === activePlugin, key: p.name },
            React.createElement(Component, { blockID: blockID, value: value, config: p.config, onChanged: onChanged(p.name), disabled: processing })));
    });
    return (React.createElement(Dialog, { autoFocus: true, canEscapeKeyClose: !processing, canOutsideClickClose: !processing, icon: icon, isOpen: open, className: 'bp4-dark', title: title, onClose: onCancel },
        React.createElement("div", { className: Classes.DIALOG_BODY, style: { display: 'flex', marginBottom: 0, flexDirection: 'column' } },
            React.createElement(ButtonGroup, null, tabs),
            content),
        React.createElement("div", { style: { marginTop: 20 }, className: Classes.DIALOG_FOOTER },
            React.createElement("div", { className: Classes.DIALOG_FOOTER_ACTIONS },
                React.createElement(Button, { disabled: processing, onClick: onCancel, minimal: true }, "Cancel"),
                React.createElement(Button, { disabled: processing || !canSelect, intent: 'primary', type: 'submit', onClick: onSelect, loading: processing }, "Select"),
                error && (React.createElement(Callout, { icon: 'blank', intent: 'danger' }, error))))));
}
