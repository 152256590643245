import React, { useState, useEffect, useCallback } from 'react';
import { FormGroup, InputGroup, Spinner, Icon } from '@blueprintjs/core';
import { Dummy } from '../../styles';
import { Wrapper, ImageGrid, ImageContainer, ImagePreview } from './styles';
function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}
export function Unsplash(props) {
    const { disabled, config, onChanged } = props;
    const [filter, setFilter] = useState('');
    const [searching, setSearching] = useState(false);
    const [selected, setSelected] = useState('');
    const [results, setResults] = useState([]);
    const debouncedValue = useDebounce(filter, 500);
    const onUnsplahSelect = useCallback((r) => {
        return () => {
            setSelected(r.id);
            const mf = {
                kind: 'url',
                url: r.downloadURL
            };
            onChanged(mf, `© Photo by ${r.author}`);
        };
    }, [setSelected, onChanged]);
    const onValueChange = useCallback((event) => {
        setFilter(event.currentTarget.value);
    }, [setFilter]);
    useEffect(() => {
        if (!debouncedValue || debouncedValue === '' || !config) {
            setSearching(false);
            setResults([]);
            return;
        }
        config
            .search(debouncedValue, undefined)
            .then((unsplash) => {
            setSearching(false);
            setResults(unsplash.content);
        }, (err) => {
            setSearching(false);
            console.error(`Error: ${JSON.stringify(err)}`, err);
        })
            .catch((err) => {
            setSearching(false);
            console.error(`Error: ${JSON.stringify(err)}`, err);
        });
    }, [debouncedValue]);
    useEffect(() => {
        setSearching(filter && filter !== '' ? true : false);
    }, [filter]);
    if (!config) {
        return React.createElement("span", null, "Unsplash plugin requires a search and random functions to be provided in the config.");
    }
    const content = results.length === 0 ?
        (React.createElement(Dummy, null,
            React.createElement(Icon, { icon: 'search' }),
            "\u00A0",
            searching ? (React.createElement("span", null,
                "Searching... \u00A0",
                React.createElement(Spinner, null))) : filter ? ('Nothing found, try something different :(') : ('Images will show up here, just start typing.'))) : (React.createElement(ImageGrid, null, results.map((r) => (React.createElement(ImageContainer, { key: r.id, selected: selected === r.id },
        React.createElement(ImagePreview, { src: r.thumbURL, onClick: onUnsplahSelect(r) }),
        React.createElement("small", { className: 'bp4-text-muted' },
            "by",
            ' ',
            React.createElement("a", { href: r.unsplashURL, target: '_blank' }, r.author)))))));
    return (React.createElement(Wrapper, null,
        React.createElement(FormGroup, { labelFor: 'search-input', label: 'Search for images' },
            React.createElement(InputGroup, { id: 'search-input', placeholder: 'New York, Zebra, Car', value: filter, onChange: onValueChange, autoFocus: true, disabled: disabled, rightElement: searching ? React.createElement(Spinner, { size: 12 }) : undefined })),
        content));
}
