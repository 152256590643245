import { newBlockID as newInlineBlockID, findParentBlockID as findParentInlineBlockID, findChildBlockByID as findChildInlineBlockByID } from '../../RIDInlineEditor';
import { TextPluginID, SheetPluginID } from '../plugins';
import { Alignment } from './alignment';
export const DATA_BLOCK_ATTR = 'data-block';
export function newBlockID() {
    return newInlineBlockID();
}
export function findParentBlockID(el) {
    return findParentInlineBlockID(el, DATA_BLOCK_ATTR);
}
export function findChildBlockByID(el, id) {
    return findChildInlineBlockByID(el, id, DATA_BLOCK_ATTR);
}
export function findParentOfBlockID(id) {
    const child = findChildBlockByID(document.getRootNode(), id);
    if (!child) {
        return undefined;
    }
    return findParentInlineBlockID(child, DATA_BLOCK_ATTR);
}
export function createBlankDoc() {
    const text = {
        id: newBlockID(),
        plugin: TextPluginID,
        kind: 'paragraph',
        alignment: Alignment.Left,
        inlines: []
    };
    const sheet = {
        id: newBlockID(),
        plugin: SheetPluginID,
        kind: 'vertical',
        blocks: [text.id]
    };
    return {
        root: sheet.id,
        blocks: {
            [sheet.id]: sheet,
            [text.id]: text
        }
    };
}
export function createBlankDocWithHeader() {
    const text = {
        id: newBlockID(),
        plugin: TextPluginID,
        kind: 'header',
        size: 1,
        locked: true,
        alignment: Alignment.Left,
        inlines: []
    };
    const sheet = {
        id: newBlockID(),
        plugin: SheetPluginID,
        kind: 'vertical',
        blocks: [text.id]
    };
    return {
        root: sheet.id,
        blocks: {
            [sheet.id]: sheet,
            [text.id]: text
        }
    };
}
