import { CalloutPluginID, CalloutIntent } from './models';
import { CalloutRenderer } from './CalloutRenderer';
import { creators } from './creators';
function insert(container, block, after) {
    return false;
}
function prev(container, id) {
    switch (id) {
        case container.id:
            return undefined;
        case container.header:
            return container.id;
        case container.content:
            return container.header;
        default:
            return undefined;
    }
}
function next(container, id) {
    switch (id) {
        case container.id:
            return container.header;
        case container.header:
            return container.content;
        case container.content:
            return undefined;
        default:
            return undefined;
    }
}
function children(container) {
    return [container.header, container.content];
}
function replace(container, id, withID) {
    if (container.header === id) {
        container.header = withID;
    }
    else if (container.content === id) {
        container.content = withID;
    }
}
function remove(container, id) {
    return false;
}
function updateIntent(intent) {
    return (context, block) => {
        const { actions } = context;
        const cloned = actions.clone(block).cloned;
        cloned.intent = intent;
        actions.update(cloned);
    };
}
function intentMenuItem(name, icon, intent) {
    return {
        type: 'command',
        data: {
            name,
            icon
        },
        func: updateIntent(intent)
    };
}
export const calloutPlugin = {
    id: CalloutPluginID,
    renderer: CalloutRenderer,
    container: {
        insert,
        prev,
        next,
        children,
        replace,
        remove
    },
    creators,
    menu: true,
    menuItems: [
        {
            type: 'group',
            data: {
                icon: 'info-sign',
                name: 'Intent'
            },
            items: [
                intentMenuItem('Default', 'blank', CalloutIntent.Default),
                intentMenuItem('Primary', 'info-sign', CalloutIntent.Primary),
                intentMenuItem('Success', 'tick-circle', CalloutIntent.Success),
                intentMenuItem('Warning', 'warning-sign', CalloutIntent.Warning),
                intentMenuItem('Danger', 'delete', CalloutIntent.Danger)
            ]
        }
    ]
};
