import React, { useMemo, useContext } from 'react';
import { MenuItem } from '@blueprintjs/core';
import { ToolbarMenuItem } from './ToolbarMenuItem';
import { PluginRefContext } from '../BlockRenderer';
import { useModelState } from '../context';
export function ToolbarMenuGroup(props) {
    const { block, group } = props;
    const { data, items } = group;
    const state = useModelState();
    const pluginRef = useContext(PluginRefContext);
    const groupData = useMemo(() => {
        return typeof data === 'function' ? data(state, block, pluginRef) : data;
    }, [state, block, data]);
    const groupItems = useMemo(() => {
        return typeof items === 'function' ? items(state, block, pluginRef) : items;
    }, [state, block, items]);
    if (groupItems.length === 0) {
        return null;
    }
    return (React.createElement(MenuItem, { icon: groupData.icon, text: groupData.name }, groupItems.map((gi, gii) => (React.createElement(ToolbarMenuItem, { key: gii, item: gi, block: block })))));
}
export default ToolbarMenuGroup;
