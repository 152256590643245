import React, { useEffect, useContext, useRef } from 'react';
import { keyboardEventToHotKey, hotKeyMatch } from '~/core/hooks/useHotKey';
import { ModelContext, ActionsContext, EditorContext, OptionsContext } from '../../Editor';
import { formatCommands } from './utils';
const commands = Object.values(formatCommands);
export const Format = React.memo((props) => {
    const options = useContext(OptionsContext);
    const editor = useContext(EditorContext);
    const model = useContext(ModelContext);
    const actions = useContext(ActionsContext);
    const contexts = useRef({
        model,
        actions
    });
    contexts.current = {
        model,
        actions
    };
    useEffect(() => {
        if (options.readonly) {
            return;
        }
        function onKeyDown(event) {
            const hotKey = keyboardEventToHotKey(event);
            // TODO Optimize this lookup, we can improve it by using KEY + MODs combination in a map
            const command = commands.find(cmd => hotKeyMatch(cmd.hotkey, hotKey));
            if (!command) {
                return;
            }
            event.preventDefault();
            command.func(contexts.current, event);
        }
        editor.addEventListener('onKeyDown', onKeyDown);
        return () => {
            editor.removeEventListener('onKeyDown', onKeyDown);
        };
    }, [options.readonly, editor.addEventListener, editor.removeEventListener]);
    return null;
});
