import { blockToJSON } from './utils';
export function buildHistoryRecord(plugins, blocks, selection) {
    const rec = {
        blocks: JSON.stringify(blocks.map(b => {
            const json = blockToJSON(plugins, b);
            return Object.assign(Object.assign({}, json), { id: b.id });
        })),
        selection: selection ? JSON.stringify(selection) : undefined
    };
    return rec;
}
export function pushHistory(plugins, history, historyLength, blocks, selection) {
    const rec = buildHistoryRecord(plugins, blocks, selection);
    if (history.changes.length > 0) {
        history.changes = history.changes.slice(0, history.location + 1);
    }
    history.changes.push(rec);
    history.location++;
    if (history.changes.length > historyLength) {
        history.changes.splice(0, 1);
        history.location--;
    }
}
