import React from 'react';
import { useTextStyles } from '../../hooks';
import { StyledText } from '../text';
export const LinkRenderer = React.memo((props) => {
    const { block } = props;
    const url = block.data;
    const styles = useTextStyles(block.styles);
    return (React.createElement("a", { href: url, "data-inline-block": block.id },
        React.createElement(StyledText, Object.assign({ tag: styles.code ? 'code' : 'span', text: block.text, colors: block.colors }, styles))));
});
