// import { guid } from '~/core/utils';
import { guid } from '../../../../core/utils';
export function newBlockID() {
    return guid();
}
export function blocksToText(blocks) {
    let res = '';
    for (const b of blocks) {
        if (b.type !== 'text') {
            continue;
        }
        res += b.text;
    }
    return res;
}
export function jsonBlocksToText(blocks) {
    let res = '';
    for (const b of blocks) {
        if (b.type !== 'text') {
            continue;
        }
        res += b.text;
    }
    return res;
}
export function blockToJSON(plugins, b) {
    const plugin = plugins[b.plugin];
    return (plugin.codec ? plugin.codec.toJSON(b) : b);
}
export function blockFromJSON(plugins, data, id) {
    const plugin = plugins[data.plugin];
    const blockID = id || newBlockID();
    const res = plugin.codec
        ? plugin.codec.fromJSON(data, blockID)
        : Object.assign(Object.assign({}, data), { id: blockID });
    return res;
}
export function cloneBlock(plugins, b, resetID) {
    const blockID = resetID ? newBlockID() : b.id;
    return blockFromJSON(plugins, JSON.parse(JSON.stringify(blockToJSON(plugins, b))), blockID);
}
