import { ImageRenderer } from './ImageRenderer';
import { ImagePluginID } from './models';
import { creators } from './creators';
import { alignmentMenu } from '../common';
export const imagePlugin = {
    id: ImagePluginID,
    renderer: ImageRenderer,
    forwardRef: true,
    creators,
    menu: true,
    menuItems: [
        {
            type: 'command',
            data: {
                icon: 'media',
                name: 'Change image...'
            },
            func: (context, block, pluginRef) => {
                if (!pluginRef) {
                    return;
                }
                pluginRef.openPicker();
            }
        },
        alignmentMenu
    ]
};
export function createImagePlugin(config) {
    return Object.assign(Object.assign({}, imagePlugin), { config });
}
