import React, { useMemo, useCallback } from 'react';
import { Select } from '@blueprintjs/select';
import { Button, MenuItem, MenuDivider } from '@blueprintjs/core';
import { newBlockID, CreateCategory } from '../../model';
import { useModelState } from '../context';
const BlockSelect = Select.ofType();
const MENU_WIDTH = 250;
function escapeRegExpChars(text) {
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}
function highlightText(text, query) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join('|'), 'gi');
    const tokens = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(React.createElement("strong", { key: lastIndex }, match[0]));
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}
export function BlockCreateMenu(props) {
    const { block, isOpen, onOpen, onClose, onClosed } = props;
    const state = useModelState();
    const { actions, options } = state;
    const { plugins } = options;
    const items = useMemo(() => {
        const cats = {};
        // Go over content creators
        Object.values(plugins).forEach(c => {
            if (!c.creators) {
                return;
            }
            c.creators.forEach(cr => {
                if (cr.type !== 'new') {
                    return;
                }
                if (!(cr.category in cats)) {
                    cats[cr.category] = [];
                }
                cats[cr.category].push(cr);
            });
        });
        const keys = Object.keys(cats);
        keys.sort((a, b) => a.localeCompare(b));
        let res = [];
        keys.forEach(k => {
            const list = cats[k];
            list.sort((a, b) => a.name.localeCompare(b.name));
            res.push(CreateCategory[k]);
            res = res.concat(list);
        });
        return res;
    }, [plugins]);
    const onCreate = useCallback((d) => {
        if (typeof d !== 'object') {
            return;
        }
        const id = newBlockID();
        const newBlock = d.ctor(id);
        const main = Array.isArray(newBlock) ? newBlock[0] : newBlock;
        const add = Array.isArray(newBlock) ? newBlock.slice(1) : undefined;
        actions.insert(main, block.id, true, add);
    }, [actions]);
    const renderItem = useCallback((item, s) => {
        if (!s.modifiers.matchesPredicate) {
            return null;
        }
        if (typeof item !== 'object') {
            return React.createElement(MenuDivider, { key: item, title: item });
        }
        return (React.createElement(MenuItem, { style: { minWidth: MENU_WIDTH }, active: s.modifiers.active, disabled: s.modifiers.disabled, 
            // TODO Check that we don't fail on this unique key https://github.com/ProtoForce/protoforce-portal-webui/issues/25
            key: item.name, icon: item.icon, onClick: () => onCreate(item), text: React.createElement("div", null,
                React.createElement("span", null, highlightText(item.name, s.query)),
                item.desc && React.createElement("div", { className: 'bp4-text-muted bp4-text-small' }, highlightText(item.desc, s.query))) }));
    }, [onCreate, highlightText]);
    const filterItem = useCallback((query, item, index, exactMatch) => {
        if (typeof item !== 'object') {
            return query === '' ? true : false;
        }
        const normalizedText = (item.name + ' ' + (item.desc || '') + item.category).toLowerCase();
        const normalizedQuery = query.toLowerCase();
        if (exactMatch) {
            return normalizedText === normalizedQuery;
        }
        else {
            return normalizedText.indexOf(normalizedQuery) >= 0;
        }
    }, []);
    return (React.createElement(BlockSelect, { filterable: true, items: items, itemPredicate: filterItem, itemRenderer: renderItem, popoverProps: {
            isOpen,
            onClose,
            onClosed
        }, noResults: React.createElement(MenuItem, { disabled: true, text: `Nothing found out of ${items.length} items.`, style: { minWidth: MENU_WIDTH } }), onItemSelect: onCreate },
        React.createElement(Button, { icon: 'plus', minimal: true, onClick: onOpen })));
}
export default BlockCreateMenu;
