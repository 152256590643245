import { textPlugin, linkPlugin, formatPlugin, clipboardPlugin, timestampPlugin, toolbarPlugin, colorPlugin, emojiPlugin } from './plugins';
export const defaultOptions = {
    history: 250,
    // debug: true,
    plugins: [
        textPlugin,
        linkPlugin,
        formatPlugin,
        clipboardPlugin,
        timestampPlugin,
        toolbarPlugin,
        colorPlugin,
        emojiPlugin
    ]
};
