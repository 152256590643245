import React, { useContext, useMemo } from 'react';
import { OptionsContext } from './context';
export const Plugins = (props) => {
    const { editorRef } = props;
    const options = useContext(OptionsContext);
    const plugins = useMemo(() => Object.values(options.plugins).filter(p => p.component), [options.plugins]);
    return (React.createElement(React.Fragment, null, plugins.map(p => {
        const Plugin = p.component;
        return (React.createElement(Plugin, { key: p.id, editorRef: editorRef, config: p.config }));
    })));
};
