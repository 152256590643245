import React, { useEffect, useState, useMemo } from 'react';
import { RescalerWrapper, Lane, Indicator } from './styles';
import { Alignment } from '../../model';
export var RescalerMode;
(function (RescalerMode) {
    RescalerMode[RescalerMode["Width"] = 0] = "Width";
    RescalerMode[RescalerMode["Height"] = 1] = "Height";
    RescalerMode[RescalerMode["WidthHeight"] = 2] = "WidthHeight";
})(RescalerMode || (RescalerMode = {}));
export function Rescaler(props) {
    const { readonly, target, mode, onRescaleStart, onRescaleEnd, onRescale } = props;
    const [resizingWidth, setResizingWidth] = useState(false);
    const [resizingHeight, setResizingHeight] = useState(false);
    // const [over, setOver] = useState(false);
    const [state, setState] = useState();
    const [update, setUpdate] = useState();
    function buildRescaleEnd(width, height) {
        if (!target.element.current || !target.container.current || !update || (!width && !height)) {
            return undefined;
        }
        const res = {};
        if (typeof width !== 'undefined') {
            res.width = {};
            res.width.value =
                target.container.current.clientWidth === target.element.current.clientWidth
                    ? undefined
                    : target.element.current.clientWidth;
        }
        if (typeof height !== 'undefined') {
            res.height = {};
            res.height.value = target.element.current.clientHeight;
        }
        setUpdate(undefined);
        return res;
    }
    function onMouseUp(event) {
        if (readonly || !state || (!resizingWidth && !resizingHeight)) {
            return;
        }
        onRescaleEnd(buildRescaleEnd(resizingWidth ? state.diffX : undefined, resizingHeight ? state.diffY : undefined));
        setResizingWidth(false);
        setResizingHeight(false);
        setState(undefined);
    }
    function buildRescaleMove(dx, dy) {
        if (!target.container.current || !update) {
            return undefined;
        }
        const { startWidth, startHeight } = update;
        const u = {};
        if (typeof dx !== 'undefined') {
            // Since we scale from both sides due to alignment, let's multiply by 2
            let ddx = target.alignment === Alignment.Center ? dx * 2 : dx;
            if (startWidth + ddx < 64) {
                ddx = 64 - startWidth;
            }
            if (startWidth + ddx > target.container.current.clientWidth) {
                ddx = target.container.current.clientWidth - startWidth;
            }
            u.diffX = ddx;
        }
        if (typeof dy !== 'undefined') {
            let ddy = dy;
            if (startHeight + ddy < 64) {
                ddy = 64 - startHeight;
            }
            u.diffY = ddy;
        }
        if (Object.keys(u).length > 0) {
            const res = Object.assign(Object.assign({}, update), u);
            setUpdate(res);
            return res;
        }
        return undefined;
    }
    function onMouseMove(event) {
        if (!state || (!resizingWidth && !resizingHeight)) {
            return;
        }
        const { pagePosX, pagePosY } = state;
        const dx = event.pageX - pagePosX;
        const dy = event.pageY - pagePosY;
        setState(Object.assign(Object.assign({}, state), { diffX: dx, diffY: dy }));
        onRescale(buildRescaleMove(resizingWidth ? dx : undefined, resizingHeight ? dy : undefined));
    }
    function buildRescaleStart() {
        if (!target.element.current) {
            return;
        }
        const res = {
            diffX: 0,
            diffY: 0,
            startWidth: target.element.current.clientWidth,
            startHeight: target.element.current.clientHeight
        };
        setUpdate(res);
        return res;
    }
    function onWidthMouseDown(event) {
        if (readonly) {
            return;
        }
        setResizingWidth(true);
        setState({
            pagePosX: event.pageX,
            pagePosY: event.pageY,
            diffX: 0,
            diffY: 0
        });
        onRescaleStart(buildRescaleStart());
    }
    function onHeightMouseDown(event) {
        if (readonly) {
            return;
        }
        setResizingHeight(true);
        setState({
            pagePosX: event.pageX,
            pagePosY: event.pageY,
            diffX: 0,
            diffY: 0
        });
        onRescaleStart(buildRescaleStart());
    }
    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
    });
    if (readonly) {
        return null;
    }
    return (React.createElement(RescalerWrapper, null,
        (mode === RescalerMode.Width || mode === RescalerMode.WidthHeight) && (React.createElement(Lane, { vertical: true },
            React.createElement(Indicator, { vertical: true, onMouseDown: onWidthMouseDown }))),
        (mode === RescalerMode.Height || mode === RescalerMode.WidthHeight) && (React.createElement(Lane, { vertical: false },
            React.createElement(Indicator, { vertical: false, onMouseDown: onHeightMouseDown })))));
}
export function buildRescalerStyle(block, state) {
    const containerStyle = {
        position: 'relative',
        maxWidth: state ? '100%' : block.width ? `${block.width}px` : undefined,
        width: state ? `${state.startWidth + state.diffX}px` : '100%',
        height: state ? `${state.startHeight + state.diffY}px` : block.height ? `${block.height}px` : '100%'
    };
    return containerStyle;
}
export function useRescalerStyle(block, state) {
    const style = useMemo(() => {
        return buildRescalerStyle(block, state);
    }, [block, state]);
    return style;
}
