import React, { useEffect, useContext, useRef } from 'react';
import { blockToJSON, blockFromJSON, buildSelection } from '../../model';
import { newTextBlock } from '../text';
import { OptionsContext, ModelContext, ActionsContext, EditorContext } from '../../Editor';
import { LinkPluginID, newLinkBlock } from '../link';
const ClipboardEntryID = 'rid-inline';
function copy(contexts, event) {
    const { options, model, actions } = contexts;
    if (!model.selection || !event.clipboardData) {
        return false;
    }
    const blocks = actions.copy(model.selection);
    const entity = {
        type: ClipboardEntryID,
        data: blocks.map(b => blockToJSON(options.plugins, b))
    };
    event.clipboardData.setData('text/plain', blocks.map(b => (b.type === 'text' ? b.text : '')).join(''));
    event.clipboardData.setData('application/json', JSON.stringify(entity));
    return true;
}
function paste(contexts, event) {
    const { model, options, actions } = contexts;
    if (!event.clipboardData) {
        return false;
    }
    let blocks;
    const json = event.clipboardData.getData('application/json');
    if (json && json !== '') {
        try {
            const parsed = JSON.parse(json);
            if (parsed && parsed.type === ClipboardEntryID && Array.isArray(parsed.data)) {
                blocks = parsed.data.map(b => blockFromJSON(options.plugins, b));
                if (blocks.length === 0) {
                    blocks = undefined;
                }
            }
        }
        catch (_a) {
            // Well, it failed, let's try just text later
        }
    }
    const html = event.clipboardData.getData('text/html');
    if (html && html !== '') {
        try {
            const htmlDoc = new DOMParser().parseFromString(html, 'text/html');
            // TODO Add support for bold html text
            const bs = [];
            const processNode = (el) => {
                if (!el) {
                    return;
                }
                switch (el.nodeType) {
                    case Node.DOCUMENT_NODE:
                        el.childNodes.forEach(processNode);
                        break;
                    case Node.TEXT_NODE:
                        if (el.textContent) {
                            bs.push({
                                text: el.textContent
                            });
                        }
                        break;
                    case Node.ELEMENT_NODE: {
                        const element = el;
                        if (element.tagName === 'A') {
                            const link = element.getAttribute('href');
                            const text = element.textContent;
                            if (link && text) {
                                bs.push({
                                    text,
                                    link
                                });
                            }
                            break;
                        }
                        else {
                            element.childNodes.forEach(processNode);
                        }
                    }
                }
            };
            processNode(htmlDoc);
            // Pack now the primitive blocks, so that text sticks together
            if (bs.length > 0) {
                const optimized = bs.reduce((prev, curr) => {
                    const prevElement = prev.length ? prev[prev.length - 1] : undefined;
                    if (!prevElement) {
                        prev.push(curr);
                        return prev;
                    }
                    if (!prevElement.link && !curr.link && prevElement.text === curr.text) {
                        prevElement.text = prevElement.text + curr.text;
                    }
                    else {
                        prev.push(curr);
                    }
                    return prev;
                }, []);
                blocks = optimized.map(b => b.link ? newLinkBlock(b.link, b.text) : newTextBlock(b.text));
            }
        }
        catch (_b) {
            // Must not be an HTML
        }
    }
    // Try text now
    if (!blocks) {
        const text = event.clipboardData.getData('text/plain');
        if (text && text !== '') {
            const newBlock = (LinkPluginID in options.plugins) && (text.startsWith('http://') || text.startsWith('https://')) ?
                newLinkBlock : newTextBlock;
            blocks = [newBlock(text)];
        }
        else {
            // Nothing to paste.
            return false;
        }
    }
    // TODO Add support for text/html with Links and styling support? https://github.com/ProtoForce/protoforce-portal-webui/issues/36
    const sel = model.selection || buildSelection(0, 0);
    actions.replace(blocks, sel, 'back');
    return true;
}
export const Clipboard = React.memo((props) => {
    const { config } = props;
    const editor = useContext(EditorContext);
    const options = useContext(OptionsContext);
    const model = useContext(ModelContext);
    const actions = useContext(ActionsContext);
    const contexts = useRef({
        options,
        model,
        actions
    });
    contexts.current = {
        options,
        model,
        actions
    };
    useEffect(() => {
        const readonly = options.readonly;
        function onCopy(event) {
            if (!readonly) {
                event.preventDefault();
                copy(contexts.current, event);
            }
            else {
                if (config && config.onCopy) {
                    config.onCopy(event);
                }
            }
        }
        function onCut(event) {
            const ctx = contexts.current;
            event.preventDefault();
            if (!copy(ctx, event)) {
                return;
            }
            const sel = ctx.model.selection;
            if (!sel) {
                return;
            }
            ctx.actions.replace([], sel, 'front');
        }
        function onPaste(event) {
            event.preventDefault();
            paste(contexts.current, event);
        }
        editor.addEventListener('onCopy', onCopy);
        if (!readonly) {
            editor.addEventListener('onCut', onCut);
            editor.addEventListener('onPaste', onPaste);
        }
        return () => {
            editor.removeEventListener('onCopy', onCopy);
            if (!readonly) {
                editor.removeEventListener('onCut', onCut);
                editor.removeEventListener('onPaste', onPaste);
            }
        };
    }, [options.readonly, editor.addEventListener, editor.removeEventListener, config]);
    return null;
});
