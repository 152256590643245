import styled, { css } from 'styled-components';
export const RescalerWrapper = styled.div `
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
`;
export const Lane = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transition: opacity 200ms ease-out 0s;
  opacity: 0;
  pointer-events: all;
  flex-direction: column;
  ${props => props.vertical
    ? css `
          width: 25px;
        `
    : css `
          height: 25px;
        `}
  ${props => props.vertical
    ? css `
          top: 0;
        `
    : css `
          left: 0;
        `}
    right: 0;
  bottom: 0;
  &:hover {
    opacity: 1;
  }
`;
export const Indicator = styled.div `
  background: #ffffff;
  border-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  ${props => props.vertical
    ? css `
          width: 5px;
          height: 30px;
          cursor: col-resize;
        `
    : css `
          width: 30px;
          height: 5px;
          cursor: row-resize;
        `}
`;
