import styled from 'styled-components';
export const Wrapper = styled.div `
  width: 100%;
  max-width: 100%;
  padding: 16px;
  margin-bottom: 15px;
  position: relative;
  overflow-x: auto;

  text-transform: none;
  font-family: monospace;
  border-radius: 3px;
  font-size: smaller;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  background: rgba(16, 22, 26, 0.3);
  color: #a7b6c2;
`;
export const Editable = styled.pre `
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  tab-size: 2;
  min-height: 1em;
  line-height: 1.5;
  white-space: pre;
  margin: 0px;
`;
export const EditableControls = styled.div `
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
`;
