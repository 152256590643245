// import { emojiIndex } from 'emoji-mart';
let emojiModule;
let emojiModuleLoading;
// import data from 'emoji-mart/data/all.json'
export function preloadEmojis() {
    if (emojiModule || emojiModuleLoading) {
        return;
    }
    emojiModuleLoading = true;
    import(/* webpackPrefetch: true, webpackChunkName: "emojies" */ 'emoji-mart').then(module => {
        emojiModule = module;
        emojiModuleLoading = false;
    }).catch(err => {
        console.error(err);
        emojiModuleLoading = false;
    });
}
export const defaultEmojis = [
    {
        name: 'Avocado',
        native: '🥑',
        skin: 1,
        text: ':avocado:'
    },
    {
        name: 'Heavy Black Heart',
        native: '❤️',
        skin: 1,
        text: ':heart:'
    },
    {
        name: 'Thumbs Up Sign',
        native: '👍',
        skin: 1,
        text: ':+1:'
    },
    {
        name: 'Smiling Face with Open Mouth and Smiling Eyes',
        native: '😄',
        skin: 1,
        text: ':smile:'
    },
    {
        name: 'Smiling Face with Open Mouth and Cold Sweat',
        native: '😅',
        skin: 1,
        text: ':sweat_smile:'
    },
    {
        name: 'Rolling on the Floor Laughing',
        native: '🤣',
        skin: 1,
        text: ':rolling_on_the_floor_laughing:'
    },
    {
        name: 'Smiling Face with Sunglasses',
        native: '😎',
        skin: 1,
        text: ':sunglasses:'
    },
    {
        name: 'Disappointed Face',
        native: '😞',
        skin: 1,
        text: ':disappointed:'
    },
    {
        name: 'Fire',
        native: '🔥',
        skin: 1,
        text: ':fire:'
    },
    {
        name: 'Rocket',
        native: '🚀',
        skin: 1,
        text: ':rocket:'
    }
];
export function searchEmoji(text, limit) {
    const emojiIndex = emojiModule === null || emojiModule === void 0 ? void 0 : emojiModule.emojiIndex;
    const data = emojiIndex && emojiIndex.search(text);
    if (!data) {
        return [];
    }
    const res = data.map((e) => ({
        name: e.name,
        text: e.colons,
        skin: e.skin || 1,
        native: e.native
    }));
    return typeof limit === 'number' ? res.slice(0, limit) : res;
}
