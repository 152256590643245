import { parseHotKey } from '~/core/hooks/useHotKey';
import { TextStyle, newBlockID } from '../../model';
export function allHaveStyle(blocks, style) {
    if (blocks.length === 0) {
        return false;
    }
    for (const b of blocks) {
        if (b.type !== 'text') {
            continue;
        }
        if (!b.styles || b.styles.indexOf(style) < 0) {
            return false;
        }
    }
    return true;
}
export function enableStyle(blocks, style, enable) {
    for (const b of blocks) {
        if (b.type !== 'text') {
            continue;
        }
        if (enable) {
            if (!b.styles) {
                b.styles = [style];
            }
            else {
                if (b.styles.indexOf(style) < 0) {
                    b.styles.push(style);
                }
            }
        }
        else {
            if (b.styles) {
                const index = b.styles.indexOf(style);
                if (index >= 0) {
                    b.styles.splice(index, 1);
                }
            }
        }
    }
}
export function toggleStyle(model, actions, style) {
    const { selection } = model;
    if (!selection) {
        return;
    }
    const copied = actions.copy(selection);
    if (copied.length === 0) {
        return;
    }
    const enable = !allHaveStyle(copied, style);
    enableStyle(copied, style, enable);
    // Reset ID for the first and last ones, as they will be new blocks most likely
    copied[0].id = newBlockID();
    copied[copied.length - 1].id = newBlockID();
    actions.replace(copied, selection);
}
function styleFunc(style) {
    return (contexts, event) => {
        toggleStyle(contexts.model, contexts.actions, style);
        if (event) {
            event.preventDefault();
        }
    };
}
export const formatCommands = {
    bold: {
        hotkey: parseHotKey('cmd + b'),
        style: TextStyle.Bold,
        func: styleFunc(TextStyle.Bold)
    },
    italic: {
        hotkey: parseHotKey('cmd + i'),
        style: TextStyle.Italic,
        func: styleFunc(TextStyle.Italic)
    },
    underline: {
        hotkey: parseHotKey('cmd + u'),
        style: TextStyle.Underline,
        func: styleFunc(TextStyle.Underline)
    },
    strike: {
        hotkey: parseHotKey('cmd + shift + s'),
        style: TextStyle.Strike,
        func: styleFunc(TextStyle.Strike)
    },
    code: {
        hotkey: parseHotKey('cmd + shift + c'),
        style: TextStyle.Code,
        func: styleFunc(TextStyle.Code)
    },
    sub: {
        hotkey: parseHotKey('cmd + shift + b'),
        style: TextStyle.Sub,
        func: styleFunc(TextStyle.Sub)
    },
    sup: {
        hotkey: parseHotKey('cmd + shift + p'),
        style: TextStyle.Sup,
        func: styleFunc(TextStyle.Sup)
    }
};
