import React, { useState, useRef, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useLayoutEffect } from '~/core/hooks';
import { defaultOptions } from '../options';
import { OuterWrapper, Editable } from './styles';
import { getDOMSelection, setDOMSelection } from '../dom';
import { intentFromKeyboardEvent, InputIntent, CONTROL_OR_EDIT_KEYS } from './intent';
import { newTextBlock, TextPluginID } from '../plugins';
import { isSelectionCollapsed, compareSelection, buildOptionsState, buildModelState, buildActionsState } from '../model';
import { ModelContext, ActionsContext, OptionsContext, EditorContext } from './context';
import { Blocks } from './Blocks';
import { Debug } from './Debug';
import { Plugins } from './Plugins';
const EditorImpl = (props, fref) => {
    const { placeholder, permanentPlaceholder, readonly } = props;
    const [, setRedraw] = useState({});
    const redraw = useCallback(() => setRedraw({}), []);
    const ref = useRef(null);
    // @ts-ignore
    const eventListeners = useRef({});
    const latestProps = useRef(props);
    latestProps.current = props;
    const options = useMemo(() => buildOptionsState(Object.assign(Object.assign(Object.assign({}, defaultOptions), (props.options || {})), (props.readonly ? { readonly: true } : {}))), [props.options, props.readonly]);
    const [model, setModel] = useState(() => buildModelState(options, props.blocks));
    const [focused, setFocused] = useState(false);
    const [over, setOver] = useState(false);
    const onUpdated = useCallback((blocks) => {
        if (!latestProps.current.onUpdated) {
            return;
        }
        latestProps.current.onUpdated(blocks);
    }, []);
    const actions = useMemo(() => buildActionsState(options, model, onUpdated, redraw), [options, model, onUpdated, redraw]);
    const reconcileSelection = useCallback(() => {
        if (model.blocks.length === 0) {
            return;
        }
        const selection = getDOMSelection(model.blocks);
        if (!selection) {
            return;
        }
        actions.updateSelection(selection);
    }, [actions, model]);
    const clearSelection = useCallback(() => {
        const sel = model.selection;
        if (!sel) {
            return;
        }
        if (isSelectionCollapsed(sel)) {
            return;
        }
        actions.replace([], sel, 'front');
    }, [model, actions]);
    // const hotkeys = useHotkeys(context.plugins);
    // const { addListener, removeListener, dispatchEvent } = useEditorEvents();
    // const [focused, setFocused] = useState(false);
    // const pluginComponents = useMemo(() => {
    //   return Object.values(context.plugins).filter(p => (p.component ? true : false));
    // }, [context.plugins]);
    const notifyEventListeners = useCallback((type, event) => {
        const propsListener = latestProps.current[type];
        if (propsListener) {
            propsListener(event);
        }
        const listeners = eventListeners.current[type];
        if (!listeners || listeners.length === 0) {
            return;
        }
        for (const l of listeners) {
            try {
                l(event);
            }
            catch (err) {
                console.error(`Error while notifying a listener for ${type}`, err);
            }
        }
    }, []);
    const onBlur = useCallback((event) => {
        setFocused(false);
        notifyEventListeners('onBlur', event);
        actions.updateSelection(undefined);
    }, [setFocused, actions, notifyEventListeners]);
    const onFocus = useCallback((event) => {
        setFocused(true);
        reconcileSelection();
        notifyEventListeners('onFocus', event);
    }, [setFocused, reconcileSelection, notifyEventListeners]);
    const onMouseEnter = useCallback(() => {
        setOver(true);
    }, [setOver]);
    const onMouseLeave = useCallback(() => {
        setOver(false);
    }, [setOver]);
    const preventDefault = useCallback((e) => {
        e.preventDefault();
    }, []);
    const onInput = useCallback(() => {
        reconcileSelection();
    }, [reconcileSelection]);
    const onSelect = useCallback(() => {
        reconcileSelection();
    }, [reconcileSelection]);
    const onClearAndType = useCallback((event) => {
        // If something is selected, we have out of sync structure, so we need
        // top clear that and re-sync the DOM/Model.
        const key = event.key;
        if (key) {
            if (CONTROL_OR_EDIT_KEYS.indexOf(key) >= 0) {
                return;
            }
        }
        event.preventDefault();
        const sel = model.selection;
        if (!sel || isSelectionCollapsed(sel)) {
            return;
        }
        const piece = newTextBlock('');
        switch (key) {
            case 'Enter':
                clearSelection();
                return;
            case 'Tab':
                piece.text = '\t';
                break;
            case ' ':
                piece.text = ' ';
                break;
            default:
                piece.text = key;
        }
        actions.replace([piece], sel, 'back');
    }, [model, actions]);
    const onDelete = useCallback((next, event) => {
        event.preventDefault();
        const sel = model.selection;
        if (!sel) {
            return;
        }
        // We have something selected while deleting - just replace it
        // with nothing
        if (!isSelectionCollapsed(sel)) {
            clearSelection();
            return;
        }
        const loc = sel.end;
        const curBlock = model.blocks[loc.block];
        if (next) {
            const isAtEnd = loc.offset === 'end' ||
                (curBlock.type === 'text' && curBlock.text.length === loc.offset) ||
                (curBlock.type === 'object' && loc.offset > 0);
            const deleteFrom = isAtEnd ? model.blocks[loc.block + 1] : curBlock;
            if (!deleteFrom) {
                return;
            }
            const offset = isAtEnd ? 0 : loc.offset;
            switch (deleteFrom.type) {
                case 'object':
                    actions.remove(deleteFrom.id);
                    // context.updateCaret({
                    //     block: isAtEnd ? loc.block + 1 : loc.block,
                    //     offset,
                    // });
                    break;
                case 'text':
                    if (deleteFrom.text.length < offset) {
                        return;
                    }
                    const symbols = Array.from(deleteFrom.text);
                    symbols.splice(offset, 1);
                    actions.updateText(deleteFrom, symbols.join(''), true, {
                        block: isAtEnd ? loc.block + 1 : loc.block,
                        offset,
                    });
                    // TODO: Ref DELETEBUG This updateCaret must be disabled, as cursor will naturally move for it as you delete
                    // a piece.
                    break;
            }
        }
        else {
            const isAtStart = loc.offset === 0;
            const deleteFrom = isAtStart ? model.blocks[loc.block - 1] : curBlock;
            if (!deleteFrom) {
                return;
            }
            switch (deleteFrom.type) {
                case 'object':
                    actions.remove(deleteFrom.id, {
                        block: isAtStart ? loc.block - 2 : loc.block,
                        offset: 'end'
                    });
                    break;
                case 'text':
                    const offset = isAtStart || loc.offset === 'end' ? deleteFrom.text.length : loc.offset;
                    if (deleteFrom.text.length < offset) {
                        return;
                    }
                    const symbols = Array.from(deleteFrom.text);
                    let symbolIndex = 0;
                    let offsetSymbol = 0;
                    for (const s of symbols) {
                        offsetSymbol += s.length;
                        symbolIndex++;
                        if (offsetSymbol >= offset) {
                            break;
                        }
                    }
                    const symbolLength = symbols[symbolIndex - 1].length;
                    symbols.splice(symbolIndex - 1, 1);
                    actions.updateText(deleteFrom, symbols.join(''), true, {
                        block: isAtStart ? loc.block - 1 : loc.block,
                        offset: offset - symbolLength
                    });
                    break;
            }
        }
    }, [actions, model]);
    const onKeyDown = useCallback((event) => {
        notifyEventListeners('onKeyDown', event);
        if (event.isDefaultPrevented()) {
            return;
        }
        // const combo = comboFromKeyboardEvent(event);
        // for (const hk of hotkeys) {
        //   if (!compareCombo(combo, hk.combo)) {
        //     continue;
        //   }
        //   hk.cmd(context, event);
        //   if (event.defaultPrevented) {
        //     return;
        //   }
        // }
        const intent = intentFromKeyboardEvent(event);
        switch (intent) {
            // These will be intercepted at onCopy, onCut, onPaste
            case InputIntent.Copy:
            case InputIntent.Cut:
            case InputIntent.Paste:
                return;
            case InputIntent.SelectAll:
                return;
            case InputIntent.Clear:
                event.preventDefault();
                clearSelection();
                return;
            case InputIntent.DeleteNext:
                return onDelete(true, event);
            case InputIntent.DeletePrevious:
                return onDelete(false, event);
            case InputIntent.FormatBold:
            case InputIntent.FormatItalic:
            case InputIntent.FormatSubscript:
            case InputIntent.FormatSuperscript:
            case InputIntent.FormatUnderline:
            case InputIntent.FormatUppercase:
            case InputIntent.FormatLowercase:
            case InputIntent.FormatClear:
            case InputIntent.Escape:
                event.preventDefault();
                return;
            case InputIntent.Undo:
                event.preventDefault();
                actions.undo();
                return;
            case InputIntent.Redo:
                event.preventDefault();
                actions.redo();
                return;
        }
        if (model.selection && !isSelectionCollapsed(model.selection)) {
            // We have something selected and a symbol was pressed.
            onClearAndType(event);
        }
    }, [actions, model, onDelete, clearSelection, notifyEventListeners]);
    const onKeyUp = useCallback((event) => {
        notifyEventListeners('onKeyUp', event);
        if (event.isDefaultPrevented()) {
            return;
        }
    }, [notifyEventListeners]);
    const onMouseDown = useCallback((event) => {
        notifyEventListeners('onMouseDown', event);
        if (event.isDefaultPrevented()) {
            return;
        }
    }, [notifyEventListeners]);
    const onMouseMove = useCallback((event) => {
        reconcileSelection();
        notifyEventListeners('onMouseMove', event);
        if (event.isDefaultPrevented()) {
            return;
        }
    }, [reconcileSelection, notifyEventListeners]);
    const onMouseUp = useCallback((event) => {
        notifyEventListeners('onMouseUp', event);
        if (event.isDefaultPrevented()) {
            return;
        }
    }, [notifyEventListeners]);
    const onCopy = useCallback((event) => {
        notifyEventListeners('onCopy', event);
        if (event.isDefaultPrevented()) {
            return;
        }
    }, [notifyEventListeners]);
    const onCut = useCallback((event) => {
        notifyEventListeners('onCut', event);
        if (event.isDefaultPrevented()) {
            return;
        }
    }, [notifyEventListeners]);
    const onPaste = useCallback((event) => {
        notifyEventListeners('onPaste', event);
        if (event.isDefaultPrevented()) {
            return;
        }
    }, [notifyEventListeners]);
    const onBeforeInput = useCallback((event) => {
        // Space is reported as keypress, so we don't limit it here and use chars later
        // if (event.type !== 'textInput') {
        //   return;
        // }
        const e = event;
        const chars = e.data;
        if (!chars) {
            return;
        }
        if (model.blocks.length === 0) {
            event.preventDefault();
            const textBlock = newTextBlock(chars);
            actions.insert(textBlock, undefined, {
                block: 0,
                offset: chars.length
            });
            // We also need to remove all child nodes, that were not created by our components
            const htmlDiv = ref.current;
            while (htmlDiv.firstChild) {
                // try {
                htmlDiv.removeChild(htmlDiv.firstChild);
                // } catch (err) {
                //   console.error(err);
                // }
            }
            return;
        }
        if (!model.selection || !isSelectionCollapsed(model.selection)) {
            return;
        }
        // If we are at the end of an Object block, we need to be able to start typing text after that
        const start = model.selection.start;
        const curBlock = model.blocks[start.block];
        if (curBlock.type === 'text') {
            // We need to only modify text if we are in a text block, or we
            // are in a text like block middle. Not the end or front
            const isAtStart = start.offset === 0;
            const isAtEnd = start.offset === 'end' || start.offset === curBlock.text.length;
            const isInside = !isAtStart && !isAtEnd;
            if (curBlock.plugin === TextPluginID || isInside) {
                let loc;
                let needRedraw = curBlock.plugin !== TextPluginID;
                let replaceText = '';
                if (start.offset === 'end' || start.offset === curBlock.text.length) {
                    replaceText = curBlock.text + chars;
                    loc = {
                        block: start.block,
                        offset: replaceText.length
                    };
                    needRedraw = true;
                }
                else {
                    replaceText = curBlock.text.substr(0, start.offset) + chars + curBlock.text.substr(start.offset);
                    // TODO: Ref DELETEBUG (Look up for another place with this ref) Uncomment this one
                    // selection = buildSelection(start.block, start.offset + chars.length - 1);
                    // and comment out this two ones:
                    loc = {
                        block: start.block,
                        offset: start.offset + chars.length
                    };
                    needRedraw = true;
                    // There is a bug, where deleting a character typing in a new one then deleting it again and
                    // then typing again, then deleting, and so on, somehow does not re-render during the delete,
                    // as a result visual part is out of sync with the model part.
                }
                // If we are at the end or beginning, we need to re-render, or it
                // might not retain styles, etc.
                actions.updateText(curBlock, replaceText, needRedraw, loc);
                if (needRedraw) {
                    event.preventDefault();
                }
                return;
            }
        }
        const curBlockIndex = start.block;
        if (start.offset === 0) {
            // We are at the beginning of the object block or non-text one, check if we have a text block
            // prior to it so we can insert a text there, or if not - then insert a new text
            // block between.
            const prevBlock = model.blocks[curBlockIndex - 1];
            if (prevBlock && prevBlock.type === 'text') {
                // We don't re-render here, just add the text and move on.
                // Selection will be reconciled on its own in onInput
                actions.updateText(prevBlock, prevBlock.text + chars, false, {
                    block: curBlockIndex - 1,
                    offset: 'end'
                });
                event.preventDefault();
                return;
            }
            const textBlock = newTextBlock(chars);
            actions.insert(textBlock, {
                block: curBlockIndex,
                offset: 0
            }, {
                block: curBlockIndex,
                offset: chars.length
            });
            event.preventDefault();
            return;
        }
        const nextBlock = model.blocks[curBlockIndex + 1];
        if (!nextBlock || nextBlock.type === 'object') {
            // We need to insert a new text block now
            event.preventDefault();
            const textBlock = newTextBlock(chars);
            actions.insert(textBlock, {
                block: curBlockIndex,
                offset: 'end'
            }, {
                block: curBlockIndex + 1,
                offset: chars.length
            });
            event.preventDefault();
            return;
        }
        // Next block is text after the object, so we should prepend the text now
        // We don't re-render here, just add the text and move on.
        // Selection will be reconciled on its own in onInput
        actions.updateText(nextBlock, chars + nextBlock.text, false);
    }, [model, actions]);
    const focus = useCallback(() => {
        if (!ref.current) {
            return;
        }
        ref.current.focus();
    }, []);
    const addEventListener = useCallback((type, listener) => {
        const listeners = (eventListeners.current[type] || []);
        if (listeners.indexOf(listener) >= 0) {
            console.warn(`Listener is already subscribed to: ${type}`);
            return;
        }
        listeners.push(listener);
        eventListeners.current[type] = listeners;
    }, []);
    const removeEventListener = useCallback((type, listener) => {
        const listeners = (eventListeners.current[type] || []);
        const index = listeners.indexOf(listener);
        if (index < 0) {
            console.warn(`Listener is not subscribed to: ${type}`);
            return;
        }
        listeners.splice(index, 1);
        if (listeners.length === 0) {
            delete eventListeners.current[type];
        }
    }, []);
    const editor = useMemo(() => ({
        focus,
        focused,
        over,
        addEventListener,
        removeEventListener
    }), [focus, focused, over, addEventListener, removeEventListener]);
    const load = useCallback((blocks) => {
        setModel(buildModelState(options, blocks));
    }, [options]);
    useImperativeHandle(fref, () => {
        return {
            model,
            actions,
            editor,
            ref,
            load
        };
    });
    useLayoutEffect(() => {
        if (!ref.current || !model.selection) {
            return;
        }
        // See if we need to re-select the DOM range
        const selection = getDOMSelection(model.blocks);
        if (!compareSelection(selection, model.selection)) {
            if (model.blocks.length === 0) {
                if (model.selection) {
                    ref.current.focus();
                }
            }
            else {
                setDOMSelection(model.blocks, model.selection);
                // This is an escape hatch in case DOM selection calculation
                // gets different due to outside changes, such as various plugins
                // that can modify the content. We do here this in a mutable way,
                // could be improved.
                const updatedSelection = getDOMSelection(model.blocks);
                if (!compareSelection(updatedSelection, model.selection)) {
                    model.selection = updatedSelection;
                    // console.trace('Reconciled DOM selection.');
                }
            }
        }
    }, [model.selection]);
    const ro = readonly || options.readonly;
    const showPlaceholder = ((over || focused) && !ro) || permanentPlaceholder;
    return (React.createElement(EditorContext.Provider, { value: editor },
        React.createElement(OptionsContext.Provider, { value: options },
            React.createElement(ModelContext.Provider, { value: model },
                React.createElement(ActionsContext.Provider, { value: actions },
                    React.createElement(OuterWrapper, { onFocus: onFocus, onBlur: onBlur, focused: focused, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
                        React.createElement(Editable, { ref: ref, placeholder: showPlaceholder ? placeholder : undefined, contentEditable: !ro, onBeforeInput: onBeforeInput, onInput: onInput, onKeyDown: onKeyDown, onKeyUp: onKeyUp, onMouseDown: onMouseDown, onMouseMove: onMouseMove, onMouseUp: onMouseUp, onSelect: onSelect, onCopy: onCopy, onCut: onCut, onPaste: onPaste, suppressContentEditableWarning: true, onDragEnter: preventDefault, onDragLeave: preventDefault, onDragOver: preventDefault, onDrop: preventDefault, autoCorrect: 'off', autoCapitalize: 'off', spellCheck: false },
                            React.createElement(Blocks, { blocks: model.blocks })),
                        options.debug && React.createElement(Debug, null)),
                    React.createElement(Plugins, { editorRef: ref }))))));
};
export const Editor = forwardRef(EditorImpl);
