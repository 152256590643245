import { CodeRenderer } from './CodeRenderer';
import { focus } from '../common';
import { creators } from './creators';
import { CodeLanguage } from './lang';
export const CodePluginID = 'code';
export const codePlugin = {
    id: CodePluginID,
    renderer: CodeRenderer,
    focus,
    creators: creators,
    menu: true,
    menuOffset: {
        y: 2
    },
    codec: {
        toJSON: (block) => {
            return Object.assign(Object.assign({}, block), { lang: CodeLanguage[block.lang] });
        },
        fromJSON: (block) => {
            return Object.assign(Object.assign({}, block), { lang: CodeLanguage[block.lang] });
        }
    }
};
export function createCodePlugin(config) {
    return Object.assign(Object.assign({}, codePlugin), { config });
}
