import styled, { css } from 'styled-components';
const wrapperStyle = css `
  width: 100%;
  max-width: 100%;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-block-start: 0;
  margin-block-end: 1em;
`;
export const WrapperUL = styled.ul `
  ${wrapperStyle}
  list-style-type: disc;
`;
export const WrapperOL = styled.ol `
  ${wrapperStyle}
  list-style-type: decimal;
`;
export const blockStyle = {
    flex: '1 1 0px',
    minWidth: 1,
    display: 'flex',
    flexDirection: 'column'
};
