import React, { useContext, useMemo, useState, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';
import { Wrapper, EmbedIFrame, EmbedResizableFrame } from './styles';
import { MediaPicker, urlPicker, Dummy, Rescaler, RescalerMode, useRescalerStyle, ActionsContext, OptionsContext } from '../../Editor';
import { MediaCaption } from '../common';
import { useResizableBlockFlexAlignment } from '../../model';
import { newTextBlock } from '../../../RIDInlineEditor';
const EmbedRendererImpl = (props, forRef) => {
    const { block } = props;
    const actions = useContext(ActionsContext);
    const { readonly } = useContext(OptionsContext);
    const ref = useRef(null);
    const blockRef = useRef(null);
    const [rescaleState, setRescaleState] = useState();
    const [pickerOpen, setPickerOpen] = useState(false);
    const url = useMemo(() => {
        return block.media.kind === 'none' ? undefined : block.media.url;
    }, [block.media]);
    const pickerPlugins = useMemo(() => {
        return [urlPicker];
    }, []);
    const onCaptionUpdated = useCallback((caption) => {
        const cloned = actions.clone(block).cloned;
        cloned.caption = caption;
        actions.update(cloned);
    }, [actions, block]);
    const onOpenMedia = useCallback(() => {
        if (readonly) {
            return;
        }
        setPickerOpen(true);
    }, [readonly, setPickerOpen]);
    const onSelectMedia = useCallback((value, plugin, caption) => {
        const cloned = actions.clone(block).cloned;
        cloned.media = value;
        if (caption) {
            cloned.caption = [newTextBlock(caption)];
        }
        actions.update(cloned);
        setPickerOpen(false);
    }, [block, actions, setPickerOpen]);
    const onCancelMedia = useCallback(() => {
        setPickerOpen(false);
    }, [setPickerOpen]);
    const onRescaleStart = useCallback((update) => {
        if (!update) {
            return;
        }
        setRescaleState(update);
    }, [setRescaleState]);
    const onRescale = useCallback((update) => {
        if (!update) {
            return;
        }
        setRescaleState(update);
    }, [setRescaleState]);
    const onRescaleEnd = useCallback((update) => {
        if (!update || (!update.width && !update.height)) {
            return;
        }
        const cloned = actions.clone(block).cloned;
        if (update.width) {
            cloned.width = update.width.value;
        }
        if (update.height) {
            cloned.height = update.height.value;
        }
        actions.update(cloned);
        setRescaleState(undefined);
    }, [actions, block, setRescaleState]);
    useImperativeHandle(forRef, () => {
        return {
            openPicker: onOpenMedia
        };
    });
    const containerStyle = useRescalerStyle(block, rescaleState);
    const alignmentStyle = useResizableBlockFlexAlignment(block);
    return (React.createElement(Wrapper, { ref: blockRef, style: alignmentStyle },
        React.createElement(EmbedResizableFrame, { ref: ref, style: containerStyle },
            url ? (React.createElement(EmbedIFrame, { src: url, frameBorder: 0, allowFullScreen: true })) : (React.createElement(Dummy, { interactive: !readonly, onClick: onOpenMedia },
                React.createElement(Icon, { icon: 'link' }),
                "\u00A0 No URL provided. Click here to set one.")),
            React.createElement(Rescaler, { readonly: readonly, mode: RescalerMode.WidthHeight, target: {
                    alignment: block.alignment,
                    container: blockRef,
                    element: ref
                }, onRescaleStart: onRescaleStart, onRescale: onRescale, onRescaleEnd: onRescaleEnd })),
        React.createElement(MediaPicker, { blockID: block.id, open: pickerOpen, value: block.media, plugins: pickerPlugins, onSave: onSelectMedia, onCancel: onCancelMedia }),
        React.createElement(MediaCaption, { readonly: readonly, caption: block.caption, onUpdated: onCaptionUpdated })));
};
export const EmbedRenderer = React.memo(forwardRef(EmbedRendererImpl));
