import styled, { css } from 'styled-components';
// TODO Do we need to investigate pointer-events: none ? https://github.com/ProtoForce/protoforce-portal-webui/issues/21
// pointer-events: ${props => props.over ? 'none' : undefined};
// https://medium.com/@reiberdatschi/common-pitfalls-with-html5-drag-n-drop-api-9f011a09ee6c
const dropStyle = css `
  border-color: rgb(45, 170, 219);
  border-style: solid;
  border-width: 0px;
  position: absolute;
  z-index: 1;
`;
export const DropLeft = styled.div `
    left: 0;
    width: 50%;
    top: 0;
    bottom: 0;
    ${dropStyle}
    border-left-width: ${props => (props.over ? '5px' : '0px')};
`;
export const DropRight = styled.div `
    right: 0;
    width: 50%;
    top: 0;
    bottom: 0;
    ${dropStyle}
    border-right-width: ${props => (props.over ? '5px' : '0px')};
`;
export const DropTop = styled.div `
    left: 0;
    right: 0;
    top: 0;
    height: 50%;
    ${dropStyle}
    border-top-width: ${props => (props.over ? '5px' : '0px')};
`;
export const DropBottom = styled.div `
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    ${dropStyle}
    border-bottom-width: ${props => (props.over ? '5px' : '0px')};
`;
