import React from 'react';
import { useDrop } from 'react-dnd';
import { useModelState } from '../context';
import { DragBlockType } from '../dnd';
import { DropLeft, DropRight, DropTop, DropBottom } from './styles';
const DropAreaPiece = React.memo((props) => {
    const { component: Component, block, onDrop } = props;
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [DragBlockType],
        drop: onDrop,
        collect: (monitor) => {
            const item = monitor.getItem();
            return {
                isOver: !!monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop() && item.block !== block
            };
        }
    });
    // Don't drop anything if drop hasn't started or we can't complete it
    if (!canDrop) {
        return null;
    }
    return React.createElement(Component, { ref: drop, over: isOver && canDrop });
});
export const DropArea = React.memo((props) => {
    const { left, right, top, bottom, block, onDrop, containerBlock } = props;
    const state = useModelState();
    function handleDrop(value) {
        return (item, monitor) => {
            const isOver = monitor.isOver({ shallow: true });
            const didDrop = monitor.didDrop();
            if (didDrop || !isOver) {
                return undefined;
            }
            onDrop(Object.assign(Object.assign({}, value), { block,
                item }), state, containerBlock);
            return {
                type: 'block',
                block,
                item
            };
        };
    }
    return (React.createElement(React.Fragment, null,
        left && React.createElement(DropAreaPiece, { component: DropLeft, onDrop: handleDrop({ left: true }), block: block }),
        right && React.createElement(DropAreaPiece, { component: DropRight, onDrop: handleDrop({ right: true }), block: block }),
        top && React.createElement(DropAreaPiece, { component: DropTop, onDrop: handleDrop({ top: true }), block: block }),
        bottom && React.createElement(DropAreaPiece, { component: DropBottom, onDrop: handleDrop({ bottom: true }), block: block })));
});
