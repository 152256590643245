import { DelimiterPluginID } from './models';
import { DelimRenderer } from './DelimRenderer';
import { creators } from './creators';
export const delimiterPlugin = {
    id: DelimiterPluginID,
    renderer: DelimRenderer,
    creators,
    menu: true,
    menuOffset: { y: -10 }
};
