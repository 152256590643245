import styled from 'styled-components';
const FocusedBackgroundColor = 'rgba(127, 127, 127, 0.05)';
export const OuterWrapper = styled.div `
  margin: 0px;
  width: 100%;
  max-width: 100%;
  background-color: ${props => (props.focused && props.debug ? FocusedBackgroundColor : 'transparent')};
`;
export const Editable = styled.div `
  width: 100%;
  max-width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  position: relative;
  /* min-height: 28px; */
  min-height: 18px;
  cursor: text;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.375;
  }
  :-ms-input-placeholder {
    color: inherit;
    opacity: 0.375;
  }

  [contenteditable]:empty:before {
    content: attr(placeholder);
    display: block; // For Firefox
    color: inherit;
    opacity: 0.375;
  }
`;
