import React, { useState, useEffect } from 'react';
import { ResizerWrapper, ResizerIndicator } from './styles';
export function Resizer(props) {
    const { readonly, vertical = false, onResizeStart, onResize, onResizeEnd } = props;
    const [resizing, setResizing] = useState(false);
    const [state, setState] = useState();
    function onMouseDown(event) {
        if (readonly) {
            return;
        }
        setState({
            pagePos: vertical ? event.pageX : event.pageY
        });
        onResizeStart();
        setResizing(true);
    }
    function onMouseUp(event) {
        if (readonly || !resizing) {
            return;
        }
        onResizeEnd();
        setResizing(false);
        setState(undefined);
    }
    function onMouseMove(event) {
        if (readonly || !state || !resizing) {
            return;
        }
        const diffSize = vertical ? event.pageX - state.pagePos : event.pageY - state.pagePos;
        onResize(diffSize);
    }
    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
        return () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
    });
    return (React.createElement(ResizerWrapper, { vertical: vertical, onMouseDown: onMouseDown },
        React.createElement(ResizerIndicator, { vertical: vertical })));
}
