import React, { useCallback } from 'react';
import { Editor } from '../../../RIDInlineEditor';
export const MediaCaption = React.memo((props) => {
    const { caption, onUpdated, readonly } = props;
    const onCaptionChanged = useCallback((blocks) => {
        if (onUpdated) {
            onUpdated(blocks);
        }
    }, [onUpdated]);
    return (React.createElement("small", null,
        React.createElement(Editor, { placeholder: readonly ? '' : '(No Caption)', permanentPlaceholder: true, readonly: readonly, blocks: caption || [], onUpdated: onCaptionChanged })));
});
