import React, { useMemo, useContext, useCallback } from 'react';
import { MenuItem, Classes } from '@blueprintjs/core';
import { PluginRefContext } from '../BlockRenderer';
import { useModelState } from '../context';
export function ToolbarMenuCommand(props) {
    const { command, block } = props;
    const { data, func } = command;
    const state = useModelState();
    const { model } = state;
    const pluginRef = useContext(PluginRefContext);
    const itemData = useMemo(() => {
        const b = model.doc.blocks[block];
        return typeof data === 'function' ? data(state, b, pluginRef) : data;
    }, [data, model, block]);
    const onCommand = useCallback(() => {
        func(state, model.doc.blocks[block], pluginRef);
    }, [state, model.doc.blocks[block], pluginRef]);
    return React.createElement(MenuItem, { icon: itemData.icon, text: itemData.name, className: Classes.POPOVER_DISMISS, onClick: onCommand });
}
export default ToolbarMenuCommand;
