import styled from 'styled-components';
const EmojisBackgroundColor = '#30404d';
export const EmojisComponent = styled.div `
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  transition: transform 0.15s cubic-bezier(0.3, 1.2, 0.2, 1);
  transform: scale(${props => (props.visible ? '1' : '0')});
  position: absolute;
  z-index: 100;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  background-color: ${EmojisBackgroundColor};
  border-radius: 3px;
  padding: 0px;
  max-height: 250px;
`;
