import { TimestampRenderer } from './TimestampRenderer';
export const TimestampPluginID = 'timestamp';
export const timestampPlugin = {
    id: TimestampPluginID,
    renderer: TimestampRenderer,
    codec: {
        toJSON: (block) => {
            return Object.assign(Object.assign({}, block), { data: {
                    ts: block.data.ts.toISOString(),
                    format: block.data.format
                } });
        },
        fromJSON: (data) => {
            return Object.assign(Object.assign({}, data), { data: {
                    ts: new Date(data.data.ts),
                    format: data.data.format
                } });
        }
    }
};
// TODO Add a component that suggests automatically some time, once use use something like @now or @today, etc, similarly https://github.com/ProtoForce/protoforce-portal-webui/issues/38
// to how emojies are suggested.
