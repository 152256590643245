import { Alignment } from '../../model';
import { CreateCategory } from '../../model/plugin';
import { ImagePluginID, ImageFillMode } from './models';
// New creators
function createImage(id) {
    return {
        id,
        plugin: ImagePluginID,
        alignment: Alignment.Left,
        media: {
            kind: 'none'
        },
        fill: ImageFillMode.Cover
    };
}
const newCreators = [
    {
        type: 'new',
        icon: 'media',
        name: 'Image',
        desc: 'Image block',
        category: CreateCategory.Media,
        ctor: (withID) => createImage(withID)
    }
];
export const creators = [...newCreators];
