import React, { useContext } from 'react';
import { OptionsContext } from '../../Editor';
import { PageBreak } from './styles';
export const PreviewBreakRenderer = React.memo((props) => {
    const { readonly } = useContext(OptionsContext);
    if (readonly) {
        return null;
    }
    return React.createElement(PageBreak, null, "preview break");
});
