import styled from 'styled-components';
export const Wrapper = styled.div `
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  margin-bottom: 5px;
`;
export const Row = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const AudioContainer = styled.audio `
  height: 32px;
  flex: 1;
`;
