import { CreateCategory } from '../../model/plugin';
import { PreviewBreakPluginID } from './models';
// New creators
function createPreviewBreak(id) {
    return {
        id,
        plugin: PreviewBreakPluginID,
    };
}
const newCreators = [
    {
        type: 'new',
        icon: 'th-disconnect',
        name: 'Preview Break',
        desc: 'Break point for a post preview',
        category: CreateCategory.Containers,
        ctor: (withID) => createPreviewBreak(withID)
    }
];
export const creators = [...newCreators];
