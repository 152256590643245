import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Popover, ControlGroup, Position, Icon } from '@blueprintjs/core';
import { BlockCreateMenu } from './ToolbarMenuCreate';
import { ToolbarMenu } from './ToolbarMenu';
import { ToolbarWrapper, ToolbarButtons } from './styles';
import { useModelState } from '../context';
export function Toolbar(props) {
    const { block, shouldBeVisible, connectDragSource } = props;
    const state = useModelState();
    const plugin = state.options.plugins[block.plugin];
    const menuItems = plugin.menuItems;
    const [visible, setVisible] = useState(false);
    const [optionsOpen, setOptionsOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const toolbarOffset = useMemo(() => {
        if (!plugin.menuOffset) {
            return {
                x: 0,
                y: 0
            };
        }
        return typeof plugin.menuOffset === 'function' ? plugin.menuOffset(state, block) : plugin.menuOffset;
    }, [state, block, plugin]);
    useEffect(() => {
        if (shouldBeVisible) {
            if (!visible) {
                setVisible(true);
            }
        }
        else {
            if (visible && !optionsOpen && !createOpen) {
                setVisible(false);
            }
        }
    }, [shouldBeVisible, visible, optionsOpen, createOpen]);
    const onOptionsOpen = useCallback(() => setOptionsOpen(true), []);
    const onOptionsClose = useCallback(() => setOptionsOpen(false), []);
    const onOptionsClosed = useCallback(() => {
        if (!createOpen) {
            // setVisible(false);
        }
    }, [createOpen]);
    const onCreateOpen = useCallback(() => setCreateOpen(true), []);
    const onCreateClose = useCallback(() => setCreateOpen(false), []);
    const onCreateClosed = useCallback(() => {
        if (!optionsOpen) {
            // setVisible(false);
        }
    }, [optionsOpen]);
    if (!visible) {
        return null;
    }
    return (React.createElement(ToolbarWrapper, { style: {
            top: toolbarOffset.y
        } },
        React.createElement(ToolbarButtons, null,
            React.createElement(ControlGroup, null,
                React.createElement(BlockCreateMenu, { block: block, isOpen: createOpen, onOpen: onCreateOpen, onClose: onCreateClose, onClosed: onCreateClosed }),
                React.createElement(Popover, { autoFocus: false, captureDismiss: true, canEscapeKeyClose: false, hasBackdrop: false, isOpen: optionsOpen, onClose: onOptionsClose, onClosed: onOptionsClosed, content: React.createElement(ToolbarMenu, { block: block, menuItems: menuItems }), position: Position.RIGHT_TOP }, connectDragSource(React.createElement("button", { onClick: onOptionsOpen, className: 'bp4-button bp4-minimal', style: {
                        cursor: 'grab'
                    }, type: 'button' },
                    React.createElement(Icon, { icon: 'drag-handle-vertical' }))))))));
}
export default Toolbar;
