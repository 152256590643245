export var Colors;
(function (Colors) {
    Colors["Default"] = "Default";
    Colors["Gray"] = "Gray";
    Colors["Blue"] = "Blue";
    Colors["Green"] = "Green";
    Colors["Red"] = "Red";
    Colors["Pink"] = "Pink";
    Colors["Orange"] = "Orange";
    Colors["Purple"] = "Purple";
    Colors["Brown"] = "Brown";
})(Colors || (Colors = {}));
export const backColors = {
    [Colors.Default]: undefined,
    [Colors.Gray]: '#7f7f7f',
    [Colors.Blue]: '#1f78b4',
    [Colors.Green]: '#33a02c',
    [Colors.Red]: '#e31a1c',
    [Colors.Pink]: '#e377c2',
    [Colors.Orange]: '#ff7f00',
    [Colors.Purple]: '#6a3d9a',
    [Colors.Brown]: '#b15928'
};
export const textColors = {
    [Colors.Default]: undefined,
    [Colors.Gray]: '#cccccc',
    [Colors.Blue]: '#a6cee3',
    [Colors.Green]: '#b2df8a',
    [Colors.Red]: '#fb9a99',
    [Colors.Pink]: '#fddaec',
    [Colors.Orange]: '#fdbf6f',
    [Colors.Purple]: '#cab2d6',
    [Colors.Brown]: '#e5c494'
};
