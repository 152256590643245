import { newBlockID } from '../../model';
import { SheetPluginID } from './plugin';
export function newSheet(vertical, blocks) {
    return {
        id: newBlockID(),
        plugin: SheetPluginID,
        kind: vertical ? 'vertical' : 'horizontal',
        blocks
    };
}
