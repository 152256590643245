import { AudioRenderer } from './AudioRenderer';
import { AudioPluginID } from './models';
import { creators } from './creators';
export const audioPlugin = {
    id: AudioPluginID,
    renderer: AudioRenderer,
    forwardRef: true,
    creators,
    menu: true,
    menuItems: [
        {
            type: 'command',
            data: {
                icon: 'music',
                name: 'Change audio file...'
            },
            func: (context, block, pluginRef) => {
                if (!pluginRef) {
                    return;
                }
                pluginRef.openPicker();
            }
        }
    ]
};
export function createAudioPlugin(config) {
    return Object.assign(Object.assign({}, audioPlugin), { config });
}
