import { CreateCategory } from '../../model/plugin';
import { AudioPluginID } from './models';
// New creators
function createAudio(id) {
    return {
        id,
        plugin: AudioPluginID,
        media: {
            kind: 'none'
        }
    };
}
const newCreators = [
    {
        type: 'new',
        icon: 'music',
        name: 'Audio',
        desc: 'Attach or link an audio file',
        category: CreateCategory.Media,
        ctor: (withID) => createAudio(withID)
    }
];
// TODO Add conversion to text with a link inline block https://github.com/ProtoForce/protoforce-portal-webui/issues/27
export const creators = [...newCreators];
