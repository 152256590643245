import { PreviewBreakRenderer } from './PreviewBreakRenderer';
import { PreviewBreakPluginID } from './models';
import { creators } from './creators';
export const previewBreakPlugin = {
    id: PreviewBreakPluginID,
    renderer: PreviewBreakRenderer,
    creators,
    menu: true,
    menuOffset: {
        y: 15
    }
};
