import { VideoRenderer } from './VideoRenderer';
import { VideoPluginID } from './models';
import { creators } from './creators';
import { alignmentMenu } from '../common';
export const videoPlugin = {
    id: VideoPluginID,
    renderer: VideoRenderer,
    forwardRef: true,
    creators,
    menu: true,
    menuItems: [
        {
            type: 'command',
            data: {
                icon: 'video',
                name: 'Change Video...'
            },
            func: (context, block, pluginRef) => {
                if (!pluginRef) {
                    return;
                }
                pluginRef.openPicker();
            }
        },
        alignmentMenu
    ]
};
export function createVideoPlugin(config) {
    return Object.assign(Object.assign({}, videoPlugin), { config });
}
