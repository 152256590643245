import { EmbedRenderer } from './EmbedRenderer';
import { EmbedPluginID } from './models';
import { creators } from './creators';
import { alignmentMenu } from '../common';
export const embedPlugin = {
    id: EmbedPluginID,
    renderer: EmbedRenderer,
    forwardRef: true,
    creators,
    menu: true,
    menuItems: [
        {
            type: 'command',
            data: {
                icon: 'link',
                name: 'Change URL...'
            },
            func: (context, block, pluginRef) => {
                if (!pluginRef) {
                    return;
                }
                pluginRef.openPicker();
            }
        },
        alignmentMenu
    ]
};
