import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FormGroup, InputGroup, Tooltip, Button } from '@blueprintjs/core';
import { Wrapper, PreviewImage, PreviewVideo, PreviewVideoDirect } from './styles';
import { videoURLCheck, videoURLProcessor, VIDEO_DIRECT_FILE } from './plugin';
const noURL = {
    kind: 'none'
};
function urlCheck(value) {
    const vl = value.toLowerCase();
    return vl.startsWith('https://') || vl.startsWith('http://') ? undefined : 'Must start with http';
}
function urlFromMedia(media) {
    switch (media.kind) {
        case 'none':
            return '';
        case 'url':
            return media.url;
        case 'upload':
            return media.url;
    }
}
export const URL = React.memo((props) => {
    const { disabled, config, value, onChanged } = props;
    const check = useCallback((v) => {
        const f = config && config.urlCheck ? config.urlCheck : urlCheck;
        return f(v);
    }, [config === null || config === void 0 ? void 0 : config.urlCheck]);
    const [previewImage, setPreviewImage] = useState(false);
    const [previewVideo, setPreviewVideo] = useState();
    const [url, setURL] = useState(() => urlFromMedia(value));
    const [urlError, setURLError] = useState(check(url));
    const onImgLoad = useCallback(() => {
        setPreviewImage(true);
    }, [setPreviewImage]);
    const onImgError = useCallback(() => {
        setPreviewImage(false);
    }, [setPreviewImage]);
    const onValueChange = useCallback((event) => {
        let v = event.currentTarget.value;
        if (config && config.urlProcessor) {
            v = config.urlProcessor(v);
        }
        setURL(v);
        const checkError = check(v);
        setURLError(checkError);
        setPreviewImage(false);
        if (!videoURLCheck(v)) {
            setPreviewVideo(videoURLProcessor(v));
        }
        else {
            setPreviewVideo(undefined);
        }
        if (!checkError) {
            const mf = {
                kind: 'url',
                url: v
            };
            onChanged(mf);
        }
        else {
            onChanged(noURL);
        }
    }, [config === null || config === void 0 ? void 0 : config.urlProcessor, setURL, check, setURLError, setPreviewImage, onChanged]);
    useEffect(() => {
        const newURL = urlFromMedia(value);
        if (newURL === url) {
            return;
        }
        setURL(newURL);
        setURLError(check(newURL));
        setPreviewImage(false);
        if (!videoURLCheck(newURL)) {
            setPreviewVideo(videoURLProcessor(newURL));
        }
        else {
            setPreviewVideo(undefined);
        }
    }, [value]);
    const directVideo = useMemo(() => VIDEO_DIRECT_FILE.test(url), [url]);
    return (React.createElement(Wrapper, null,
        config && config.message && React.createElement("div", { className: 'bp4-text-muted' }, config.message),
        React.createElement(FormGroup, { labelFor: 'url-input', label: 'Address' },
            React.createElement(InputGroup, { id: 'url-input', placeholder: 'https://...', value: url, onChange: onValueChange, onInput: onValueChange, autoFocus: true, disabled: disabled, rightElement: urlError ? (React.createElement(Tooltip, { content: urlError },
                    React.createElement(Button, { disabled: true, icon: 'warning-sign', intent: 'danger', minimal: true }))) : (undefined) })),
        previewVideo ? (directVideo ?
            React.createElement(PreviewVideoDirect, { src: previewVideo, controls: true })
            :
                React.createElement(PreviewVideo, { src: previewVideo })) :
            React.createElement(PreviewImage, { ready: previewImage, src: url, onLoad: onImgLoad, onError: onImgError })));
});
