import React from 'react';
import { Divider, ButtonGroup } from '@blueprintjs/core';
import { FormatButton } from './FormatButton';
import { formatCommands } from '../format/utils';
import { BlockPaintButton } from './BlockPaintButton';
export function ToolbarMenu() {
    return (React.createElement(ButtonGroup, { minimal: true },
        React.createElement(FormatButton, { icon: 'bold', title: 'Bold', command: formatCommands.bold }),
        React.createElement(FormatButton, { icon: 'italic', title: 'Italic', command: formatCommands.italic }),
        React.createElement(FormatButton, { icon: 'underline', title: 'Underline', command: formatCommands.underline }),
        React.createElement(FormatButton, { icon: 'strikethrough', title: 'Strikethrough', command: formatCommands.strike }),
        React.createElement(FormatButton, { icon: 'code', title: 'Code', command: formatCommands.code }),
        React.createElement(Divider, null),
        React.createElement(BlockPaintButton, null)));
}
