import styled from 'styled-components';
const color = `#26343e`;
const bgColor = `rgb(57, 75, 88)`;
export const PageBreak = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  max-width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  background-image:
    /* Top jagged */
    linear-gradient(135deg, ${color} 50%, transparent 50%),
    linear-gradient(225deg, ${color} 50%, transparent 50%),
    /* Bottom jagged */
    linear-gradient(45deg, ${color} 50%, transparent 50%),
    linear-gradient(-45deg, ${color} 50%, transparent 50%);
  background-position:
    /* Top jagged */
    top left, top left,
    /* Bottom jagged */
    bottom left, bottom left;
  background-size: 12px 12px;
  background-repeat: repeat-x;
  background-color: ${bgColor};

  color: rgb(202, 197, 197);
  letter-spacing: 10px;
  font-family: monospace;
`;
