import { CreateCategory } from '../../model/plugin';
import { ListPluginID } from './models';
import { newTextBlock } from '../text';
// New creators
function createList(id, kind) {
    const text = newTextBlock();
    const res = {
        id,
        plugin: ListPluginID,
        kind,
        blocks: [text.id]
    };
    let list;
    switch (kind) {
        case 'checkbox':
            list = Object.assign(Object.assign({}, res), { checked: [] });
            break;
        case 'ordered':
        case 'unordered':
        default:
            list = Object.assign(Object.assign({}, res), { kind });
            break;
    }
    return [list, text];
}
const newCreators = [
    {
        type: 'new',
        icon: 'numbered-list',
        name: 'Numbered list',
        desc: 'A list with numbered points',
        category: CreateCategory.Containers,
        ctor: (withID) => createList(withID, 'ordered')
    },
    {
        type: 'new',
        icon: 'properties',
        name: 'Bulleted list',
        desc: 'A list with bullet points',
        category: CreateCategory.Containers,
        ctor: (withID) => createList(withID, 'unordered')
    },
    {
        type: 'new',
        icon: 'small-tick',
        name: 'Checkbox list',
        desc: 'A list with checkbox points',
        category: CreateCategory.Containers,
        ctor: (withID) => createList(withID, 'checkbox')
    }
];
// From creators
function listToList(withID, from, kind) {
    return {
        id: withID,
        plugin: from.plugin,
        kind,
        blocks: from.blocks
    };
}
function listToCheckbox(withID, from) {
    return {
        id: withID,
        plugin: from.plugin,
        kind: 'checkbox',
        blocks: from.blocks,
        checked: []
    };
}
const fromCreators = [
    {
        type: 'from',
        icon: 'numbered-list',
        name: 'Numbered list',
        ctor: (withID, from) => listToList(withID, from, 'ordered'),
        check: (from) => {
            return from.plugin === ListPluginID && from.kind !== 'ordered';
        }
    },
    {
        type: 'from',
        icon: 'properties',
        name: 'Bulleted list',
        ctor: (withID, from) => listToList(withID, from, 'unordered'),
        check: (from) => {
            return from.plugin === ListPluginID && from.kind !== 'unordered';
        }
    },
    {
        type: 'from',
        icon: 'small-tick',
        name: 'Checkbox list',
        ctor: (withID, from) => listToCheckbox(withID, from),
        check: (from) => {
            return from.plugin === ListPluginID && from.kind !== 'checkbox';
        }
    }
];
export const creators = [...newCreators, ...fromCreators];
