const GM_DIRECT = 'https://www.google.com/maps/';
const GM_EMBED = 'https://www.google.com/maps/embed/v1/';
// TODO Add support for other types of maps:
// Open Street Maps
// Apple Maps
// Here Maps
export function googleMapsURLCheck(value) {
    const vl = value.toLowerCase();
    const res = vl.startsWith(GM_DIRECT) || vl.startsWith(GM_EMBED);
    return res ? undefined : `Google Maps link must start with ${GM_DIRECT}`;
}
function processDirect(value) {
    let mapsData = value.substr(GM_DIRECT.length);
    const isPlace = mapsData.startsWith('place/');
    const mode = isPlace ? 'place' : 'view';
    const basePath = `${GM_EMBED}${mode}`;
    const posEnd = mapsData.indexOf('/data=');
    if (posEnd >= 0) {
        mapsData = mapsData.substr(0, posEnd);
    }
    const posStart = mapsData.indexOf('@');
    if (posStart < 0) {
        console.error('Does not look like a real maps URL, must have @ in the URL.');
        return '';
    }
    const mapsPlace = isPlace ? mapsData.substr(6, posStart - 7) : '';
    mapsData = mapsData.substr(posStart + 1);
    const posChunks = mapsData.split(',');
    if (posChunks.length !== 3) {
        console.error('Maps position must include coordinates.');
        return '';
    }
    const mapType = posChunks[2].endsWith('z') ? 'roadmap' : 'satellite';
    const mapCenter = `${posChunks[0]},${posChunks[1]}`;
    // https://github.com/ProtoForce/protoforce-portal-webui/issues/31
    // In satellite mode google gives: @-33.82913,151.1828329,11398m
    // Whie it expects to have the one with z in the end, just like in
    // regular roadmap view, so we just put it at zoom 18 here, TODO
    // maybe extract correct coords
    const mapZoom = mapType === 'satellite' ? '18' : posChunks[2].substr(0, posChunks[2].length - 1);
    const mapQ = isPlace ? `&q=${mapsPlace}` : '';
    return `${basePath}?center=${mapCenter}&zoom=${mapZoom}&maptype=${mapType}${mapQ}`;
}
function processEmbed(value) {
    // For embeds, we want to remove the key if present, otherwise it is fine
    const paramsIndex = value.indexOf('?');
    if (paramsIndex < 0) {
        return value;
    }
    const params = value.substring(paramsIndex + 1).split('&');
    let reassembled = value.substring(0, paramsIndex) + (params.length > 0 ? '?' : '');
    for (const p of params) {
        const pieces = p.split('=');
        if (pieces[0] === 'key') {
            continue;
        }
        reassembled += p + '&';
    }
    // cut the last &
    reassembled = reassembled.substr(0, reassembled.length - 1);
    return reassembled;
}
export function googleMapsURLProcessor(value) {
    const vl = value.toLocaleLowerCase();
    return vl.startsWith(GM_EMBED) ? processEmbed(vl) : processDirect(vl);
}
