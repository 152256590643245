import React, { forwardRef, useImperativeHandle, useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { OuterWrapper, BottomArea } from './styles';
import { BlockRenderer } from './BlockRenderer';
import { buildOptions, buildModel, buildActions, docCollectChildren } from '../model';
import { defaultEditorOptions } from './options';
import { SheetPluginID, textBlockToText, TextPluginID, newTextBlock, PreviewBreakPluginID } from '../plugins';
import { OptionsContext, ModelContext, ActionsContext } from './context';
// const RIDEditorImpl: RefForwardingComponent<RIDEditorRef, RIDEditorProps<B, PC, F, BS, RF, P>> = (props, forRef) => {
function RIDEditorImpl(props, forRef) {
    const ref = useRef(null);
    const [, setUpdated] = useState({});
    const onRedraw = useCallback(() => setUpdated({}), [setUpdated]);
    const options = useMemo(() => {
        const editorOptions = props.options || defaultEditorOptions;
        return buildOptions(editorOptions);
    }, [props.options]);
    const [model, setModel] = useState(() => buildModel(options, props.doc));
    const onUpdated = useCallback(() => {
        if (props.onUpdated) {
            props.onUpdated(model.doc);
        }
    }, [props.onUpdated, model]);
    const actions = useMemo(() => buildActions(options, model, onUpdated, onRedraw), [options, model, onUpdated, onRedraw]);
    useEffect(() => {
        setModel(buildModel(options, props.doc));
    }, [props.doc, options]);
    const onAddSheetClick = useCallback(() => {
        const doc = model.doc;
        const root = doc.blocks[model.doc.root];
        if (root.plugin !== SheetPluginID) {
            return;
        }
        const sheet = root;
        if (sheet.kind !== 'vertical') {
            return;
        }
        const last = sheet.blocks.length === 0 ? undefined : doc.blocks[sheet.blocks[sheet.blocks.length - 1]];
        if (last && last.plugin === TextPluginID && last.inlines.length === 0) {
            actions.focus(last.id);
            return;
        }
        const text = newTextBlock();
        const cloned = actions.clone(sheet).cloned;
        cloned.blocks.push(text.id);
        actions.update(cloned, text);
        actions.focus(text.id);
    }, [model, actions]);
    useImperativeHandle(forRef, () => {
        return {
            getDoc: () => model.doc,
            getPreview: () => {
                let plain = '';
                const clone = JSON.parse(JSON.stringify(model.doc));
                const root = clone.blocks[clone.root];
                const rootPlugin = options.plugins[root.plugin];
                if (!rootPlugin.container) {
                    throw new Error(`Unsupported root block, expected container got ${rootPlugin.id}`);
                }
                const rootchildren = rootPlugin.container.children(root).slice(); // Get a slice, as this may be the actual container ones and we need to iterate over them later
                const previewBlocks = { [clone.root]: true };
                for (const c of rootchildren) {
                    const cb = clone.blocks[c];
                    if (cb.plugin !== PreviewBreakPluginID) {
                        previewBlocks[c] = true;
                        // TODO Currently supports only a text block, add universal support to transform a block to text,
                        // potentially also to HTML and so on.
                        if (cb.plugin === TextPluginID) {
                            plain += textBlockToText(cb) + '\n';
                        }
                        continue;
                    }
                    break;
                }
                if (rootchildren.length === Object.keys(previewBlocks).length) {
                    return { doc: clone, plain };
                }
                const orphanedBlocks = [];
                for (const c of rootchildren) {
                    if (c in previewBlocks) {
                        continue;
                    }
                    orphanedBlocks.push(...docCollectChildren(options.plugins, clone, c, false));
                    rootPlugin.container.remove(root, c);
                    delete clone.blocks[c];
                }
                for (const ob of orphanedBlocks) {
                    if (!(ob in clone.blocks)) {
                        continue;
                    }
                    delete clone.blocks[ob];
                }
                return { doc: clone, plain };
            }
        };
    }, [model, actions]);
    // TODO Fix those types here https://github.com/ProtoForce/protoforce-portal-webui/issues/19
    return (React.createElement(OptionsContext.Provider, { value: options },
        React.createElement(ModelContext.Provider, { value: model },
            React.createElement(ActionsContext.Provider, { value: actions },
                React.createElement(OuterWrapper, { ref: ref, style: props.style, className: props.className },
                    React.createElement(BlockRenderer, { block: model.doc.blocks[model.doc.root] }),
                    !options.readonly && React.createElement(BottomArea, { onClick: onAddSheetClick }),
                    props.options && props.options.debug && (React.createElement("small", null,
                        React.createElement("pre", null,
                            "Blocks:",
                            JSON.stringify(model.doc.blocks, undefined, 2)))))))));
}
export const RIDEditor = React.memo(forwardRef(RIDEditorImpl));
