import React, { useRef, useContext, useMemo, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';
import { Editor, intentFromKeyboardEvent, InputIntent, isAtDOMStart, isAtDOMEnd, isEmojiPickerOpen } from '../../../RIDInlineEditor';
import { newTextBlock, textMenuVerticalOffset, TextPluginID } from './plugin';
import { FocusTarget, FocusPosition, Alignment } from '../../model';
import { PWrapper, H1Wrapper, H2Wrapper, H3Wrapper, QuoteWrapper, Anchor } from './styles';
import { ActionsContext, OptionsContext } from '../../Editor';
const variations = {
    header: [
        {
            placeholder: 'Header',
            component: H1Wrapper
        },
        {
            placeholder: 'Header',
            component: H2Wrapper
        },
        {
            placeholder: 'Header',
            component: H3Wrapper
        }
    ],
    paragraph: {
        placeholder: 'Paragraph',
        component: PWrapper
    },
    quote: {
        placeholder: 'Quote',
        component: QuoteWrapper
    }
};
export const TextRenderer = React.memo((props) => {
    const { block, over, toolbarShouldBeVisible, pluginConfig } = props;
    const actions = useContext(ActionsContext);
    const ref = useRef(null);
    const editorRef = useRef(null);
    const { readonly } = useContext(OptionsContext);
    const onUpdated = useCallback((inlines) => {
        // We do shallow cloning here
        const cloned = Object.assign(Object.assign({}, block), { inlines });
        actions.update(cloned);
    }, [block, actions]);
    const isAtStart = useCallback(() => {
        if (!editorRef.current || !editorRef.current.ref.current) {
            return false;
        }
        return isAtDOMStart(editorRef.current.ref.current, true);
    }, []);
    const isAtEnd = useCallback(() => {
        if (!editorRef.current || !editorRef.current.ref.current) {
            return false;
        }
        return isAtDOMEnd(editorRef.current.ref.current, true);
    }, []);
    const onKeyDown = useCallback((event) => {
        const intent = intentFromKeyboardEvent(event);
        switch (intent) {
            case InputIntent.Return:
                // Emoji plugin may not like this, we need to make sure we don't go to a new line if it is open
                if (isEmojiPickerOpen()) {
                    return;
                }
                event.preventDefault();
                if (!ref.current || !editorRef.current) {
                    return;
                }
                const newBlock = newTextBlock();
                actions.insert(newBlock, block.id, true, undefined, true);
                break;
            case InputIntent.DeletePrevious:
                if (isAtStart()) {
                    const prevText = actions.prev(block.id, TextPluginID);
                    if (prevText) {
                        event.preventDefault();
                        const prevCloned = actions.clone(prevText).cloned;
                        prevCloned.inlines = [
                            ...prevCloned.inlines,
                            ...block.inlines
                        ];
                        // TODO This has to be done in a way where it can be used as an update history https://github.com/ProtoForce/protoforce-portal-webui/issues/34
                        actions.remove(block);
                        actions.update(prevCloned);
                        actions.focus(prevCloned.id, undefined, FocusPosition.End);
                    }
                }
                break;
            case InputIntent.MoveLeft:
            case InputIntent.MoveUp:
                if (intent === InputIntent.MoveUp && isEmojiPickerOpen()) {
                    break;
                }
                if (isAtStart()) {
                    event.preventDefault();
                    actions.focus(block.id, FocusTarget.Previous, FocusPosition.End, true);
                }
                break;
            case InputIntent.MoveRight:
            case InputIntent.MoveDown:
                if (intent === InputIntent.MoveDown && isEmojiPickerOpen()) {
                    break;
                }
                if (isAtEnd()) {
                    event.preventDefault();
                    actions.focus(block.id, FocusTarget.Next, FocusPosition.Start, true);
                }
                break;
        }
    }, [actions, block, isAtStart, isAtEnd]);
    const variation = block.kind === 'header' ? variations.header[block.size - 1] : variations[block.kind];
    const placeholder = variation.placeholder;
    const mayShowAnchor = block.kind === 'header' && readonly ? true : false;
    const anchor = useMemo(() => {
        return (pluginConfig === null || pluginConfig === void 0 ? void 0 : pluginConfig.anchors) ? pluginConfig.anchors(block) : undefined;
    }, [mayShowAnchor, pluginConfig, block]);
    const onAnchorClick = useCallback((event) => {
        if (!(pluginConfig === null || pluginConfig === void 0 ? void 0 : pluginConfig.onAnchorClick)) {
            return;
        }
        pluginConfig.onAnchorClick(block, anchor, event);
    }, [block, anchor, pluginConfig === null || pluginConfig === void 0 ? void 0 : pluginConfig.onAnchorClick]);
    const Wrapper = variation.component;
    const style = useMemo(() => {
        switch (block.alignment) {
            case Alignment.Right:
                return {
                    textAlign: 'right'
                };
            case Alignment.Center:
                return {
                    textAlign: 'center'
                };
            case Alignment.Left:
            default:
                return undefined;
        }
    }, [block.alignment]);
    return (React.createElement(React.Fragment, null,
        mayShowAnchor && anchor &&
            React.createElement(Anchor, { href: anchor, style: { top: textMenuVerticalOffset(block) + 5, opacity: over ? 1 : 0 }, onClick: onAnchorClick },
                React.createElement(Icon, { icon: 'link', iconSize: 16 })),
        React.createElement(Wrapper, { ref: ref, style: style },
            React.createElement(Editor, { ref: editorRef, readonly: readonly, placeholder: placeholder, permanentPlaceholder: toolbarShouldBeVisible, blocks: block.inlines, onUpdated: onUpdated, onKeyDown: onKeyDown }))));
});
