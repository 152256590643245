import React, { useContext, useMemo, useState, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';
import { VideoView } from '~/components/general/VideoView';
import { Wrapper, VideoIFrame, VideoResizableFrame } from './styles';
import { MediaPicker, urlPicker, uploadPicker, videoURLCheck, videoURLProcessor, VIDEO_DIRECT_FILE, Rescaler, RescalerMode, useRescalerStyle, Dummy, ActionsContext, OptionsContext } from '../../Editor';
import { useResizableBlockFlexAlignment } from '../../model';
const VideoRendererImpl = (props, forRef) => {
    const { block, pluginConfig } = props;
    const actions = useContext(ActionsContext);
    const { readonly } = useContext(OptionsContext);
    const ref = useRef(null);
    const blockRef = useRef(null);
    const [rescaleState, setRescaleState] = useState();
    const [pickerOpen, setPickerOpen] = useState(false);
    const url = useMemo(() => {
        return block.media.kind === 'none' ? undefined : block.media.url;
    }, [block.media]);
    const plugins = useMemo(() => {
        const pickerPlugins = [
            Object.assign(Object.assign({}, urlPicker), { config: {
                    urlCheck: videoURLCheck,
                    urlProcessor: videoURLProcessor
                } })
        ];
        if (!pluginConfig) {
            return pickerPlugins;
        }
        if (pluginConfig.uploader) {
            pickerPlugins.push(Object.assign(Object.assign({}, uploadPicker), { config: {
                    upload: pluginConfig.uploader
                } }));
        }
        return pickerPlugins;
    }, [pluginConfig === null || pluginConfig === void 0 ? void 0 : pluginConfig.uploader]);
    const onOpenMedia = useCallback(() => {
        if (readonly) {
            return;
        }
        setPickerOpen(true);
    }, [readonly, setPickerOpen]);
    const onSelectMedia = useCallback((value, plugin, caption) => {
        const cloned = actions.clone(block).cloned;
        cloned.media = value;
        actions.update(cloned);
        setPickerOpen(false);
    }, [actions, block, setPickerOpen]);
    const onCancelMedia = useCallback(() => {
        setPickerOpen(false);
    }, [setPickerOpen]);
    const onRescaleStart = useCallback((update) => {
        if (!update) {
            return;
        }
        setRescaleState(update);
    }, [setRescaleState]);
    const onRescale = useCallback((update) => {
        if (!update) {
            return;
        }
        setRescaleState(update);
    }, [setRescaleState]);
    const onRescaleEnd = useCallback((update) => {
        if (!update || (!update.width && !update.height)) {
            return;
        }
        const cloned = actions.clone(block).cloned;
        if (update.width) {
            cloned.width = update.width.value;
        }
        if (update.height) {
            cloned.height = update.height.value;
        }
        actions.update(cloned);
        setRescaleState(undefined);
    }, [actions, block, setRescaleState]);
    useImperativeHandle(forRef, () => {
        return {
            openPicker: onOpenMedia
        };
    });
    const containerStyle = useRescalerStyle(block, rescaleState);
    const alignmentStyle = useResizableBlockFlexAlignment(block);
    const directVideo = url ? VIDEO_DIRECT_FILE.test(url) : false;
    return (React.createElement(Wrapper, { ref: blockRef, style: alignmentStyle },
        React.createElement(VideoResizableFrame, { ref: ref, style: containerStyle },
            url ? (block.media.kind === 'upload' || directVideo ? (
            // TODO Add support for video uploads https://github.com/ProtoForce/protoforce-portal-webui/issues/35
            React.createElement(VideoView, { url: url, type: 'video/mp4', style: {
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    cursor: 'default',
                    borderWidth: 0
                } })) : (React.createElement(VideoIFrame, { src: url, frameBorder: 0, allowFullScreen: true }))) : (React.createElement(Dummy, { interactive: !readonly, onClick: onOpenMedia },
                React.createElement(Icon, { icon: 'link' }),
                "\u00A0 No URL provided. Click here to set one.")),
            React.createElement(Rescaler, { readonly: readonly, mode: RescalerMode.WidthHeight, target: {
                    alignment: block.alignment,
                    container: blockRef,
                    element: ref
                }, onRescaleStart: onRescaleStart, onRescale: onRescale, onRescaleEnd: onRescaleEnd })),
        React.createElement(MediaPicker, { blockID: block.id, open: pickerOpen, value: block.media, plugins: plugins, onSave: onSelectMedia, onCancel: onCancelMedia })));
};
export const VideoRenderer = React.memo(forwardRef(VideoRendererImpl));
