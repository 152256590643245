import React, { useContext } from 'react';
import { Heading, Wrapper } from './styles';
import { BlockRenderer, ModelContext } from '../../Editor';
export const CalloutRenderer = React.memo((props) => {
    const { block } = props;
    const model = useContext(ModelContext);
    const headerBlock = model.doc.blocks[block.header];
    const contentBlock = model.doc.blocks[block.content];
    return (React.createElement(Wrapper, { intent: block.intent },
        React.createElement(Heading, { intent: block.intent },
            React.createElement(BlockRenderer, { block: headerBlock, locked: true })),
        React.createElement(BlockRenderer, { block: contentBlock, locked: true })));
});
