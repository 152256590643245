import { ListRenderer } from './ListRenderer';
import { ListPluginID } from './models';
import { creators } from './creators';
function insert(container, block, after) {
    const afterIndex = typeof after !== 'undefined' ? container.blocks.indexOf(after) : -1;
    const blocks = Array.isArray(block) ? block : [block];
    if (afterIndex >= 0) {
        container.blocks.splice(afterIndex + 1, 0, ...blocks);
    }
    else {
        container.blocks.push(...blocks);
    }
    return true;
}
function prev(container, id) {
    if (id === container.id) {
        return undefined;
    }
    const index = container.blocks.indexOf(id);
    if (index < 0) {
        return undefined;
    }
    if (index === 0) {
        return container.id;
    }
    return container.blocks[index - 1];
}
function next(container, id) {
    if (id === container.id) {
        return container.blocks[0];
    }
    const index = container.blocks.indexOf(id);
    return container.blocks[index + 1];
}
function children(container) {
    return container.blocks;
}
function replace(container, id, withID) {
    const index = container.blocks.indexOf(id);
    if (index >= 0) {
        container.blocks[index] = withID;
    }
}
function remove(container, id) {
    const index = container.blocks.indexOf(id);
    if (index < 0) {
        return false;
    }
    container.blocks.splice(index, 1);
    return true;
}
export const listPlugin = {
    id: ListPluginID,
    renderer: ListRenderer,
    menu: true,
    creators,
    container: {
        insert,
        prev,
        next,
        children,
        replace,
        remove
    }
};
