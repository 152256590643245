import React, { useContext } from 'react';
import { WrapperUL, WrapperOL, blockStyle } from './styles';
import { BlockRenderer, ModelContext } from '../../Editor';
// https://github.com/ProtoForce/protoforce-portal-webui/issues/32
// TODO Consider moving this to a plugin itself, so that
// all containers always follow this paradigm, to avoid
// re-renders
function onDrop(result, state, blockID) {
    const { model, actions } = state;
    const block = model.doc.blocks[blockID];
    if (!block) {
        return;
    }
    // We only support bottom for now, so just stick to it
    const { block: dropBlock, item } = result;
    const sourceBlock = item.block;
    const srcParent = actions.parent(sourceBlock);
    if (!srcParent) {
        return;
    }
    // https://github.com/ProtoForce/protoforce-portal-webui/issues/32
    // TODO Organize this code into a move handler on the model,
    // so that we can do this in any container easily
    const { parent: sourceParentBlock, plugin: sourceParentPlugin } = srcParent;
    const sameSourceTarget = sourceParentBlock.id === block.id;
    // Remove this block from the parent that currently holds it
    const sourceParentClone = actions.clone(sourceParentBlock).cloned;
    sourceParentPlugin.container.remove(sourceParentClone, sourceBlock);
    // https://github.com/ProtoForce/protoforce-portal-webui/issues/32
    // TODO Check for consistency that we can remove from the source parent?
    // For example if it doesn't exist there - it might be inconsistent
    // Attach to the current block
    const targetParentClone = sameSourceTarget ? sourceParentClone : actions.clone(block).cloned;
    const dropIndex = targetParentClone.blocks.indexOf(dropBlock);
    if (dropIndex < 0) {
        console.warn(`Inconsistent model, drop block ${dropBlock} is not found`, targetParentClone);
        return;
    }
    targetParentClone.blocks.splice(dropIndex + 1, 0, sourceBlock);
    if (sameSourceTarget) {
        actions.update(targetParentClone);
    }
    else {
        // https://github.com/ProtoForce/protoforce-portal-webui/issues/32
        // TODO Move this clean up part to the model update method, it does make sense to check there,
        // as the hierarchy of updates is known and can be traversed
        if (sourceParentPlugin.container.children(sourceParentClone).length === 0) {
            // Source parent container is now empty, we need to remove it
            const deletion = [sourceParentClone.id];
            const updates = [targetParentClone];
            let delParentID = sourceParentClone.id;
            while (delParentID) {
                const delParent = actions.parent(delParentID);
                if (!delParent) {
                    // May happen if we are at the root component
                    break;
                }
                if (delParent.plugin.container.children(delParent.parent).length > 1) {
                    // Parent has other children, but we still need to clean up
                    // our empty container. Let's clone the parent, update its
                    // children, and then only break
                    if (delParent.parent.id === targetParentClone.id) {
                        // We've reached the parent block which we moved the
                        // block to, so we just need to remove the container
                        targetParentClone.blocks.splice(targetParentClone.blocks.indexOf(delParentID), 1);
                    }
                    else {
                        const delParentClone = actions.clone(delParent.parent).cloned;
                        delParent.plugin.container.remove(delParentClone, delParentID);
                        updates.push(delParentClone);
                    }
                    break;
                }
                deletion.push(delParent.parent.id);
                delParentID = delParent.parent.id;
            }
            actions.update(updates, undefined, deletion);
        }
        else {
            actions.update([sourceParentClone, targetParentClone]);
        }
    }
}
function ListRendererImpl(props) {
    const { block } = props;
    const model = useContext(ModelContext);
    const Wrapper = block.kind === 'ordered' ? WrapperOL : WrapperUL;
    return (React.createElement(Wrapper, null, block.blocks.map((b, bi) => (React.createElement("li", { key: b },
        React.createElement(BlockRenderer, { block: model.doc.blocks[b], drop: {
                bottom: true,
                onDrop,
                containerBlock: block.id
            }, locked: bi === 0, style: blockStyle }))))));
}
export const ListRenderer = React.memo(ListRendererImpl);
