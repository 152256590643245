import { FileRenderer } from './FileRenderer';
import { FilePluginID } from './models';
import { creators } from './creators';
export const filePlugin = {
    id: FilePluginID,
    renderer: FileRenderer,
    forwardRef: true,
    creators,
    menu: true,
    menuItems: [
        {
            type: 'command',
            data: {
                icon: 'paperclip',
                name: 'Change file...'
            },
            func: (context, block, pluginRef) => {
                if (!pluginRef) {
                    return;
                }
                pluginRef.openPicker();
            }
        }
    ]
};
export function createFilePlugin(config) {
    return Object.assign(Object.assign({}, filePlugin), { config });
}
