import styled, { css } from 'styled-components';
export const Wrapper = styled.div `
  height: 300px;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  margin: 20px;
  border-style: ${props => (props.over ? css `solid` : css `dashed`)};
  border-width: 2px;
  border-color: #7ba8ce;
  border-radius: 5px;
  cursor: ${props => (props.readonly ? undefined : 'pointer')};
`;
export const DropInput = styled.input `
  opacity: 0;
  position: fixed;
  top: -100em;
`;
export const DropImage = styled.img `
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  object-fit: scale-down;
`;
export const DropCTA = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
`;
