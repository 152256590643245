import { useMemo } from 'react';
import { Alignment } from './alignment';
export function useResizableBlockFlexAlignment(block) {
    return useMemo(() => {
        switch (block.alignment) {
            case Alignment.Right:
                return {
                    alignItems: 'flex-end'
                };
            case Alignment.Center:
                return {
                    alignItems: 'center'
                };
            case Alignment.Left:
            default:
                return undefined;
        }
    }, [block.alignment]);
}
