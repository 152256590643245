import React, { useContext, useMemo, useState, useRef, useImperativeHandle, forwardRef, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';
import { Wrapper, GoogleMapsIFrame, GoogleMapsResizableFrame } from './styles';
import { MediaPicker, Dummy, urlPicker, Rescaler, RescalerMode, useRescalerStyle, OptionsContext, ActionsContext } from '../../Editor';
import { MediaCaption } from '../common';
import { useResizableBlockFlexAlignment } from '../../model';
import { newTextBlock } from '../../../RIDInlineEditor';
import { googleMapsURLCheck, googleMapsURLProcessor } from './url';
const GoogleMapsRendererImpl = (props, forRef) => {
    const { block, pluginConfig } = props;
    const actions = useContext(ActionsContext);
    const { readonly } = useContext(OptionsContext);
    const ref = useRef(null);
    const blockRef = useRef(null);
    const [rescaleState, setRescaleState] = useState();
    const [pickerOpen, setPickerOpen] = useState(false);
    const url = useMemo(() => {
        return block.media.kind === 'none' ? undefined : block.media.url;
    }, [block.media]);
    const pickerPlugins = useMemo(() => {
        return [
            Object.assign(Object.assign({}, urlPicker), { config: {
                    urlCheck: googleMapsURLCheck,
                    urlProcessor: googleMapsURLProcessor
                } })
        ];
    }, []);
    const onCaptionUpdated = useCallback((caption) => {
        const cloned = actions.clone(block).cloned;
        cloned.caption = caption;
        actions.update(cloned);
    }, [actions, block]);
    const onOpenMedia = useCallback(() => {
        if (readonly) {
            return;
        }
        setPickerOpen(true);
    }, [setPickerOpen]);
    const onSelectMedia = useCallback((value, plugin, caption) => {
        const cloned = actions.clone(block).cloned;
        if (cloned.media.kind === 'none' && value.kind !== 'none') {
            cloned.height = 450;
        }
        else if (cloned.media.kind !== 'none' && value.kind === 'none') {
            cloned.height = undefined;
        }
        cloned.media = value;
        if (caption) {
            cloned.caption = [newTextBlock(caption)];
        }
        actions.update(cloned);
        setPickerOpen(false);
    }, [actions, block, setPickerOpen]);
    const onCancelMedia = useCallback(() => {
        setPickerOpen(false);
    }, [setPickerOpen]);
    const onRescaleStart = useCallback((update) => {
        if (!update) {
            return;
        }
        setRescaleState(update);
    }, [setRescaleState]);
    const onRescale = useCallback((update) => {
        if (!update) {
            return;
        }
        setRescaleState(update);
    }, [setRescaleState]);
    const onRescaleEnd = useCallback((update) => {
        if (!update || (!update.width && !update.height)) {
            return;
        }
        const cloned = actions.clone(block).cloned;
        if (update.width) {
            cloned.width = update.width.value;
        }
        if (update.height) {
            cloned.height = update.height.value;
        }
        actions.update(cloned);
        setRescaleState(undefined);
    }, [actions, block, setRescaleState]);
    useImperativeHandle(forRef, () => {
        return {
            openPicker: onOpenMedia
        };
    });
    const containerStyle = useRescalerStyle(block, rescaleState);
    const alignmentStyle = useResizableBlockFlexAlignment(block);
    if (!pluginConfig) {
        return React.createElement("span", null, "Google Maps plugin requires a config with an API key.");
    }
    const mapsURL = url && url.length > 0 ? `${url}&key=${pluginConfig.apiKey}` : undefined;
    return (React.createElement(Wrapper, { ref: blockRef, style: alignmentStyle },
        React.createElement(GoogleMapsResizableFrame, { ref: ref, style: containerStyle },
            mapsURL ? (React.createElement(GoogleMapsIFrame, { src: mapsURL, frameBorder: 0, allowFullScreen: true })) : (React.createElement(Dummy, { interactive: !readonly, onClick: onOpenMedia },
                React.createElement(Icon, { icon: 'map' }),
                "\u00A0 Click here to set Google Maps link.")),
            React.createElement(Rescaler, { readonly: readonly, mode: RescalerMode.WidthHeight, target: {
                    alignment: block.alignment,
                    container: blockRef,
                    element: ref
                }, onRescaleStart: onRescaleStart, onRescale: onRescale, onRescaleEnd: onRescaleEnd })),
        React.createElement(MediaPicker, { blockID: block.id, open: pickerOpen, value: block.media, plugins: pickerPlugins, onSave: onSelectMedia, onCancel: onCancelMedia }),
        React.createElement(MediaCaption, { readonly: readonly, caption: block.caption, onUpdated: onCaptionUpdated })));
};
export const GoogleMapsRenderer = React.memo(forwardRef(GoogleMapsRendererImpl));
