import { useEffect, useRef } from 'react';
export function useVisibilityObserver(element, onVisibilityChange, threshold = 1.0) {
    const callback = useRef(onVisibilityChange);
    callback.current = onVisibilityChange;
    useEffect(() => {
        if (!element) {
            return;
        }
        if (!('IntersectionObserver' in window) ||
            !('IntersectionObserverEntry' in window) ||
            !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
            return;
        }
        const observer = new IntersectionObserver(entries => {
            if (!callback.current) {
                return;
            }
            callback.current(entries[0].isIntersecting);
        }, { threshold });
        observer.observe(element);
        return () => {
            observer.unobserve(element);
            observer.disconnect();
        };
    }, [element, threshold]);
}
