import React, { useContext, useState, useImperativeHandle, forwardRef, useMemo, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';
import { formatBytes } from './models';
import { Wrapper, FileContainer, FileName, FileSize } from './styles';
import { Dummy, MediaPicker, urlPicker, uploadPicker, ActionsContext, OptionsContext } from '../../Editor';
const FileRendererImpl = (props, forRef) => {
    const { block, pluginConfig } = props;
    const actions = useContext(ActionsContext);
    const { readonly } = useContext(OptionsContext);
    const [pickerOpen, setPickerOpen] = useState(false);
    const { media } = block;
    const plugins = useMemo(() => {
        if (!pluginConfig) {
            return [urlPicker];
        }
        const pickerPlugins = [
            urlPicker,
            Object.assign(Object.assign({}, uploadPicker), { config: {
                    upload: pluginConfig.uploader
                } })
        ];
        return pickerPlugins;
    }, [pluginConfig]);
    const onOpenMedia = useCallback(() => {
        if (readonly) {
            return;
        }
        setPickerOpen(true);
    }, [readonly, setPickerOpen]);
    const onSelectMedia = useCallback((value, plugin, caption) => {
        const cloned = actions.clone(block).cloned;
        cloned.media = value;
        actions.update(cloned);
        setPickerOpen(false);
    }, [actions, block, setPickerOpen]);
    const onCancelMedia = useCallback(() => {
        setPickerOpen(false);
    }, [setPickerOpen]);
    useImperativeHandle(forRef, () => {
        return {
            openPicker: onOpenMedia
        };
    });
    return (React.createElement(Wrapper, null,
        media.kind !== 'none' ? (React.createElement(FileContainer, { href: media.url, target: 'blank' },
            React.createElement(FileName, null,
                React.createElement(Icon, { icon: 'paperclip' }),
                "\u00A0",
                media.kind === 'url' ? media.url : media.name),
            media.kind === 'upload' && React.createElement(FileSize, null, formatBytes(media.size, 2)))) : (React.createElement(Dummy, { interactive: !readonly, onClick: onOpenMedia },
            React.createElement(Icon, { icon: 'paperclip' }),
            "\u00A0 Attach or link a file.")),
        React.createElement(MediaPicker, { blockID: block.id, open: pickerOpen, value: media, plugins: plugins, onSave: onSelectMedia, onCancel: onCancelMedia })));
};
export const FileRenderer = React.memo(forwardRef(FileRendererImpl));
