import React from 'react';
import styled, { css } from 'styled-components';
import { textColors, backColors } from '../../model';
export const A = styled.div `
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border-width: ${props => (props.text ? '1' : '0')}px;
  ${props => props.text
    ? css `
          border-style: solid;
        `
    : undefined}
  font-weight: 600;
`;
export const BlockPaintIcon = React.memo((props) => {
    const { text, back } = props;
    return (React.createElement(A, { text: text ? true : false, style: {
            color: text ? textColors[text] : undefined,
            backgroundColor: back ? backColors[back] : undefined
        } }, "A"));
});
