import React, { useContext, useState, useImperativeHandle, forwardRef, useMemo, useCallback } from 'react';
import { Icon } from '@blueprintjs/core';
import { Wrapper, Row, AudioContainer } from './styles';
import { Dummy, MediaPicker, urlPicker, uploadPicker, ActionsContext, OptionsContext } from '../../Editor';
const AudioRendererImpl = (props, forRef) => {
    const { block, pluginConfig } = props;
    const actions = useContext(ActionsContext);
    const { readonly } = useContext(OptionsContext);
    const [pickerOpen, setPickerOpen] = useState(false);
    const { media } = block;
    const plugins = useMemo(() => {
        if (!pluginConfig) {
            return [urlPicker];
        }
        const pickerPlugins = [
            urlPicker,
            Object.assign(Object.assign({}, uploadPicker), { config: {
                    upload: pluginConfig.uploader
                } })
        ];
        return pickerPlugins;
    }, [pluginConfig]);
    const onOpenMedia = useCallback(() => {
        if (readonly) {
            return;
        }
        setPickerOpen(true);
    }, [readonly, setPickerOpen]);
    const onSelectMedia = useCallback((value, plugin, caption) => {
        const cloned = actions.clone(block).cloned;
        cloned.media = value;
        actions.update(cloned);
        setPickerOpen(false);
    }, [actions, block, setPickerOpen]);
    const onCancelMedia = useCallback(() => {
        setPickerOpen(false);
    }, [setPickerOpen]);
    useImperativeHandle(forRef, () => {
        return {
            openPicker: onOpenMedia
        };
    });
    return (React.createElement(Wrapper, null,
        media.kind !== 'none' ? (React.createElement(Row, null,
            React.createElement(Icon, { icon: 'music' }),
            "\u00A0\u00A0",
            media.kind === 'upload' ? React.createElement(React.Fragment, null,
                media.name,
                "\u00A0\u00A0") : '',
            React.createElement(AudioContainer, { controls: true },
                React.createElement("source", { src: media.url }),
                "Your browser does not support the audio element."))) : (React.createElement(Dummy, { interactive: !readonly, onClick: onOpenMedia },
            React.createElement(Icon, { icon: 'music' }),
            "\u00A0 Attach or link an audio file.")),
        React.createElement(MediaPicker, { blockID: block.id, open: pickerOpen, value: media, plugins: plugins, onSave: onSelectMedia, onCancel: onCancelMedia })));
};
export const AudioRenderer = React.memo(forwardRef(AudioRendererImpl));
