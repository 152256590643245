import React, { useContext } from 'react';
import { Button, Popover, Menu, MenuItem, MenuDivider, ButtonGroup } from '@blueprintjs/core';
import { applyColor } from '../color/utils';
import { textColors, backColors } from '../../model';
import { ModelContext, ActionsContext } from '../../Editor';
import { BlockPaintIcon } from './BlockPaintIcon';
let lastTextColor;
let lastBackColor;
export const BlockPaintButton = () => {
    const model = useContext(ModelContext);
    const actions = useContext(ActionsContext);
    const contexts = { model, actions };
    function applyLastText(event) {
        applyColor(contexts, lastTextColor, undefined);
        event.preventDefault();
    }
    function applyLastBack(event) {
        applyColor(contexts, lastBackColor, true);
        event.preventDefault();
    }
    function colorFunc(color, back) {
        return (event) => {
            applyColor(contexts, color, back);
            if (event) {
                event.preventDefault();
            }
            if (back) {
                lastBackColor = color;
            }
            else {
                lastTextColor = color;
            }
        };
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonGroup, null,
            React.createElement(Popover, { content: React.createElement(Menu, null,
                    Object.keys(textColors).map(tc => (React.createElement(MenuItem, { key: tc, icon: React.createElement(BlockPaintIcon, { text: tc }), text: tc, onClick: colorFunc(tc, undefined) }))),
                    React.createElement(MenuDivider, null),
                    Object.keys(backColors).map(bc => (React.createElement(MenuItem, { key: bc, icon: React.createElement(BlockPaintIcon, { back: bc }), text: `${bc} Background`, onClick: colorFunc(bc, true) })))) },
                React.createElement(Button, { icon: 'tint', text: 'Color', rightIcon: 'chevron-down' })),
            lastTextColor && (React.createElement(Button, { minimal: true, icon: React.createElement(BlockPaintIcon, { text: lastTextColor }), onClick: applyLastText })),
            lastBackColor && (React.createElement(Button, { minimal: true, icon: React.createElement(BlockPaintIcon, { back: lastBackColor }), onClick: applyLastBack })))));
};
