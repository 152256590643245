import styled from 'styled-components';
export const PWrapper = styled.div `
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
`;
export const H1Wrapper = styled.h1 `
  display: flex;
  width: 100%;
  max-width: 100%;

  color: #f5f8fa;
  line-height: 40px;
  font-size: 36px;
  font-weight: 600;
  padding: 0;
`;
export const H2Wrapper = styled.h2 `
  display: flex;
  width: 100%;
  max-width: 100%;

  color: #f5f8fa;
  line-height: 32px;
  font-size: 28px;
  font-weight: 600;
  padding: 0;
`;
export const H3Wrapper = styled.h3 `
  display: flex;
  width: 100%;
  max-width: 100%;

  color: #f5f8fa;
  line-height: 25px;
  font-size: 22px;
  font-weight: 600;
  padding: 0;
`;
export const QuoteWrapper = styled.blockquote `
  display: flex;
  width: 100%;
  max-width: 100%;

  margin: 0 0 10px;
  border-left: 4px solid rgba(115, 134, 148, 0.5);
  padding: 0 20px;
`;
export const Anchor = styled.a `
  position: absolute;
  left: -32px;
  width: 32px;
  transition: opacity 0.1s ease-in-out;
`;
