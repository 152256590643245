export var InputIntent;
(function (InputIntent) {
    InputIntent[InputIntent["Unknown"] = 0] = "Unknown";
    InputIntent[InputIntent["Clear"] = 1] = "Clear";
    InputIntent[InputIntent["DeletePrevious"] = 2] = "DeletePrevious";
    InputIntent[InputIntent["DeleteNext"] = 3] = "DeleteNext";
    InputIntent[InputIntent["MoveLeft"] = 4] = "MoveLeft";
    InputIntent[InputIntent["MoveRight"] = 5] = "MoveRight";
    InputIntent[InputIntent["MoveUp"] = 6] = "MoveUp";
    InputIntent[InputIntent["MoveDown"] = 7] = "MoveDown";
    InputIntent[InputIntent["SelectAll"] = 8] = "SelectAll";
    // Tab,
    InputIntent[InputIntent["Escape"] = 9] = "Escape";
    // NewBlock,
    // Even though those seem like commands, we need to disable
    // them if user tries to do while not having an actual command
    // attached to it.
    InputIntent[InputIntent["Copy"] = 10] = "Copy";
    InputIntent[InputIntent["Cut"] = 11] = "Cut";
    InputIntent[InputIntent["Paste"] = 12] = "Paste";
    InputIntent[InputIntent["Undo"] = 13] = "Undo";
    InputIntent[InputIntent["Redo"] = 14] = "Redo";
    InputIntent[InputIntent["FormatUppercase"] = 15] = "FormatUppercase";
    InputIntent[InputIntent["FormatLowercase"] = 16] = "FormatLowercase";
    InputIntent[InputIntent["FormatClear"] = 17] = "FormatClear";
    InputIntent[InputIntent["FormatBold"] = 18] = "FormatBold";
    InputIntent[InputIntent["FormatItalic"] = 19] = "FormatItalic";
    InputIntent[InputIntent["FormatUnderline"] = 20] = "FormatUnderline";
    InputIntent[InputIntent["FormatSubscript"] = 21] = "FormatSubscript";
    InputIntent[InputIntent["FormatSuperscript"] = 22] = "FormatSuperscript";
    // Reported, though not used in the inline editor itself
    InputIntent[InputIntent["Return"] = 23] = "Return";
})(InputIntent || (InputIntent = {}));
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
export const MODIFIER_KEYS = [
    'Alt',
    'AltGraph',
    'CapsLock',
    'Control',
    'Fn',
    'FnLock',
    'Hyper',
    'Meta',
    'NumLock',
    'ScrollLock',
    'Shift',
    'Super',
    'Symbol',
    'SymbolLock'
];
export const WHITEPSPACE_KEYS = ['Enter', 'Tab', ' '];
export const NAVIGATION_KEYS = ['ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'End', 'Home', 'PageDown', 'PageUp'];
export const EDITING_KEYS = [
    'Backspace',
    'Clear',
    'Copy',
    'CrSel',
    'Cut',
    'Delete',
    'EraseEof',
    'ExSel',
    'Insert',
    'Paste',
    'Redo',
    'Undo',
    ''
];
export const KOREAN_KEYS = ['HangulMode', 'HanjaMode', 'JunjaMode'];
export const JAPANESE_KEYS = [
    'Eisu',
    'Hankaku',
    'Hiragana',
    'HiraganaKatakana',
    'KanaMode',
    'KanjiMode',
    'Katakana',
    'Romaji',
    'Zenkaku',
    'ZenkakuHanaku'
];
export const CONTROL_OR_EDIT_KEYS = [
    ...MODIFIER_KEYS,
    ...NAVIGATION_KEYS,
    ...KOREAN_KEYS,
    ...JAPANESE_KEYS,
    ...EDITING_KEYS
];
export function comboFromString(combo) {
    const pieces = combo
        .toLowerCase()
        .split('+')
        .map(c => c.trim());
    const res = {
        alt: false,
        ctrl: false,
        shift: false,
        key: ''
    };
    for (const p of pieces) {
        switch (p) {
            case 'alt':
                res.alt = true;
                break;
            case 'shift':
                res.shift = true;
                break;
            case 'control':
            case 'ctrl':
            case 'command':
            case 'cmd':
                res.ctrl = true;
                break;
            default:
                res.key = p.toLowerCase();
        }
    }
    return res;
}
export function comboFromKeyboardEvent(event) {
    return {
        alt: event.altKey,
        ctrl: event.ctrlKey || event.metaKey,
        shift: event.shiftKey,
        key: event.key.toLowerCase()
    };
}
export function compareCombo(left, right) {
    return left.ctrl === right.ctrl && left.shift === right.shift && left.key === right.key && left.alt === right.alt;
}
export function intentFromKeyboardEvent(event) {
    const controlPressed = event.ctrlKey || event.metaKey;
    const shiftPressed = event.shiftKey;
    const code = event.keyCode || event.which;
    // console.log(`Code: ${code} Control: ${controlPressed} Shift: ${shiftPressed}`);
    if (event.key) {
        switch (event.key) {
            case 'Clear':
                return InputIntent.Clear;
        }
    }
    if (shiftPressed && controlPressed) {
        switch (code) {
            case 65: // Ctrl + Shift + A
                return InputIntent.FormatUppercase;
            case 75: // Ctrl + Shift + K
                return InputIntent.FormatLowercase;
            case 90: // Ctrl + Shift + Z
                return InputIntent.Redo;
        }
    }
    if (controlPressed) {
        switch (code) {
            case 65: // Ctrl + A
                return InputIntent.SelectAll;
            case 67: // Ctrl + C
                return InputIntent.Copy;
            case 88: // Ctrl + X
                return InputIntent.Cut;
            case 86: // Ctrl + V
                return InputIntent.Paste;
            case 13: // Return
            case 77: // On Linux: Carriage return Ctrl + M
            case 74: // On Linux: Linefeed Ctrl + J
                return InputIntent.Return;
            case 66: // Ctrl + B
                return InputIntent.FormatBold;
            case 73: // Ctrl + I
                return InputIntent.FormatItalic;
            case 85: // Ctrl + U
                return InputIntent.FormatUnderline;
            case 189: // Ctrl + -
                return InputIntent.FormatSubscript;
            case 187: // Ctrl + +
                return InputIntent.FormatSuperscript;
            case 48: // Ctrl + 0
                return InputIntent.FormatClear;
            case 90: // Ctrl + Z
                return InputIntent.Undo;
            case 72: // On Linux: Backspace delete
                return InputIntent.DeletePrevious;
            // default:
            //   return InputIntent.Unknown;
        }
    }
    switch (code) {
        case 13: // Return
            return InputIntent.Return;
        case 8: // Backspace
            return InputIntent.DeletePrevious;
        case 46: // Delete
            return InputIntent.DeleteNext;
        // case 9: // Tab
        //     return InputIntent.Tab;
        case 38: // Up
            return InputIntent.MoveUp;
        case 39: // Right
            return InputIntent.MoveRight;
        case 40: // Down
            return InputIntent.MoveDown;
        case 37: // Left
            return InputIntent.MoveLeft;
        case 27: // Escape
            return InputIntent.Escape;
        // case 191: // Slash
        //     return InputIntent.NewBlock;
    }
    return InputIntent.Unknown;
}
