import React, { useMemo } from 'react';
import { Menu } from '@blueprintjs/core';
import { ToolbarMenuItem } from './ToolbarMenuItem';
import { cloneMenuItem, getTransformMenuItem, deleteMenuItem } from './common';
import { useModelState } from '../context';
export function ToolbarMenu(props) {
    const { block, menuItems } = props;
    const state = useModelState();
    const items = useMemo(() => {
        const blockItems = menuItems && menuItems.length > 0 ? [...menuItems, { type: 'divider' }] : [];
        const blockTransforms = block.locked ? undefined : getTransformMenuItem(state, block);
        // TODO Redo transforms so it displays full height transformtion items https://github.com/ProtoForce/protoforce-portal-webui/issues/24
        // just like in creation, with subtitle, etc.
        // Likewise lock in a hot key for this, so we can have perform some transformation
        // via hot keys or other commands.
        const res = [
            cloneMenuItem,
            ...(blockTransforms ? [blockTransforms] : []),
            { type: 'divider' },
            ...blockItems,
            ...(block.locked ? [] : [deleteMenuItem])
        ];
        return res;
    }, [block, menuItems]);
    return (React.createElement(Menu, null, items.map((m, mi) => (React.createElement(ToolbarMenuItem, { key: mi, block: block, item: m })))));
}
export default ToolbarMenu;
