import React, { useContext, useEffect, useRef, useMemo, useState, useCallback } from 'react';
import { Portal } from '@blueprintjs/core';
import { useLayoutEffect } from '~/core/hooks';
import { isSelectionCollapsed } from '../../model';
import { ModelContext, EditorContext, OptionsContext } from '../../Editor';
import { getVisibleSelectionRect } from '../../dom';
import { ToolbarComponent } from './styles';
import { ToolbarMenu } from './ToolbarMenu';
const ToolbarGap = 5;
function calcPosition(element) {
    const rect = getVisibleSelectionRect();
    const { left: l, right: r, top: t, bottom: b } = rect || { left: 0, right: 0, top: 0, bottom: 0 };
    const toolbarHeight = element ? element.clientHeight : 45;
    const above = t >= toolbarHeight + ToolbarGap;
    const topAdjusted = above ? t - toolbarHeight - ToolbarGap : b + ToolbarGap;
    let leftAdjusted = l + (r - l) / 2;
    const halfWidth = element ? element.clientWidth / 2 : 0;
    if (leftAdjusted < halfWidth) {
        leftAdjusted = halfWidth;
    }
    else if (window.innerWidth - leftAdjusted < halfWidth) {
        leftAdjusted = window.innerWidth - halfWidth;
    }
    return {
        left: leftAdjusted,
        top: topAdjusted
    };
}
export function Toolbar(props) {
    const options = useContext(OptionsContext);
    const editor = useContext(EditorContext);
    const model = useContext(ModelContext);
    const ref = useRef(null);
    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(0);
    const visible = useMemo(() => {
        return editor.focused && model.selected.length > 0 && !isSelectionCollapsed(model.selection);
    }, [editor.focused, model.selected, model.selection]);
    useEffect(() => {
        const pos = calcPosition(ref.current);
        setLeft(pos.left);
        setTop(pos.top);
    }, [visible]);
    useLayoutEffect(() => {
        const pos = calcPosition(ref.current);
        if (Math.floor(pos.left) !== Math.floor(left)) {
            setLeft(pos.left);
        }
        if (Math.floor(pos.top) !== Math.floor(top)) {
            setTop(pos.top);
        }
    });
    const onMouseEvent = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();
    }, []);
    if (options.readonly) {
        return null;
    }
    return (React.createElement(Portal, { className: 'bp4-dark' },
        React.createElement(ToolbarComponent, { ref: ref, visible: visible, style: {
                left,
                top
            }, onMouseDown: onMouseEvent, onMouseUp: onMouseEvent }, visible && React.createElement(ToolbarMenu, null))));
}
