import React, { useContext, useRef, useState } from 'react';
import { newSheet } from './models';
import { BlockRenderer, Resizer, ModelContext, ActionsContext } from '../../Editor';
import { DATA_BLOCK_ATTR } from '../../model';
import { HorizontalWrapper, VerticalWrapper } from './styles';
import { SheetPluginID } from './plugin';
// https://github.com/ProtoForce/protoforce-portal-webui/issues/32
// TODO Consider moving this to a plugin itself, so that
// all containers always follow this paradigm, to avoid
// re-renders
function onDrop(result, state, blockID) {
    const { model, actions } = state;
    const block = model.doc.blocks[blockID];
    if (!block) {
        return;
    }
    const vertical = block.kind === 'vertical';
    // We only support bottom for now, so just stick to it
    const { block: dropBlock, item } = result;
    const sourceBlock = item.block;
    const srcParent = actions.parent(sourceBlock);
    if (!srcParent) {
        return;
    }
    // https://github.com/ProtoForce/protoforce-portal-webui/issues/32
    // TODO Organize this code into a move handler on the model,
    // so that we can do this in any container easily
    const { parent: sourceParentBlock, plugin: sourceParentPlugin } = srcParent;
    const sameSourceTarget = sourceParentBlock.id === block.id;
    // Remove this block from the parent that currently holds it
    const sourceParentClone = actions.clone(sourceParentBlock).cloned;
    sourceParentPlugin.container.remove(sourceParentClone, sourceBlock);
    // https://github.com/ProtoForce/protoforce-portal-webui/issues/32
    // TODO Check for consistency that we can remove from the source parent?
    // For example if it doesn't exist there - it might be inconsistent
    // Attach to the current block
    const targetParentClone = sameSourceTarget ? sourceParentClone : actions.clone(block).cloned;
    const dropIndex = targetParentClone.blocks.indexOf(dropBlock);
    if (dropIndex < 0) {
        console.warn(`Inconsistent model, drop block ${dropBlock} is not found`, targetParentClone);
        return;
    }
    let added;
    if (vertical && result.right) {
        // We want to create a horizontal sheet here, so
        // let's create a new block
        added = newSheet(false, [dropBlock, sourceBlock]);
        targetParentClone.blocks.splice(dropIndex, 1, added.id);
    }
    else {
        targetParentClone.blocks.splice(dropIndex + 1, 0, sourceBlock);
        if (sourceParentClone.plugin === SheetPluginID) {
            const maybeHorSheet = sourceParentClone;
            if (maybeHorSheet.kind === 'horizontal' &&
                maybeHorSheet.blocks.length === 1 &&
                model.parents[maybeHorSheet.id] === block.id) {
                // If we have one left in horizontal sheet, and the parent is
                // this vertical sheet - just move both elements into the parent
                targetParentClone.blocks.splice(dropIndex + 2, 0, maybeHorSheet.blocks[0]);
                maybeHorSheet.blocks = [];
            }
        }
    }
    if (sameSourceTarget) {
        actions.update(targetParentClone, added);
    }
    else {
        // https://github.com/ProtoForce/protoforce-portal-webui/issues/32
        // TODO Move this clean up part to the model update method, it does make sense to check there,
        // as the hierarchy of updates is known and can be traversed
        if (sourceParentPlugin.container.children(sourceParentClone).length === 0) {
            // Source parent container is now empty, we need to remove it
            const deletion = [sourceParentClone.id];
            const updates = [targetParentClone];
            let delParentID = sourceParentClone.id;
            while (delParentID) {
                const delParent = actions.parent(delParentID);
                if (!delParent) {
                    // May happen if we are at the root component
                    break;
                }
                if (delParent.plugin.container.children(delParent.parent).length > 1) {
                    // Parent has other children, but we still need to clean up
                    // our empty container. Let's clone the parent, update its
                    // children, and then only break
                    if (delParent.parent.id === targetParentClone.id) {
                        // We've reached the parent block which we moved the
                        // block to, so we just need to remove the container
                        targetParentClone.blocks.splice(targetParentClone.blocks.indexOf(delParentID), 1);
                    }
                    else {
                        const delParentClone = actions.clone(delParent.parent).cloned;
                        delParent.plugin.container.remove(delParentClone, delParentID);
                        updates.push(delParentClone);
                    }
                    break;
                }
                deletion.push(delParent.parent.id);
                delParentID = delParent.parent.id;
            }
            actions.update(updates, added, deletion);
        }
        else {
            actions.update([sourceParentClone, targetParentClone], added);
        }
    }
}
function SheetRendererImpl(props) {
    const { block } = props;
    const model = useContext(ModelContext);
    const actions = useContext(ActionsContext);
    const vertical = block.kind === 'vertical';
    const ref = useRef(null);
    const [resizeDiff, setResizeDiff] = useState(0);
    const [resizeSensor, setResizeSensor] = useState();
    function onResizeStart(index) {
        return () => {
            setResizeSensor(index);
            setResizeDiff(0);
        };
    }
    function onResize(index) {
        return (diff) => {
            setResizeDiff(diff);
        };
    }
    function onResizeEnd(index) {
        return () => {
            const sheetEl = ref.current;
            if (!sheetEl) {
                return;
            }
            const cloned = actions.clone(block).cloned;
            if (!cloned.ratios) {
                cloned.ratios = {};
            }
            let totalWidth = 0;
            const widths = [];
            sheetEl.childNodes.forEach(cn => {
                if (cn.nodeType !== Node.ELEMENT_NODE) {
                    return;
                }
                const cne = cn;
                const cneid = cne.attributes.getNamedItem(DATA_BLOCK_ATTR);
                if (!cneid) {
                    return;
                }
                widths.push(cne.offsetWidth);
                totalWidth += cne.offsetWidth;
            });
            for (let i = 0; i < cloned.blocks.length; i++) {
                const b = cloned.blocks[i];
                cloned.ratios[b] = widths[i] / totalWidth;
            }
            setResizeSensor(undefined);
            setResizeDiff(0);
            actions.update(cloned);
        };
    }
    const Wrapper = vertical ? VerticalWrapper : HorizontalWrapper;
    const evenRatio = 1.0 / block.blocks.length;
    const spacing = 32;
    const fullSpace = (block.blocks.length - 1) * spacing;
    return (React.createElement(Wrapper, { ref: ref }, block.blocks.map((b, bi) => {
        const bb = model.doc.blocks[b];
        const right = bb && bb.plugin !== SheetPluginID;
        let resizeStyle;
        if (!vertical) {
            const hsheet = block;
            const ratio = hsheet.ratios ? hsheet.ratios[b] : evenRatio;
            let width = `(100% - ${fullSpace}px) * ${ratio}`;
            if (typeof resizeSensor !== 'undefined' && resizeDiff !== 0) {
                if (bi === resizeSensor || bi === resizeSensor + 1) {
                    width += ` ${bi === resizeSensor ? '+' : '-'} ${resizeDiff}px`;
                }
            }
            resizeStyle = {
                width: `calc(${width})`
            };
        }
        return (React.createElement(React.Fragment, { key: b },
            React.createElement(BlockRenderer, { style: resizeStyle, block: bb, drop: {
                    bottom: vertical,
                    right,
                    onDrop,
                    containerBlock: block.id
                } }),
            !vertical && bi < block.blocks.length - 1 && (React.createElement(Resizer, { vertical: !vertical, onResizeStart: onResizeStart(bi), onResize: onResize(bi), onResizeEnd: onResizeEnd(bi) }))));
    })));
}
export const SheetRenderer = React.memo(SheetRendererImpl);
