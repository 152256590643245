import { Alignment } from '../../model';
import { CreateCategory } from '../../model/plugin';
import { TextPluginID } from './plugin';
// New creators
function createParagraph(id) {
    return {
        id,
        plugin: TextPluginID,
        kind: 'paragraph',
        alignment: Alignment.Left,
        inlines: []
    };
}
function createHeader(id, size) {
    return {
        id,
        plugin: TextPluginID,
        kind: 'header',
        size,
        alignment: Alignment.Left,
        inlines: []
    };
}
function createQuote(id) {
    return {
        id,
        plugin: TextPluginID,
        kind: 'quote',
        alignment: Alignment.Left,
        inlines: []
    };
}
const newCreators = [
    {
        type: 'new',
        icon: 'paragraph',
        name: 'Paragraph',
        desc: 'Block of text',
        category: CreateCategory.Basic,
        ctor: (withID) => createParagraph(withID)
    },
    {
        type: 'new',
        icon: 'header-one',
        name: 'Header 1',
        desc: 'Large size header',
        category: CreateCategory.Basic,
        ctor: (withID) => createHeader(withID, 1)
    },
    {
        type: 'new',
        icon: 'header-two',
        name: 'Header 2',
        desc: 'Medium size header',
        category: CreateCategory.Basic,
        ctor: (withID) => createHeader(withID, 2)
    },
    {
        type: 'new',
        icon: 'header',
        name: 'Header 3',
        desc: 'Small size header',
        category: CreateCategory.Basic,
        ctor: (withID) => createHeader(withID, 3)
    },
    {
        type: 'new',
        icon: 'citation',
        name: 'Quote',
        desc: 'A quote with an optional caption',
        category: CreateCategory.Basic,
        ctor: (withID) => createQuote(withID)
    }
];
// From creators
function textToParagraph(withID, from) {
    const inlines = JSON.parse(JSON.stringify(from.inlines));
    return {
        id: withID,
        plugin: from.plugin,
        kind: 'paragraph',
        alignment: from.alignment,
        inlines
    };
}
function textToQuote(withID, from) {
    const inlines = JSON.parse(JSON.stringify(from.inlines));
    return {
        id: withID,
        plugin: from.plugin,
        kind: 'quote',
        alignment: from.alignment,
        inlines
    };
}
function textToHeader(withID, from, size) {
    const inlines = JSON.parse(JSON.stringify(from.inlines));
    return {
        id: withID,
        plugin: from.plugin,
        kind: 'header',
        alignment: from.alignment,
        inlines,
        size
    };
}
function textToHeader1(withID, from) {
    return textToHeader(withID, from, 1);
}
function textToHeader2(withID, from) {
    return textToHeader(withID, from, 2);
}
function textToHeader3(withID, from) {
    return textToHeader(withID, from, 3);
}
const fromCreators = [
    {
        type: 'from',
        icon: 'paragraph',
        name: 'Paragraph',
        ctor: (withID, from) => textToParagraph(withID, from),
        check: (from) => {
            return from.plugin === TextPluginID && from.kind !== 'paragraph';
        }
    },
    {
        type: 'from',
        icon: 'citation',
        name: 'Quote',
        ctor: (withID, from) => textToQuote(withID, from),
        check: (from) => {
            return from.plugin === TextPluginID && from.kind !== 'quote';
        }
    },
    {
        type: 'from',
        icon: 'header-one',
        name: 'Header 1',
        ctor: (withID, from) => textToHeader1(withID, from),
        check: (from) => {
            return from.plugin === TextPluginID && (from.kind !== 'header' || from.size !== 1);
        }
    },
    {
        type: 'from',
        icon: 'header-two',
        name: 'Header 2',
        ctor: (withID, from) => textToHeader2(withID, from),
        check: (from) => {
            return from.plugin === TextPluginID && (from.kind !== 'header' || from.size !== 2);
        }
    },
    {
        type: 'from',
        icon: 'header',
        name: 'Header 3',
        ctor: (withID, from) => textToHeader3(withID, from),
        check: (from) => {
            return from.plugin === TextPluginID && (from.kind !== 'header' || from.size !== 3);
        }
    }
];
export const creators = [...newCreators, ...fromCreators];
