import styled from 'styled-components';
const ToolbarBackgroundColor = '#30404d';
// const ToolbarBackgroundActiveColor = '#106ba3';
// const ToolbarTextColor = '#f5f8fa';
// const ToolbarTextActiveColor = '#48aff0';
// color: ${ToolbarTextColor};
// &:hover {
//     background-color: ${ToolbarBackgroundActiveColor};
// }
// color: ${ToolbarTextColor};
export const ToolbarComponent = styled.div `
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  transition: transform 0.15s cubic-bezier(0.3, 1.2, 0.2, 1);
  transform: translate(-50%) scale(${props => (props.visible ? '1' : '0')});
  position: absolute;
  z-index: 100;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  background-color: ${ToolbarBackgroundColor};
  border-radius: 3px;
  padding: 5px;
`;
