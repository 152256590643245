import { compareSelection, normalizeSelection, offsetToLocation, locationToOffset, blocksTextLength, isSelectionReversed } from './location';
import { copyFragment, replaceFragment } from './fragment';
import { pushHistory, buildHistoryRecord } from './history';
import { blockToJSON, blockFromJSON, cloneBlock } from './utils';
export function buildOptionsState(options) {
    const { debug, readonly, plugins, history: historyLength } = options;
    const pluginsMap = {};
    plugins.forEach(p => {
        if (debug) {
            if (p.id in plugins) {
                throw new Error(`Duplicate plugin: ${p.id}`);
            }
        }
        pluginsMap[p.id] = p;
    });
    return {
        plugins: pluginsMap,
        debug,
        historyLength,
        readonly
    };
}
export function buildModelState(options, blocks) {
    const { readonly, plugins } = options;
    const blocksRestored = blocks.map(f => {
        const blockRef = readonly ? f : JSON.parse(JSON.stringify(f));
        return blockFromJSON(plugins, blockRef);
    });
    return {
        blocks: blocksRestored,
        selected: [],
        history: {
            changes: [buildHistoryRecord(plugins, blocksRestored, undefined)],
            location: 0
        }
    };
}
export function buildActionsState(options, model, onUpdated, onRedraw) {
    const { plugins } = options;
    function notifyRedraw() {
        if (!onRedraw) {
            return;
        }
        onRedraw();
    }
    function notifyUpdate(blocks) {
        if (!onUpdated) {
            return;
        }
        const json = blocks.map(b => blockToJSON(plugins, b));
        onUpdated(json);
    }
    function assignSelection(updatedSelection) {
        model.selection = updatedSelection;
        model.selected = pick(updatedSelection);
    }
    function loadHistory(rec) {
        const recBlocks = JSON.parse(rec.blocks).map((b) => blockFromJSON(plugins, b, b.id));
        const recSelection = rec.selection ? JSON.parse(rec.selection) : undefined;
        model.blocks = recBlocks;
        assignSelection(recSelection);
        notifyUpdate(recBlocks);
        notifyRedraw();
    }
    function undo() {
        const { history } = model;
        if (history.location <= 0) {
            return false;
        }
        history.location--;
        const rec = history.changes[history.location];
        loadHistory(rec);
        return true;
    }
    function redo() {
        const { history } = model;
        if (history.location >= history.changes.length - 1) {
            return false;
        }
        history.location++;
        const rec = history.changes[history.location];
        console.log(`Restoring: ${rec.selection} ${rec.blocks}`);
        loadHistory(rec);
        return true;
    }
    function updateCaret(l, forced) {
        updateSelection({
            start: Object.assign({}, l),
            end: Object.assign({}, l)
        }, forced);
    }
    function updateSelection(s, forced) {
        const { selection } = model;
        if (compareSelection(s, selection) && !forced) {
            // No need, same selection
            return;
        }
        // We need to adjust selection in case it ends up on an object type block.
        // fixSelection(s);
        assignSelection(s);
        notifyRedraw();
    }
    // function fixSelection(s: InlineSelection) {
    //     const reverse = isSelectionReversed(blocks, s);
    //     const fixBlockLocation = (b: InlineBlock, l: InlineLocation, toStart: boolean) => {
    //         if (b.type !== 'object') {
    //             return;
    //         }
    //         if (toStart) {
    //             if (l.offset !== 0) {
    //                 l.offset = 0;
    //             }
    //         } else {
    //             if (l.offset !== 'end' && l.offset > 0) {
    //                 l.offset = 'end';
    //             }
    //         }
    //     };
    //     fixBlockLocation(blocks[s.start.block], s.start, !reverse);
    //     fixBlockLocation(blocks[s.end.block], s.end, reverse);
    // }
    function clone(b, resetID) {
        return cloneBlock(plugins, b, resetID);
    }
    function copy(s) {
        const { blocks } = model;
        return copyFragment(plugins, s, blocks);
    }
    function pick(selection) {
        const res = [];
        if (!selection) {
            return res;
        }
        const { blocks } = model;
        const reversed = isSelectionReversed(blocks, selection);
        const start = reversed ? selection.end : selection.start;
        const end = reversed ? selection.start : selection.end;
        for (let i = start.block; i <= end.block; i++) {
            const b = blocks[i];
            if (!b) {
                // Position must not have been updated, end of the blocks
                break;
            }
            if (i === start.block) {
                if (start.offset === 'end' || (b.type === 'text' && start.offset >= b.text.length)) {
                    // Nothing to do, we were in the end
                }
                else {
                    res.push(b);
                }
            }
            else if (i === end.block) {
                if (end.offset === 0) {
                    // Do nothing, just skip
                }
                else {
                    res.push(b);
                }
            }
            else {
                res.push(b);
            }
        }
        return res;
    }
    function replace(replaceWith, s, caret) {
        const { blocks, selection } = model;
        const sel = selection ? [selection.start, selection.end] : [];
        const res = replaceFragment(plugins, replaceWith, s, blocks, ...sel);
        if (!res) {
            console.warn('Failed to replace the content.');
            return [];
        }
        const { blocks: updatedBlocks, locations: [start, end] } = res;
        let newSel;
        if (typeof caret !== 'undefined') {
            if (typeof caret === 'string') {
                const uninverted = normalizeSelection(blocks, s);
                const caretPos = caret === 'front'
                    ? offsetToLocation(updatedBlocks, locationToOffset(blocks, uninverted.start))
                    : offsetToLocation(updatedBlocks, locationToOffset(blocks, uninverted.start) + blocksTextLength(replaceWith));
                newSel = { start: caretPos, end: caretPos };
            }
            else {
                updateCaret(caret, true);
            }
        }
        else {
            if (start && end) {
                newSel = { start, end };
            }
        }
        model.blocks = updatedBlocks;
        if (newSel) {
            assignSelection(newSel);
        }
        pushHistoryRecord();
        notifyUpdate(updatedBlocks);
        notifyRedraw();
        return updatedBlocks;
    }
    function insert(block, location, caret) {
        const { blocks } = model;
        const loc = location || (blocks.length === 0
            ? {
                block: 0,
                offset: 0
            }
            : {
                block: blocks.length - 1,
                offset: 'end'
            });
        replace(Array.isArray(block) ? block : [block], { start: loc, end: loc }, caret);
    }
    function remove(id, caret) {
        const { blocks } = model;
        const block = blocks.findIndex(b => b.id === id);
        if (block < 0) {
            return;
        }
        replace([], {
            start: {
                block,
                offset: 0
            },
            end: {
                block,
                offset: 'end'
            }
        }, caret);
    }
    function pushHistoryRecord() {
        const { blocks, history, selection } = model;
        const { historyLength } = options;
        pushHistory(plugins, history, historyLength, blocks, selection);
    }
    function updateText(id, text, redraw, caret) {
        const { blocks } = model;
        const bi = typeof id === 'string' ? blocks.find(b => b.id === id) : id;
        if (!bi || bi.type !== 'text') {
            console.warn(`Trying to update block ${id} which doesn't exist or is not of text type.`);
            return;
        }
        if (text.length === 0) {
            remove(bi.id);
            return;
        }
        bi.text = text;
        if (caret) {
            updateCaret(caret, true);
        }
        notifyUpdate(blocks);
        pushHistoryRecord();
        if (!redraw) {
            return;
        }
        model.blocks = blocks.map(bl => (bl.id === bi.id ? Object.assign({}, bl) : bl));
        notifyRedraw();
    }
    return {
        pick,
        clone,
        copy,
        undo,
        redo,
        replace,
        insert,
        remove,
        updateText,
        updateCaret,
        updateSelection
    };
}
