import { GoogleMapsRenderer } from './GoogleMapsRenderer';
import { GoogleMapsPluginID } from './models';
import { creators } from './creators';
import { alignmentMenu } from '../common';
const googleMapsPlugin = {
    id: GoogleMapsPluginID,
    renderer: GoogleMapsRenderer,
    forwardRef: true,
    creators,
    menu: true,
    menuItems: [
        {
            type: 'command',
            data: {
                icon: 'map',
                name: 'Change Map...'
            },
            func: (context, block, pluginRef) => {
                if (!pluginRef) {
                    return;
                }
                pluginRef.openPicker();
            }
        },
        alignmentMenu
    ]
};
export function createGoogleMapsPlugin(config) {
    return Object.assign(Object.assign({}, googleMapsPlugin), { config });
}
