function clone(state, block) {
    const { actions } = state;
    const res = actions.clone(block, true, true);
    actions.insert(res.cloned, block.id, true, res.children);
}
export const cloneMenuItem = {
    type: 'command',
    data: {
        icon: 'duplicate',
        name: 'Clone'
    },
    func: clone
};
export function getTransformMenuItem(state, block) {
    const { actions, options } = state;
    const { plugins } = options;
    const transforms = [];
    Object.values(plugins).forEach(plugin => {
        if (!plugin.creators) {
            return;
        }
        transforms.push(...plugin.creators
            .filter(p => p.type === 'from' && (!p.check || p.check(block)))
            .map(c => {
            const cmdFunc = (m, b) => {
                const created = c.ctor(block.id, b);
                if (Array.isArray(created)) {
                    console.error(`CTOR should not return multiple blocks during transformation`);
                }
                else {
                    actions.update(created);
                    actions.focus(created.id);
                }
            };
            const cmd = {
                type: 'command',
                data: {
                    icon: c.icon,
                    name: c.name
                },
                func: cmdFunc
            };
            return cmd;
        }));
    });
    if (transforms.length === 0) {
        return undefined;
    }
    return {
        type: 'group',
        items: transforms,
        data: {
            icon: 'exchange',
            name: 'Convert'
        }
    };
}
export const deleteMenuItem = {
    type: 'command',
    data: {
        icon: 'trash',
        name: 'Delete'
    },
    func: (context, block) => {
        context.actions.remove(block);
    }
};
